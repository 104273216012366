import React, { useEffect, useState, useRef } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  NewCustomersDaily,
  NewCustomersWeek,
  NewCustomersMonth,
  UnredeemedYear,
  redeemedYear,
  ExpiredYesterday,
  ExpiredMonth,
  Expiring3Month,
  NoOfTranscation,
  NoOfTranscationWeekly,
  NoOfTranscationMonthly,
  ValueSalesDaily,
  ValueSalesWeekly,
  ValueSalesMonthly,
  Bubble,
  Top5ByNationalitiesDaily,
  Top5ByNationalitiesMonthly,
  Top5ByNationalitiesWeekly,
  DummbleDaily,
  DummbleWeekly,
  DummbleMonth,
  TopCategoriesMonthly,
  TopSubCategories,
  TopSubCategoriesDaily,
  TopSellingBrand,
  TopSellingBrandDaily,
  TopSellingNationality,
  TopSellingBrandLastweek,
  UserPieChart,
  TopSellingNationalityDaily,
  TopSellingNationalityWeek,
  RedeemedDaily,
  RedeemedPointChart,
  RedeemedMonthly,
  TopCategoriesDaily,
  TopCategoriesWeekly,
  TopSubCategoriesMonthly,
  UserBarChart,
  EarnedPointsHistogram,
  UserActivityRangeBar,
  UnredeemedYearBar,
  AmountComparison,
} from "./components/charts";
import LoaderOwn from "../common/loaderOwn.js";
import {
  userChart,
  chartCurrentVsPrevious,
  unRedeemedChart,
  userTotalChart,
  topBrandChartStatus,
  topNationalityChartStatus,
  expiredPointsChart,
  expiredNext3MonthsPointsChart,
  expiredNextCurrentMonthPointsChart,
  orderTransactionChart,
  RedeemedPointsChart,
  orderCategoryChart,
} from "../api/apiIntegration.js";
import TabOwn from "../common/twoButton.js";
import UserPieChartOwn from "./components/pie_chart.js";
import Top10CategoriesPieChart from "./components/pieChartTop10Category.js";
import MonthChartNationality from "./components/month_chart_nationality.js";
import WeekendChartNationality from "./components/weekend_chart_nationality.js";
import MonthChartBrand from "./components/Month-chart-brand.js";
import TopBrandMonthChart from "./components/Weekend-chart-brand.js";
import Top10SubCategoryPieChart from "./components/pieChartTop10SubCategory.js";
import Top10NationalityPieChart from "./components/top5Nationality_month_pieChart.js";
import Top10BrandPieChart from "./components/brand_chart.js";
import BeerSpiritsWinesMain from "./components/BeerSpiritsWines_main.js";
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [redeemedYear, setRedeemedYear] = useState([]);

  const [top5Brand, setTop5Brand] = useState([]);
  const [expiredPointChart, setExpiredPointChart] = useState([]);
  const [expiredNext3MonthsPointChart, setExpiredNext3MonthsPointChart] =
    useState([]);
  const [expiredCurrentPointChart, setExpiredCurrentPointChart] = useState([]);
  const [redeemedChart, setRedeemedChart] = useState([]);
  const [top5Nationality, setTop5Nationality] = useState([]);
  const [orderTransaction, setOrderTransaction] = useState([]);
  const [orderWeekTransaction, setWeekOrderTransaction] = useState([]);
  const [orderMonthTransaction, setMonthOrderTransaction] = useState([]);
  const [orderCategoryChartGet, setOrderCategoryChart] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMonth, setLoadingMonth] = useState(true);
  const [userTotalCount, setuserTotalCount] = useState([
    { value: 0 },
    { value: 0 },
    { value: 0 },
  ]);
  const [userBarTotalCount, setUserBarTotalCount] = useState([]);
  const [isUserTotalCountLoading, setUserTotalCountLoading] = useState(true);
  const [isUnredeemedLoading, setIsUnredeemedLoading] = useState(true);
  const [istop5BrandLoading, setUserTop5BrandLoading] = useState(true);
  const [isRedeemPointsLoading, setIsRedeemPointsLoading] = useState(true);
  const [isOrderTransactionLoading, setOrderTransactionLoading] =
    useState(true);
  const [isExpiredPointChartLoading, setExpiredPointChartLoading] =
    useState(true);
  const [isExpiredNext3MonthsLoading, setExpiredNext3MonthsLoading] =
    useState(true);
  const [isExpiredCurrentMonthLoading, setExpiredCurrenMonthtLoading] =
    useState(true);
  const [istop5NationalityLoading, setUserTop5NationalityLoading] =
    useState(true);

  const [istop5SubCategoryLoading, setUserTop5SubCategoryLoading] =
    useState(true);
  const [isuserChartType, setUserChartType] = useState("bar");
  const [isuserExpiryType, setUserExpiryType] = useState("bar");
  const [isuserChartPie, setUserChartPie] = useState("pie");
  const [isuserThreeExpiryType, setUserThreeExpiryType] = useState("line");
  const [isuserCurrentExpiryType, setUserCurrentExpiryType] = useState("line");
  const [isuserTransactionsType, setUserTransactionsType] = useState("bar");
  const [isUnredeemedYearType, setUnredeemedYearType] = useState("funnel");
  const [isRedeemedYearType, setRedeemedYearType] = useState("funnel");
  const [isuserValueSalesDailyType, setUserValueSalesDailyType] =
    useState("bar");
  const [isuserChartBody, setUserChartBody] = useState({ status: "daily" });
  const [isuserChartCurrentBody, setUserChartCurrentBody] = useState(0);
  const [isValueSalesDailyBody, setValueSalesDailyBody] = useState({
    status: "lastweek",
  });
  const [isuserTransactionsBody, setUserTransactionsBody] = useState({
    status: "daily",
  });
  const [isCategoryChartLoading, setUserCategoryChartLoading] = useState(true);
  useEffect(() => {
    list(isuserChartBody);
    list1();
    // fetchData() ;
    unRedeemed();
    userCountChart();
    top5BrandData({
      status: "daily",
    });
    top5NationalityData({
      status: "daily",
    });
    expiredPoint();
    RedeemedPoints();
    expiredNext3MonthsPoint();
    expiredCurrentMonthPoint();
    orderTransactionData(isuserTransactionsBody);

    //apiCall();
  }, []);

  // const apiCall = async () => {
  //   if (isApiCalled.current) return;
  //   isApiCalled.current = true;
  //   try {
  //     console.log("api called hit : 1");

  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleStatusChange = (newStatus) => {
    setUserChartType(
      newStatus == 1 ? "line" : isuserChartCurrentBody == 1 ? "slope" : "bar"
    );
    list(isuserChartBody);
    if (isuserChartCurrentBody == 1) {
      list1();
    }
  };
  const handleExpiredStatusChange = (newStatus) => {
    setUserExpiryType(newStatus === 1 ? "histogram" : "bar");
    expiredPoint();
  };
  const handlePieStatusChange = (newStatus) => {
    setUserChartPie(newStatus === 1 ? "pie" : "rangeBar");
  };
  const handleExpiredThreeMonthStatusChange = (newStatus) => {
    setUserThreeExpiryType(newStatus === 1 ? "line" : "bar");
    expiredNext3MonthsPoint();
  };
  const handleExpiredCurrentMonthStatusChange = (newStatus) => {
    setUserCurrentExpiryType(newStatus === 1 ? "line" : "bar");
    expiredCurrentMonthPoint();
  };
  const handleTransactionsStatusChange = (newStatus) => {
    setUserTransactionsType(newStatus === 1 ? "bar" : "line");
    orderTransactionData(isuserTransactionsBody);
  };
  const handleUnredeemedYearChange = (newStatus) => {
    setUnredeemedYearType(newStatus === 1 ? "funnel" : "bar");
    unRedeemed();
  };
  const handleRedeemedYearChange = (newStatus) => {
    setRedeemedYearType(newStatus === 1 ? "funnel" : "bar");
    unRedeemed();
  };

  const handleTabSelect = (eventKey) => {
    if (eventKey === "daily") {
      setUserChartCurrentBody(0);
      setUserChartType("bar");
      setUserChartBody({
        status: "daily",
      });
      list({
        status: "daily",
      });
    } else if (eventKey === "last_week") {
      setUserChartCurrentBody(0);
      setUserChartType("bar");
      setUserChartBody({
        status: "lastWeek",
      });
      list({
        status: "lastWeek",
      });
    } else if (eventKey === "current_month") {
      setUserChartCurrentBody(1);
      setUserChartType("bar");
      list1();
    }
  };

  const handleNationalityTabSelect = (eventKey) => {
    if (eventKey === "daily") {
      top5NationalityData({
        status: "daily",
      });
    } else if (eventKey === "last_week") {
      top5NationalityData({
        status: "weekly",
      });
    } else if (eventKey === "current_month") {
      top5NationalityData({
        status: "currentMonth",
      });
    }
  };
  const handleBrandTabSelect = (eventKey) => {
    if (eventKey === "daily") {
      top5BrandData({
        status: "daily",
      });
    } else if (eventKey === "last_week") {
      top5BrandData({
        status: "lastWeek",
      });
    } else if (eventKey === "current_month") {
      top5BrandData({
        status: "currentMonth",
      });
    }
  };
  const handleTransactionTabSelect = (eventKey) => {
    if (eventKey === "daily") {
      setUserTransactionsBody({
        status: "daily",
      });
      orderTransactionData({
        status: "daily",
      });
    } else if (eventKey === "last_week") {
      setUserTransactionsBody({
        status: "lastweek",
      });
      orderTransactionData({
        status: "lastweek",
      });
    } else if (eventKey === "current_month") {
      setUserTransactionsBody({
        status: "currentMonth",
      });
      orderTransactionData({
        status: "currentMonth",
      });
    }
  };

  const list = async (status) => {
    try {
      setIsLoading(true);
      const response = await userChart(status);
      if (response.success === true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const unRedeemed = async () => {
    try {
      setIsUnredeemedLoading(true);
      const response = await unRedeemedChart();
      if (response.success === true) {
        setRedeemedYear(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsUnredeemedLoading(false);
    }
  };
  const RedeemedPoints = async () => {
    try {
      setIsRedeemPointsLoading(true);
      const response = await RedeemedPointsChart();
      if (response.success === true) {
        setRedeemedChart(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsRedeemPointsLoading(false);
    }
  };
  const userCountChart = async () => {
    try {
      setUserTotalCountLoading(true);
      const response = await userTotalChart();
      if (response.success === true) {
        const dataGet = response.data;
        setUserBarTotalCount(dataGet);
        const data = [
          { value: dataGet.activeUserCount.count },
          { value: dataGet.dormantUserCount.count },
          { value: dataGet.lapsedUserCount.count },
        ];
        setuserTotalCount(data);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setUserTotalCountLoading(false);
    }
  };

  const list1 = async () => {
    try {
      setLoadingMonth(true);
      const response = await chartCurrentVsPrevious();
      if (response.success === true) {
        setData1(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setLoadingMonth(false);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await UserCount();
  //     if (response.success === true) {
  //       setUserCount(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const top5BrandData = async (status) => {
    try {
      setUserTop5BrandLoading(true);
      const response = await topBrandChartStatus(status);
      if (response.success === true) {
        setTop5Brand(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setUserTop5BrandLoading(false);
    }
  };
  const top5NationalityData = async (status) => {
    try {
      setUserTop5NationalityLoading(true);
      const response = await topNationalityChartStatus(status);
      if (response.success === true) {
        setTop5Nationality(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setUserTop5NationalityLoading(false);
    }
  };
  const expiredPoint = async () => {
    try {
      setExpiredPointChartLoading(true);
      const response = await expiredPointsChart();
      if (response.success === true) {
        setExpiredPointChart(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setExpiredPointChartLoading(false);
    }
  };
  const expiredNext3MonthsPoint = async () => {
    try {
      setExpiredNext3MonthsLoading(true);
      const response = await expiredNext3MonthsPointsChart();
      if (response.success === true) {
        setExpiredNext3MonthsPointChart(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setExpiredNext3MonthsLoading(false);
    }
  };
  const expiredCurrentMonthPoint = async () => {
    try {
      setExpiredCurrenMonthtLoading(true);
      const response = await expiredNextCurrentMonthPointsChart();
      if (response.success === true) {
        setExpiredCurrentPointChart(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setExpiredCurrenMonthtLoading(false);
    }
  };
  const orderTransactionData = async (status) => {
    try {
      setOrderTransactionLoading(true);
      // console.log(status);
      const response = await orderTransactionChart(status);
      if (response.success === true) {
        if (status.status === "daily") {
          setOrderTransaction(response.data);
        } else if (status.status === "lastweek") {
          setWeekOrderTransaction(response.data);
        } else if (status.status === "currentMonth") {
          setMonthOrderTransaction(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setOrderTransactionLoading(false);
    }
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              {/* <div className="col-lg-3">
                <div className="card card-chart">
                  <div class="info-icon text-center icon-warning">
                    <img src="./images/user.png" alt="" />
                  </div>
                  <div className="card-header pad-l80">
                    <h5 className="card-category">Total number of users</h5>
                    <h3 className="card-title"> {userCount.totalUsers}</h3>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">New Customers</h5>
                    <h3 className="card-title"> Performance</h3>
                  </div>
                  <div className="tab_pad customs_tab">
                    {/* <TabOwn
                      onStatusChange={handleStatusChange}
                      buttonName={[
                        "Line",
                        isuserChartCurrentBody == 0 ? "Bar" : "Slope",
                      ]}
                      active={isuserChartType === "line" ? 0 : 1}
                      onActiveChange={(index) =>
                        setUserChartType(
                          index == 1
                            ? "line"
                            : isuserChartCurrentBody == 0
                            ? "bar"
                            : "slope"
                        )
                      }
                    /> */}
                    <Tabs className="pos-abs" onSelect={handleTabSelect}>
                      <Tab eventKey="daily" title="Daily">
                        <div className="chart-area">
                          {isLoading === true ? (
                            <LoaderOwn />
                          ) : (
                            <NewCustomersDaily
                              data={data}
                              chartType={isuserChartType}
                            />
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="last_week" title="Last Week">
                        <div className="chart-area">
                          {isLoading === true ? (
                            <LoaderOwn />
                          ) : (
                            <NewCustomersWeek
                              data={data}
                              chartType={isuserChartType}
                            />
                          )}
                        </div>
                      </Tab>
                      <Tab
                        eventKey="current_month"
                        title="Current Month vs Last Month and Last Year"
                      >
                        <div className="chart-area">
                          {isLoadingMonth === true ? (
                            <LoaderOwn />
                          ) : (
                            <NewCustomersMonth
                              apiResponse={data1}
                              chartType={isuserChartType}
                            />
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="row " style={{ justifyContent: "space-between" }}>
              <div className="col-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category"></h5>
                    <h3 className="card-title">Total number of user</h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <TabOwn
                        onStatusChange={handlePieStatusChange}
                        buttonName={["Pie", "RangeBar"]}
                        active={isuserChartPie === "pie" ? 0 : 1}
                        onActiveChange={(index) => {
                          setUserChartPie(index === 0 ? "pie" : "rangeBar");
                        }}
                      />

                      {isuserChartPie === "pie" ? (
                        <UserPieChartOwn />
                      ) : isUserTotalCountLoading ? (
                        <LoaderOwn />
                      ) : (
                        <UserActivityRangeBar apiResponse={userBarTotalCount} />
                      )}
                      {/* {userTotalCount &&
                        (isuserChartPie === "pie" ? (
                          <UserPieChart key="pie1" data={userTotalCount} />
                        ) : isuserChartPie === "bar" ? (
                          <UserBarChart key="bar1" data={userBarTotalCount} />
                        ) : (
                          <div></div>
                        ))} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Total Unredeemed points</h5>
                    <h3 className="card-title">One year</h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <TabOwn
                        onStatusChange={handleUnredeemedYearChange}
                        buttonName={["Funnel", "Bar"]}
                        active={isUnredeemedYearType === "funnel" ? 0 : 1}
                        onActiveChange={(index) => {
                          setUnredeemedYearType(index === 0 ? "funnel" : "bar");
                        }}
                      />
                      {isUnredeemedLoading === true ? (
                        <LoaderOwn />
                      ) : isUnredeemedYearType === "funnel" ? (
                        <UnredeemedYear data={redeemedYear} />
                      ) : (
                        <UnredeemedYearBar data={redeemedYear} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-5">
                <div className="column">
                  <div className="card card-chart">
                    <div class="info-icon text-center icon-primary">
                      <img src="./images/user.png" alt="" />
                    </div>
                    <div className="card-header pad-l80">
                      <h5 className="card-category">Active users</h5>
                      <h3 className="card-title">{userCount.activeUsers}</h3>
                    </div>
                  </div>

                  <div className="card card-chart">
                    <div class="info-icon text-center icon-info">
                      <img src="./images/user.png" alt="" />
                    </div>
                    <div className="card-header pad-l80">
                      <h5 className="card-category">Dormant users</h5>
                      <h3 className="card-title">{userCount.dormantUsers}</h3>
                    </div>
                  </div>

                  <div className="card card-chart">
                    <div class="info-icon text-center icon-danger">
                      <img src="./images/user.png" alt="" />
                    </div>
                    <div className="card-header pad-l80">
                      <h5 className="card-category">Lapsed Users</h5>
                      <h3 className="card-title"> {userCount.lapsedUsers}</h3>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header">
                    <h3 className="card-title">
                      Total expiring points on a monthly basis
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      {/* <TabOwn
                        onStatusChange={handleExpiredStatusChange}
                        buttonName={["Histogram", "bar"]}
                        active={isuserExpiryType === "bar" ? 1 : 0}
                        onActiveChange={(index) => {
                          setUserExpiryType(index === 0 ? "histogram" : "bar");
                        }}
                      /> */}
                      {isExpiredPointChartLoading === true ? (
                        <LoaderOwn />
                      ) : isuserExpiryType === "bar" ? (
                        <ExpiredYesterday
                          data={expiredPointChart}
                          chartType={isuserExpiryType}
                        />
                      ) : (
                        <EarnedPointsHistogram
                          data={expiredPointChart}
                          // chartType={isuserExpiryType}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Total points expiring</h5>
                    <h3 className="card-title">Next 3 months</h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <TabOwn
                        onStatusChange={handleExpiredThreeMonthStatusChange}
                        buttonName={["Line", "Bar"]}
                        active={isuserThreeExpiryType === "line" ? 0 : 1}
                        onActiveChange={(index) => {
                          setUserThreeExpiryType(index === 0 ? "line" : "bar");
                        }}
                      />
                      {isExpiredNext3MonthsLoading === true ? (
                        <LoaderOwn />
                      ) : (
                        <Expiring3Month
                          data={expiredNext3MonthsPointChart}
                          chartType={isuserThreeExpiryType}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Points expiring</h5>
                    <h3 className="card-title">Current Month</h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <TabOwn
                        onStatusChange={handleExpiredCurrentMonthStatusChange}
                        buttonName={["Line", "Bar"]}
                        active={isuserCurrentExpiryType === "line" ? 0 : 1}
                        onActiveChange={(index) => {
                          setUserCurrentExpiryType(
                            index === 0 ? "line" : "bar"
                          );
                        }}
                      />
                      {isExpiredCurrentMonthLoading === true ? (
                        <LoaderOwn />
                      ) : (
                        <ExpiredMonth
                          data={expiredCurrentPointChart}
                          chartType={isuserCurrentExpiryType}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Number of Transactions</h5>
                    <h3 className="card-title">Performance</h3>
                  </div>
                  <div className="tab_pad customs_tab">
                    <TabOwn
                      onStatusChange={handleTransactionsStatusChange}
                      buttonName={["Bar", "Line"]}
                      active={isuserTransactionsType === "bar" ? 0 : 1}
                      onActiveChange={(index) => {
                        setUserTransactionsType(index === 0 ? "bar" : "line");
                      }}
                    />
                    <Tabs
                      className="pos-abs"
                      onSelect={handleTransactionTabSelect}
                    >
                      <Tab eventKey="daily" title="Daily">
                        <div className="chart-area">
                          {isOrderTransactionLoading === true ? (
                            <LoaderOwn />
                          ) : (
                            <NoOfTranscation
                              data={orderTransaction}
                              chartType={isuserTransactionsType}
                            />
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="last_week" title="Last Week">
                        <div className="chart-area">
                          {isOrderTransactionLoading === true ? (
                            <LoaderOwn />
                          ) : (
                            <NoOfTranscationWeekly
                              data={orderWeekTransaction}
                              chartType={isuserTransactionsType}
                            />
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="current_month" title="Current Month">
                        <div className="chart-area">
                          {isOrderTransactionLoading === true ? (
                            <LoaderOwn />
                          ) : (
                            <NoOfTranscationMonthly
                              data={orderMonthTransaction}
                              chartType={isuserTransactionsType}
                            />
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Redeemed Points</h5>
                    <h3 className="card-title"> Performance</h3>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <TabOwn
                        onStatusChange={handleRedeemedYearChange}
                        buttonName={["Funnel", "Bar"]}
                        active={isRedeemedYearType === "funnel" ? 0 : 1}
                        onActiveChange={(index) => {
                          handleRedeemedYearChange(
                            index === 0 ? "funnel" : "bar"
                          );
                        }}
                      />
                      {isRedeemPointsLoading === true ? (
                        <LoaderOwn />
                      ) : isRedeemedYearType === "funnel" ? (
                        <RedeemedPointChart data={redeemedChart} />
                      ) : (
                        <AmountComparison data={redeemedChart} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <BeerSpiritsWinesMain />
            </div>
            <div className="row">
              <Top10CategoriesPieChart />
              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Top 10 Product Categories</h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                      <Tabs
                        className="pos-abs"
                        onSelect={handleCategoryTabSelect}
                      >
                        <Tab eventKey="daily" title="Daily">
                          <div className="chart-area">
                            {istop5CategoryLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopCategoriesDaily data={top5Category} />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="last_week" title="Last Week">
                          <div className="chart-area">
                            {istop5CategoryLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopCategoriesWeekly data={top5Category} />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="current_month" title="Current Month">
                          <div className="chart-area">
                            {istop10CategoryLoading ? (
                              <LoaderOwn />
                            ) : (
                              <Top10CategoriesPieChart data={top10Category} />
                            )}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div> */}
              <Top10SubCategoryPieChart />
              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">
                      Top 5 Product Subcategories
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                      <TabsF
                        className="pos-abs"
                        onSelect={handleSubCategoryTabSelect}
                      >
                        <Tab eventKey="daily" title="Daily">
                          <div className="chart-area">
                            {istop5SubCategoryLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSubCategoriesDaily data={top5SubCategory} />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="last_week" title="Last Week">
                          <div className="chart-area">
                            {istop5SubCategoryLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSubCategories data={top5SubCategory} />
                            )}
                          </div>
                        </Tab>
                        <Tab
                          eventKey="current_month"
                          title="Current Month vs Previous Year"
                        >
                          <div className="chart-area">
                            {istop5SubCategoryLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSubCategoriesMonthly  />
                            )}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Top 5 Selling Brands</h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                      <Tabs className="pos-abs" onSelect={handleBrandTabSelect}>
                        <Tab eventKey="daily" title="Daily">
                          <div className="chart-area">
                            {istop5BrandLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingBrandDaily data={top5Brand} />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="last_week" title="Last Week">
                          <div className="chart-area">
                            {istop5BrandLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingBrandLastweek data={top5Brand} />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="current_month" title="Current Month">
                          <div className="chart-area">
                            {istop5BrandLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingBrand data={top5Brand} />
                            )}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Top 5 Selling Brands</h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                 
                      <Tabs className="pos-abs">
                        <Tab
                          eventKey="current_month_Vs_previous_month"
                          title="Current Month Vs Previous Month"
                        >
                          <div className="chart-area">
                            <MonthChartBrand />
                          </div>
                        </Tab>
                        <Tab
                          eventKey="previous_weekend_vs_before_weekend"
                          title="Previous Weekend Vs Before Weekend"
                        >
                          <div className="chart-area">
                            <TopBrandMonthChart />
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div> */}
              <Top10BrandPieChart />
              <Top10NationalityPieChart />

              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">
                      Top 5 spenders by nationalities
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                      <Tabs className="pos-abs">
                        <Tab
                          eventKey="current_month_Vs_previous_month"
                          title="Current Month Vs Previous Month"
                        >
                          <div className="chart-area">
                            <MonthChartNationality />
                          </div>
                        </Tab>
                        <Tab
                          eventKey="previous_weekend_vs_before_weekend"
                          title="Previous Weekend Vs Before Weekend"
                        >
                          <div className="chart-area">
                            <WeekendChartNationality />
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>  */}
              {/* <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">
                      Top 5 spenders by nationalities
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="tab_pad customs_tab">
                      <Tabs
                        className="pos-abs"
                        onSelect={handleNationalityTabSelect}
                      >
                        <Tab eventKey="daily" title="Daily">
                          <div className="chart-area">
                            {istop5NationalityLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingNationalityDaily
                                data={top5Nationality}
                              />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="last_week" title="Last Week">
                          <div className="chart-area">
                            {istop5NationalityLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingNationalityWeek
                                data={top5Nationality}
                              />
                            )}
                          </div>
                        </Tab>
                        <Tab eventKey="current_month" title="Current Month">
                          <div className="chart-area">
                            {istop5NationalityLoading === true ? (
                              <LoaderOwn />
                            ) : (
                              <TopSellingNationality data={top5Nationality} />
                            )}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            {/* <div className="row">
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">
                      Top 5 spenders by nationalities
                    </h5>
                    <h3 className="card-title"> Performance</h3>
                  </div>
                  <div className="tab_pad customs_tab">
                    <Tabs className="pos-abs">
                      <Tab eventKey="daily" title="Daily">
                        <div className="chart-area">
                          <Top5ByNationalitiesDaily />
                        </div>
                      </Tab>
                      <Tab eventKey="last_week" title="Last Week">
                        <div className="chart-area">
                          <Top5ByNationalitiesWeekly />
                        </div>
                      </Tab>
                      <Tab eventKey="current_month" title="Current Month">
                        <div className="chart-area">
                          <Top5ByNationalitiesMonthly />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-chart">
                  <div className="card-header">
                    <h5 className="card-category">Top 5 Selling Brands</h5>
                    <h3 className="card-title"> Performance</h3>
                  </div>
                  <div className="tab_pad customs_tab">
                    <Tabs className="pos-abs">
                      <Tab eventKey="daily" title="Daily">
                        <div className="chart-area">
                          <DummbleDaily />
                        </div>
                      </Tab>
                      <Tab eventKey="last_week" title="Last Week">
                        <div className="chart-area">
                          <DummbleWeekly />
                        </div>
                      </Tab>
                      <Tab eventKey="current_month" title="Current Month">
                        <div className="chart-area">
                          <DummbleMonth />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="card card-chart">
                <div className="card-header">
                  <h5 className="card-category">Redeemed Points Daily</h5>
                  <h3 className="card-title"> Performance</h3>
                </div>
                <div className="tab_pad customs_tab">
                  <Tabs className="pos-abs">
                    <Tab eventKey="daily" title="Daily">
                      <div className="chart-area">
                        <RedeemedDaily />
                      </div>
                    </Tab>
                    <Tab eventKey="last_week" title="Last Week">
                      <div className="chart-area">
                        <RedeemedWeekly />
                      </div>
                    </Tab>
                    <Tab eventKey="current_month" title="Current Month">
                      <div className="chart-area">
                        <RedeemedMonthly />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
}
