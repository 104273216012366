import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";

import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import AddSubCategorymain from "./addSubCategory.js";
import { useParams } from "react-router-dom";
import EditSubCategory from "./EditSubCategory.js";
import DeleteSubCategory from "./DeleteSubCategory.js";
import { toast, ToastContainer } from "react-toastify";
import { subCategoryListPages ,deleteSubCategory,updateSubCategoryStatus} from "../api/apiIntegration.js";
import LoaderOwn from "../common/loaderOwn.js";
import { getUser } from "../auth/authService.js";
export default function SubCategoryListPages() {
  const { id } = useParams();
  const [showHeaderFilter] = useState(true);
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [SubcategoryToDelete, setSubcategoryToDelete] = useState(null);
  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    try {
      setIsLoading(true);
      const response = await subCategoryListPages();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    setSelectedSubCategory(null);
    setShowEditOffcanvas(false);
  };
  const handleEdit = (SubCategory) => {
    setSelectedSubCategory(SubCategory);
    setShowEditOffcanvas(true);
  };

  const handleUpdate = (updatedSubCategory) => {
    setData((prevData) =>
      prevData.map((SubCategory) =>
        data.ProductSubCategoryId === updatedSubCategory.ProductSubCategoryId
          ? updatedSubCategory
          : SubCategory
      )
    );
  };
  const handleDelete = (SubCategory) => {
    setSubcategoryToDelete(SubCategory);
    setShowDeleteModal(true);
  };
 
  const confirmDelete = async () => {
    try {
      await deleteSubCategory (SubcategoryToDelete.ProductCategoryId);
      setData((prevData) =>
        prevData.filter((SubCategory) => SubCategory.ProductSubCategoryId !== SubcategoryToDelete.ProductSubCategoryId)
      );
      toast.success("SubCategory deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete SubCategory.");
    } finally {
      setShowDeleteModal(false);
      setSubcategoryToDelete(null);
    }
  };
  const handleStatusToggle = async (SubCategory, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: SubCategory.ProductSubCategoryId,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateSubCategoryStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };

  const handleError = (event) => {
    event.target.src = "../images/no_image.png";
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">SubCategories</h4>
                    <div className="ms-auto right_btn">
                      <AddSubCategorymain onAdd={list} id={id} />
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="ProductSubCategoryId"
                        columnAutoWidth={false}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}

                      >
                        <HeaderFilter visible={showHeaderFilter} />

                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Categories..."
                        />
                        <Grouping autoExpandAll={false} />
                        <Column
                         width={300}
                          dataField="ProductSubCategoryIcon"
                          caption="Icon"
                          alignment="center"
                          cellRender={({ data }) =>
                            data.ProductSubCategoryIcon ? (
                              <img
                                src={data.ProductSubCategoryIcon}
                                alt="ProductSubCategoryIcon"
                                style={{ maxWidth: "50%", height: "auto" }}
                                onError={handleError}
                              />
                            ) : (
                              <img
                                src="../images/no_image.png"
                                alt="ProductSubCategoryIcon"
                                style={{ maxWidth: "50%", height: "auto" }}
                              />
                            )
                          }
                        />
                        <Column
                          dataField="ProductCategoryName"
                          caption="Category Name"
                         width={200}
                        />
                        <Column
                          dataField="ProductSubCategoryName"
                          caption="Sub CategoryName"
                         width={200}
                        />
                        <Column
                          dataField="ProductSubCategoryDesc"
                          caption="Description"
                          width={200}
                          cellRender={({ data }) => (
                            <div style={{ whiteSpace: "normal", maxWidth:"100%" , height:"auto"}}>
                              {data.ProductSubCategoryDesc}
                            </div>
                          )}
                        />
                        <Column
                          alignment="center"
                          caption="Status"
                          width={250}
                          cellRender={({ data }) => (
                            <button
                              id="active_deactive"
                              className={`btn btn-${
                                data.IsEnabled === true ? "success " : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.IsEnabled === true ? "Active" : "Deactive"}
                            </button>
                          )}
                        />

                        <Column
                      width={100}
                         cellRender={({ data }) => (
                            
                            <Actions data={data}
                             onEdit={handleEdit} 
                             onDelete={handleDelete}
                             onToggleStatus={handleStatusToggle}

                              />
                       
                        )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>

      {selectedSubCategory && (
        <EditSubCategory
          load={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          SubcategoryData={selectedSubCategory}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
       <DeleteSubCategory
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}
export function Actions({ data, onEdit ,onDelete,onToggleStatus}) {
    const { IsEnabled } = data;

  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "150px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute"  }}>
        <a
          href="#"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="../images/more_icon.svg" />
          </span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="../images/blue_edit_icon.svg" alt="" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" href="#" onClick={() => onDelete(data)}>
              <img src="../images/blue_delete_icon.svg" alt="" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {IsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="../images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="../images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )}
          {/* <li>
            <a class="dropdown-item" href="#">
              <img src="../images/view-icons.svg" alt="" /> <span>View</span>
            </a>
          </li> */}
        </ul>
      </div>
      </div>
    </>
  );
}
