import React, { useCallback, useState, useEffect } from "react";
import LoaderOwn from "../common/loaderOwn.js";
import { useLocation } from "react-router-dom";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { useParams } from "react-router-dom";
import {
  competitionsDetails,
  competitionEntriesList,
  competitionWinnerList,
} from "../api/apiIntegration.js";
import { format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import Editcompetition from "./EditCompetition.js";
import { Button, Form, Offcanvas, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import DataRow from "./RowData.js";

export default function CompetitionDetails() {
  const [showHeaderFilter] = useState(true);

  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState("allEntries");
  const [data, setData] = useState([]);
  const [competitionWinner, setCompetitionwinner] = useState([]);
  const navigate = useNavigate();

  const handleTitleClick = (user) => {
    navigate(`/user/${user.competitionUserId}`);
  };

  useEffect(() => {
    list();
    list1();
    winnerList();
  }, [id]);

  const list = async () => {
    try {
      setIsLoading(true);
      const response = await competitionsDetails({ id: id });
      if (response.success) {
        setCompetition(response.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = (updatedcompetition) => {
    setCompetition((prevData) =>
      prevData.map((competition) =>
        competition.competitionId === updatedcompetition.competitionId
          ? updatedcompetition
          : competition
      )
    );
  };
  const list1 = async () => {
    try {
      setIsLoading(true);
      const response = await competitionEntriesList(id);
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const winnerList = async () => {
    try {
      setIsLoading(true);
      const response = await competitionWinnerList(id);
      if (response.success == true) {
        setCompetitionwinner(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  
  
  if (!competition) {
    return <div></div>;
  }
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy hh:mm a");
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-1">Competition Details</h4>
                  </div>
                  <div className="card-header d-flex align-items-center">
                    <div className="card-title ">
                      {" "}
                      <button type="button" className="btn mb-3" id="back"  onClick={() => navigate(-1)}>
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                  {isLoading ? (
                      <LoaderOwn />
                    ) : (
                  <div
                    id="competitiondetail" className="card-body mt-5 pt-2 px-3" >
                    
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-1">
                          <h2 style={{ fontSize: "36px", fontWeight: "300" }}>
                            {competition.title}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mb-4"
                      style={{ borderBottom: "1px solid rgb(199, 199, 199)" }}
                    >
                      <div className="col-md-6">
                        <div className="mb-3">
                          <p style={{ color: "#212529" }}>
                            {competition.subTitle}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <p>Start Date</p>
                          <h5 style={{ color: "#0A69D6" }}>
                            {formatDate(competition.startDate)}
                          </h5>{" "}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <p>End Date</p>

                          <h5 style={{ color: "#0A69D6" }}>
                            {formatDate(competition.endDate)}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="mb-3">
                          <p>Max Winners</p>
                          <h5>{competition.maxWinners}</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mr-5">
                        <div className="mb-5 ">
                          {!isEditing && (
                            <Button
                              id="btn1"
                              variant="primary"
                              onClick={toggleEditMode}
                            >
                              Edit Competition
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <p>Rules</p>
                          <h5>{competition.rules}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <p>Prize</p>
                          <h5>{competition.prize}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <Nav
                        variant="tabs"
                        activeKey={activeTab}
                        onSelect={(selectedKey) => setActiveTab(selectedKey)}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="allEntries">All Entries</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="winners">Winners</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <div className="tab-content">
                        {activeTab === "allEntries" && (
                          <div>
                            <DataGrid
                              id="gridContainers"
                              dataSource={data}
                              keyExpr="competitionId"
                              columnAutoWidth={true}
                              showBorders={true}
                              width="100%"
                              rowAlternationEnabled={true}
                              hoverStateEnabled={true}
                              // dataRowRender={DataRow}
                            >
                              <HeaderFilter visible={showHeaderFilter} />
                              <Paging defaultPageSize={10} />
                              <Pager visible={true} />

                              <SearchPanel
                                visible={true}
                                highlightCaseSensitive={true}
                              />

                              <Grouping autoExpandAll={false} />
                              <Column
                                dataField="competitionEntryId"
                                caption="EntryID"
                                alignment="left"
                              />
                              <Column
                                dataField="competitionUserName"
                                caption="User Name"
                                cellRender={({ data }) => (
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                    onClick={() => handleTitleClick(data)}
                                  >
                                    {data.competitionUserName}
                                  </div>
                                )}
                              />
                              <Column
                                dataField="competitionCreatedDate"
                                caption="Date & Time"
                                calculateCellValue={({
                                  competitionCreatedDate,
                                }) => formatDate(competitionCreatedDate)}
                              />
                              <Column
                                dataField="competitionStatus"
                                caption="Status"
                                alignment="left"
                              />
                              <Column
                                dataField="competitionReceiptNo"
                                caption="Receipt No"
                              />
                            </DataGrid>
                          </div>
                        )}
                        {activeTab === "winners" && (
                          <div>
                            <DataGrid
                              id="gridContainers"
                              dataSource={competitionWinner}
                              keyExpr="competitionId"
                              columnAutoWidth={true}
                              showBorders={true}
                              width="100%"
                              rowAlternationEnabled={true}
                              hoverStateEnabled={true}
                              // dataRowRender={DataRow}
                            >
                              <HeaderFilter visible={showHeaderFilter} />
                              <Paging defaultPageSize={8} />
                              <Pager visible={true} />

                              <SearchPanel
                                visible={true}
                                highlightCaseSensitive={true}
                                placeholder="Search Products..."
                              />

                              <Grouping autoExpandAll={false} />
                              <Column
                                dataField="competitionEntryId"
                                alignment="left"
                                caption="Entry Id"
                              />
                              <Column
                                dataField="competitionUserName"
                                caption="User Name"
                                cellRender={({ data }) => (
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                    onClick={() => handleTitleClick(data)}
                                  >
                                    {data.competitionUserName}
                                  </div>
                                )}
                              />
                              <Column
                                dataField="competitionCreatedDate"
                                alignment=""
                                caption="StartDate"
                                calculateCellValue={({
                                  competitionCreatedDate,
                                }) => formatDate(competitionCreatedDate)}
                              />
                              <Column
                                dataField="competitionReceiptNo"
                                alignment="left "
                                caption="ReceiptNo"
                              />
                              <Column
                                dataField="competitionIsValidated"
                                caption="Valid?"
                                alignment="center"
                                cellRender={({ data }) => (
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {data.competitionIsValidated ? "Valid" : "Not Valid"}
                                  </div>
                                )}
                              />
                                <Column
                                dataField="competitionIsNotified"
                                caption="Notified?"
                                cellRender={({ data }) => (
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {data.competitionIsNotified ? "Notified" : "Not Notified"}
                                  </div>
                                )}
                              />
                                <Column
                                dataField="competitionIsClaimed"
                                caption="Claimed?"
                                cellRender={({ data }) => (
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {data.competitionIsClaimed ? "UnClaimed" : "Claimed"}
                                  </div>
                                )}
                              />
                            </DataGrid>
                          </div>
                        )}
                      </div>
                    </div>
                 
                  </div>
                     )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
      <Editcompetition
        load={list}
        show={isEditing}
        onClose={toggleEditMode}
        competitionData={competition}
        onUpdate={handleUpdate}
      />
    </>
  );
}
