import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { subCategoryEdit,categoryList} from "../api/apiIntegration";
import { ThreeDots } from "react-loader-spinner";
import { getUser } from "../auth/authService.js";
import ImageModal from "./components/image_model.js";

const EditSubCategory = ({
  load,
  show,
  onClose,
  SubcategoryData,
  onUpdate,
}) => {
  const [SubCategory, setSubCategory] = useState(SubcategoryData);
  const [fileIcon, setFileIcon] = useState(null);
  const [SubCategoryName, setSubCategoryName] = useState(
    SubCategory.ProductSubCategoryName
  );
  const [categoryOptions, setcategoryOptions] = useState([]);
  const maxChars = 200;
  const [remainingChars, setRemainingChars] = useState(maxChars - SubCategory.ProductSubCategoryDesc.length);
  const [imagePreview, setImagePreview] = useState(
    SubCategory.ProductSubCategoryIcon
  );
  const [imageSelected, setImageSelected] = useState(false);
  const [SubCategoryDesc, setSubCategoryDesc] = useState(
    SubCategory.ProductSubCategoryDesc
  );
  const [productCategoriesId, setProductCategoriesId] = useState(
    SubCategory.ProductCategoryId
  );
  const [modalShow, setModalShow] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(SubCategory.IsEnabled);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    categoryListEvent();
  }, [SubcategoryData]);
  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success == true) {
        setcategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const handleCategoryId = (event) => {
    setProductCategoriesId(event.target.value);
  };
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
     
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension !== "png" && fileExtension !== "jpeg") {
        toast.error("Only PNG and JPEG images are allowed");
        setFileIcon(null);
        setImagePreview(SubCategory.ProductSubCategoryIcon);
        setImageSelected(false);
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileIcon(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile));
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setFileIcon(null);
          setImagePreview(SubCategory.ProductSubCategoryIcon);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };

  const handleSubCategoryName = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setSubCategoryDesc(countDesc);
    setRemainingChars(200 - countDesc.length);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userGet = await getUser();
    const formData = new FormData();
    formData.append("productSubCategoriesId", SubCategory.ProductSubCategoryId);
    formData.append("productCategoriesId", productCategoriesId);
    formData.append("productSubCategoriesName", SubCategoryName);
    formData.append("productSubCategoriesIcon", fileIcon);
    formData.append("productSubCategoriesDesc", SubCategoryDesc);
    formData.append("createdBy", userGet.AdminId);
    formData.append("IsEnabled", IsEnabled);
    console.log(formData);
    try {
      setLoading(true);
      const response = await subCategoryEdit(formData);
      if (response.success == true) {
        toast.success(response.message);
        load();
        onClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setModalShow(true);
  };

  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit SubCategory</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <div className="row">
          <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="Category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={productCategoriesId}
                  onChange={handleCategoryId}
                  required
                >
                  <option value="">Select...</option>
                  {categoryOptions.map((option, index) => (
                    <option key={index} value={option.ProductCategoryId}>
                      {option.ProductCategoryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="productSubCategoryName">
                <Form.Label>SubCategory Name</Form.Label>
                <Form.Control
                  type="text"
                  name="productSubCategoryName"
                  value={SubCategoryName}
                  onChange={handleSubCategoryName}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productCategoryDesc">
                <Form.Label>SubCategory Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="productSubCategoryDesc"
                  value={SubCategoryDesc}
                  onChange={handleDescChange}
                  maxLength="200"
                />
                <>
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Maximum characters: 200)
                  </Form.Text>
                  <br />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    You Have{" "}
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        padding: "2px 5px",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingChars}
                    </div>{" "}
                    Characters Left
                  </Form.Text>
                </>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productCategoryIcon">
                <Form.Label>SubCategory Icon</Form.Label>
                <Form.Control
                  type="file"
                  name="productSubCategoryIcon"
                  onChange={handleFileIcon}
                />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1080px x 900px )
                </Form.Text>
              </Form.Group>
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "100px", height: "100px" }}
                    onClick={() => handleImageClick()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span> Update SubCategory</span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={imagePreview}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditSubCategory;
