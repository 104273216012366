import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { TopBrandMonthChart } from "../../api/apiIntegration.js";
import LoaderOwn from "../../common/loaderOwn.js";

const MonthChartBrand = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    try {
      setIsLoading(true);
      const response = await TopBrandMonthChart();
      if (response.success) {
        setData(response.data);
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderOwn />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const brands = Array.from(
    new Set([
      ...data.currentMonth.map((item) => item.BrandName),
      ...data.previousMonth.map((item) => item.BrandName),
    ])
  );

  const chartSeries = brands.map((brand) => {
    const currentMonthItem = data.currentMonth.find(
      (item) => item.BrandName === brand
    );
    const previousMonthItem = data.previousMonth.find(
      (item) => item.BrandName === brand
    );

    return {
      name: brand,
      data: [
        currentMonthItem ? currentMonthItem.TotalAmount : 0,
        previousMonthItem ? previousMonthItem.TotalAmount : 0,
      ],
    };
  });

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    stroke: {
      width: 2,
    },
    title: {
      text: "Top 5 Selling Brands Comparison",
    },
    xaxis: {
      categories: ["Current Month", "Previous Month"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Total Amount",
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default MonthChartBrand;
