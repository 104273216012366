import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ValueSalesWeekly from "./ValueSalesWeekly_chart";
import ValueSalesYearVsLastYear from "./ValueSalesYearVsLastYear_chart";
import { YearVsLastYearCategoryApi } from "../../api/apiIntegration";
import LoaderOwn from "../../common/loaderOwn";

const BeerSpiritsWinesMain = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchYearData = async () => {
    try {
      setIsLoading(true);
      const response = await YearVsLastYearCategoryApi();

      if (response.success) {
        const dataGet = response.data;
        setData(dataGet);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handelTap = (index) => {
    if (index === "current_month") {
      fetchYearData();
    }
  };
  return (
    <div className="col-lg-12">
      <div className="card card-chart">
        <div className="card-header">
          <h5 className="card-category">
            Value sales of Beer, spirits & wines
          </h5>
          <h3 className="card-title"> Performance</h3>
        </div>
        <div className="tab_pad customs_tab">
          {/* <TabOwn
          onStatusChange={handleValueSalesChange}
          buttonName={["Bar", "Line"]}
          active={isuserValueSalesDailyType === "bar" ? 0 : 1}
          onActiveChange={(index) => {
            setUserValueSalesDailyType(
              index === 0 ? "bar" : "line"
            );
          }}
        /> */}
          <Tabs className="pos-abs" onSelect={handelTap}>
            {/* <Tab eventKey="daily" title="Daily">
            <div className="chart-area">
              {isCategoryChartLoading === true ? (
                <LoaderOwn />
              ) : (
                <ValueSalesDaily
                  data={orderCategoryChartGet}
                  chartType={isuserValueSalesDailyType}
                />
              )}
            </div>
          </Tab> */}
            <Tab eventKey="last_week" title="Monthly">
              <div className="chart-area">
                <ValueSalesWeekly />
              </div>
            </Tab>
            <Tab eventKey="current_month" title="Year to date Vs Last year">
              <div className="chart-area">
                {isLoading ? (
                  <LoaderOwn />
                ) : (
                  <ValueSalesYearVsLastYear data={data} />
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default BeerSpiritsWinesMain;
