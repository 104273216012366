import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { brandEdit } from "../api/apiIntegration";
import { ThreeDots } from "react-loader-spinner";
import { getUser } from "../auth/authService.js";
import ImageModal from "./components/image_model.js";

const EditBrand = ({ load, show, onClose, brandData, onUpdate }) => {
  const [brand, setBrand] = useState(brandData);
  const [brandName, setBrandName] = useState(brand.BrandName);
  const [brandDesc, setBrandDesc] = useState(brand.BrandDesc);
  const [brandUrl, setBrandUrl] = useState(brand.BrandURL);
  const [fileIcon, setFileIcon] = useState(null);
  const [fileBanner, setFileBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [imagePreviewBanner, setImagePreviewBanner] = useState(
    brand.BrandBanner
  );
  const [imagePreviewIcon, setImagePreviewIcon] = useState(brand.BrandIcon);
  const maxChars = 250;
  const [remainingChars, setRemainingChars] = useState(
    maxChars - brand.BrandDesc.length
  );
  const [IsEnabled, setIsEnabled] = useState(brand.IsEnabled);
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleImage1Click = () => {
    setModalShow1(true);
  };
  const validateImage = (file, requiredWidth, requiredHeight, errorMessage) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== requiredWidth || img.height !== requiredHeight) {
          toast.error(errorMessage);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {}, [brandData]);

  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
  };
  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setBrandDesc(countDesc);
    setRemainingChars(maxChars - countDesc.length);
  };

  const handleBrandurlChange = (event) => {
    setBrandUrl(event.target.value);
  };
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check file size
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size exceeds 150KB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        toast.error("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      }
      validateImage(selectedFile, 180, 90, "Icon dimensions must be 180 x 90.");
      setFileIcon(selectedFile);
    }
  };
  const handleFileBanner = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 500 * 1024) {
        toast.error("File size exceeds 500KB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        toast.error("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      }
      validateImage(
        selectedFile,
        1242,
        865,
        "Banner dimensions must be 1242 x 865."
      );
      setFileBanner(selectedFile);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userGet = await getUser();
    const formData = new FormData();
    formData.append("brandId", brand.BrandId);
    formData.append("brandName", brandName);
    formData.append("brandDesc", brandDesc);
    formData.append("brandURL", brandUrl);
    formData.append("brandBanner", fileBanner);
    formData.append("brandIcon", fileIcon);
    formData.append("createdBy", userGet.AdminId);
    formData.append("IsEnabled", IsEnabled);
    try {
      setLoading(true);
      const response = await brandEdit(formData);
      if (response.success == true) {
        toast.success(response.message);
        load();
        onClose();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked); // Update state when the switch is toggled
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Brand</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="BrandName">
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  name="BrandName"
                  value={brandName}
                  onChange={handleBrandNameChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="BrandDesc">
                <Form.Label>Brand Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="BrandDesc"
                  value={brandDesc}
                  onChange={handleDescChange}
                  maxLength={maxChars}
                />
                <>
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Maximum characters: {maxChars})
                  </Form.Text>
                  <br />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    You Have{" "}
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        padding: "2px 5px",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingChars}
                    </div>{" "}
                    Characters Left
                  </Form.Text>
                </>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="BrandURL">
                <Form.Label>Brand URL</Form.Label>
                <Form.Control
                  type="url"
                  name="BrandURL"
                  value={brandUrl}
                  onChange={handleBrandurlChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="BrandBanner">
                <Form.Label>Brand Banner</Form.Label>
                <Form.Control
                  type="file"
                  name="Banner"
                  onChange={handleFileBanner}
                />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1242px x 865px )
                </Form.Text>
              </Form.Group>
            </div>
            {fileBanner === null ? (
              <div className="mt-3">
                <img
                  src={imagePreviewBanner}
                  alt="Banner Preview"
                  style={{ maxWidth: "100%", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              </div>
            ) : (
              <div className="mt-3">
                <img
                  src={URL.createObjectURL(fileBanner)}
                  alt="Banner Preview"
                  style={{ maxWidth: "100%", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="BrandBanner">
                <Form.Label>Brand Icon</Form.Label>
                <Form.Control
                  type="file"
                  name="Banner"
                  onChange={handleFileIcon}
                />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  180px x 90px )
                </Form.Text>
              </Form.Group>
            </div>
            {fileIcon === null ? (
              <div className="mt-3">
                <img
                  src={imagePreviewIcon}
                  alt="Banner Preview"
                  style={{ maxWidth: "100%", height: "100px" }}
                  onClick={() => handleImage1Click()}
                />
              </div>
            ) : (
              <div className="mt-3">
                <img
                  src={URL.createObjectURL(fileIcon)}
                  alt="Banner Preview"
                  style={{ maxWidth: "100%", height: "100px" }}
                  onClick={() => handleImage1Click()}
                />
              </div>
            )}
          </div>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span>Update Brand</span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={
            fileBanner === null
              ? imagePreviewBanner
              : URL.createObjectURL(fileBanner)
          }
        />
        <ImageModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
          imageUrl={
            fileIcon === null ? imagePreviewIcon : URL.createObjectURL(fileIcon)
          }
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditBrand;
