import React from 'react';

const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.Header}</td>
      <td>{rowInfo.data.Description}</td>
      <td>{rowInfo.data.Delivery}</td>
      <td>{rowInfo.data.AudienceType}</td>
      <td>{rowInfo.data.AudienceTypeValue}</td>
      <td>{rowInfo.data.DateCreated}</td>

      <td>{rowInfo.data.Actions}</td>

    </tr>
  </React.Fragment>

);
export default DataRow;
