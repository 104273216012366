import React from 'react';

export default function Header() {
  return (
    <>
    <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light fixed-top custom-navbar">
      <div class="container-fluid">
        <button data-bs-button-init class="navbar-toggler" type="button" data-bs-collapse-init data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-bars"></i>
        </button>
        <a class="navbar-brand py-0 px-2" href="#">
          <img  class = "logo" src="../images/logo1.png" height="35" alt="Logo" />
        </a>
        <ul class="navbar-nav ms-auto d-flex flex-row">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle hidden-arrow d-flex align-items-center" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img (31).webp" class="rounded-circle" height="22" alt="Avatar" loading="lazy" />
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li><a class="dropdown-item" href="#">My profile</a></li>
                <li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item" href="#">Logout</a></li>
                <li><a class="dropdown-item" href="/change-password">Change password </a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    </>
  )
}
