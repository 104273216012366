import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";

import { top10SubCategoriesChart } from "../../api/apiIntegration";
import LoaderOwn from "../../common/loaderOwn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const colors = [
  "#FF5733", // Red
  "#33FF57", // Green
  "#3357FF", // Blue
  "#FF33A1", // Pink
  "#FF8333", // Orange
  "#33FFD5", // Aqua
  "#A533FF", // Purple
  "#FFE933", // Yellow
  "#FF3333", // Bright Red
  "#33FF8D", // Light Green
];
const Top10SubCategoryPieChart = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("current_month");

  useEffect(() => {
    fetchData({ period: currentTab });
  }, [currentTab]);

  const fetchData = async (body) => {
    try {
      setIsLoading(true);
      const response = await top10SubCategoriesChart(body);

      if (response.success) {
        const dataGet = response.data;
        if (Array.isArray(dataGet)) {
          const data = dataGet.map((item, index) => {
            const percentageChange = (
              ((item.TotalAmount - item.lastYearCount) / item.lastYearCount) *
              100
            ).toFixed(2);

            const icon =
              percentageChange >= 0 ? "images/arrow.png" : "images/down.png";
            return {
              name: `${item.SubCategoryName} (Total value: ${
                item.TotalAmount
              })  <img src="${icon}" alt="${
                percentageChange >= 0 ? "Increase" : "Decrease"
              } Icon" style="width:16px;height:16px;margin-right:4px;"/>${
                percentageChange >= 0 ? "+" : ""
              }${percentageChange}%<br>Last year`,
              y: item.lastYearCount,
              color: colors[index % colors.length], // Assign color from the palette
            };
          });
          setSeries(data);
        } else {
          console.error("Expected an array but got:", dataGet);
        }
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const options = {
    chart: {
      type: "pie",
      toolbar: {
        show: true,
        tools: {
          download: true, // Enable the download button
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        return opts.w.config.labels[opts.dataPointIndex];
      },
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    legend: { show: false, position: "bottom" },
    labels: series.map((item) => item.name),
  };
  const CustomLegend = ({ series }) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {series.map((item, index) => (
          <div
            key={index}
            style={{
              margin: "5px 10px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                width: "16px",
                height: "16px",
                marginRight: "8px",
                backgroundColor: item.color,
                display: "inline-block",
              }}
            ></span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "1",
              }}
              dangerouslySetInnerHTML={{
                __html: item.name.replace("Last year", ""),
              }}
            ></span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="col-lg-6">
      <div className="card card-chart">
        <div className="card-header">
          <h5 className="card-category">
            Top 10 SubCategories Rolling Month Vs Last Year
          </h5>
        </div>
        <div className="card-body">
          <div className="tab_pad customs_tab">
            {/* <Tabs
              className="pos-abs"
              activeKey={currentTab}
              onSelect={(k) => setCurrentTab(k)}
            > */}
            {/* <Tab eventKey="current_month" title="Current Month"> */}
            {isLoading ? (
              <LoaderOwn />
            ) : (
              <div>
                <ApexCharts
                  options={options}
                  series={series.map((item) => item.y)}
                  type="pie"
                  height="480"
                />
                <CustomLegend series={series} />
              </div>
            )}
            {/* </Tab> */}
            {/* <Tab eventKey="last_year" title="Last Year">
                {isLoading ? (
                  <LoaderOwn />
                ) : (
                  <ApexCharts
                    options={options}
                    series={series.map((item) => item.y)}
                    type="pie"
                    height="480"
                  />
                )}
              </Tab> */}
            {/* </Tabs> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top10SubCategoryPieChart;
