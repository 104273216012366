import React, { useCallback, useState } from "react";

export default function Sidebar() {
  const [showNotifications, setShowNotifications] = useState(false);


  const toggleNotifications = () => {
    setShowNotifications(prevState => !prevState);
  };
  return (
    <>
    <nav id="sidebarMenu" class="collapse d-lg-block sidebar collapse">
      <div class="position-sticky">
        <div className='sidebar_bg  mx-3 mt-4'>
          <div class="list-group list-group-flush">
            <a class="list-group-item list-group-item-action ripple" href="/dashboard">
              <img src="../images/dashboard.png" alt="" /> <span>Dashboard</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/user">
              <img src="../images/user.png" alt="" /> <span>User</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/category">
              <img src="../images/category.png" alt="" /> <span>Category</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/subcategory-list">
              <img src="../images/list.png" alt="" /> <span>SubCategory</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/brands">
               <img src="../images/brandd.png" alt="" /> <span>Brands </span>
            </a>
           
            <a class="list-group-item list-group-item-action ripple" href="/product">
              <img src="../images/product.png" alt="" /> <span>Products</span>
            </a>
            
            <a class="list-group-item list-group-item-action ripple" href="/purchase-history">
              <img src="../images/orders-icon.png" alt="" /> <span>Puschase History </span>
            </a>
            {/* <a class="list-group-item list-group-item-action ripple" href="/logindesign">
              <img src="./images/store.png" alt="" /> <span>Login</span>
            </a> */}
            <a class="list-group-item list-group-item-action ripple" href="/wishlist">
              <img src="../images/wishlis.png" alt="" /> <span>Wishlist </span>
            </a>
            <a style={{cursor:"pointer" }}  className="list-group-item list-group-item-action ripple"   onClick={toggleNotifications}>
                <img  src="../images/notification.png" alt="" /> <span >Notifications</span>
                <span   id="icon"><img src="../images/down-arrow.png" style={{ float: 'right' }}alt="" /> </span>

                {/* <i id="icon"className={`bi bi-chevron-`} style={{ float: 'right' }}></i> */}
              </a>
            
              <div className={`submenu ${showNotifications ? 'open' : ''}`}>    
                <a className="list-group-item list-group-item-action ripple" href="/pushnotifications">
                    <span>Push </span>
                  </a>
                  <a className="list-group-item list-group-item-action ripple" href="/emailNotifications">
                    <span>Email </span>
                  </a>
                  <a className="list-group-item list-group-item-action ripple" href="/sms-notifications">
                    <span>SMS </span>
                  </a>
                  <a className="list-group-item list-group-item-action ripple" href="/whatsapp-notifications">
                    <span>WhatsApp </span>
                  </a>
                  <a className="list-group-item list-group-item-action ripple" href="/SMT-notifications">
                    <span>SMTP Configuration</span>
                  </a>
                </div>
            
           
            <a class="list-group-item list-group-item-action ripple" href="/banners">
               <img src="../images/banner.png" alt="" /> <span> banners </span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/competitions">
               <img src="../images/stage.png" alt="" /> <span> competitions </span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/notifications">
              <img src="../images/notification.png" alt="" /> <span>Notifications</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/email-template">
              <img src="../images/mail.png" alt="" /> <span>Email template</span>
            </a>
            <a class="list-group-item list-group-item-action ripple" href="/logout">
              <img src="../images/logout.png" alt="" /> <span>Logout </span>
            </a>
            {/* <a class="list-group-item list-group-item-action ripple" >
              <img src="../images/padlock.png" alt="" /> <span>Change password </span>
            </a> */}
            
          </div>
        </div>
      </div>
    </nav>
    </>
  )
}

