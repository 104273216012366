import React from 'react';

const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.Location}</td>
      <td>{rowInfo.data.Address}</td>
      <td>{rowInfo.data. Contact}</td>
      <td>{rowInfo.data. Actions}</td>

      
    </tr>
  </React.Fragment>

);
export default DataRow;
