import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailTemplateEdit } from "../api/apiIntegration"; 
import { getUser } from "../auth/authService.js";
import Editor from "./components/Editor"

const EditEmailTemplate = ({onAdd, show, onClose, templateData, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(templateData);
  const [templateType, setTemplateType] = useState(templateData.TemplateType);
  const [emailContent, setEmailContent] = useState('');
  const [subject, setSubject] = useState(templateData.Subject);

  useEffect(() => {
    setTemplate(templateData);
    setTemplateType(templateData.TemplateType);
    setEmailContent(templateData.EmailContent);
    setSubject(templateData.Subject);
  }, [templateData]);

  const handleContent = (value) => {
    setEmailContent(value);
  };

  const handleTemplateType = (event) => {
    setTemplateType(event.target.value);
  };

  const handleSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userGet = await getUser();

   
    const formData = {
      emailTemplateID: template.EmailTemplateID,
      templateType,
      emailContent,
      subject,
      createdBy: userGet.AdminId
    };

   
    console.log("Payload values:", formData);

    try {
      setLoading(true);
      const response = await EmailTemplateEdit(formData);
      if (response.success) {
        toast.success(response.message);
        onUpdate(template);
        onAdd(); // Update the template in the parent component
        onClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement={"end"}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Email Template</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <Form.Group className="mb-2" controlId="exampleForm.ControlSelect1">
                <Form.Label>Template for</Form.Label>
                <Form.Control as="select" value={templateType} onChange={handleTemplateType}>
                  <option value="">Select an option</option>
                  <option value="Option1">Option1</option>
                  <option value="Option2">Option2</option>
                  <option value="Option3">Option3</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <Form.Group className="mb-2" controlId="header">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  value={subject}
                  type="text"
                  placeholder=""
                  onChange={handleSubject}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Content</Form.Label>
                <Editor value={emailContent} onChange={handleContent} />
              </Form.Group>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button id="btn1" variant="primary" type="submit" disabled={loading}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span> Update </span>}
              </div>
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default EditEmailTemplate;
