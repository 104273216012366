import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import {
  competitionAdd,
  subCategoryList,
  categoryList,
  productDropDownList,
} from "../api/apiIntegration.js";
import { getUser } from "../auth/authService.js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { Group } from "devextreme-react/cjs/diagram.js";
import Select from "react-select";
import ImageModal from "./components/image_model.js";
function OffCanvasAddCompetition({ load, name, ...props }) {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [startDate, setStartDate] = useState(today);
  const [rules, setRules] = useState("");
  const [prize, setPrize] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isFeatured, setIsFeatured] = useState("");
  const [competitionImageUrl, setCompetitionImageUrl] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [maxWinners, setMaxWinners] = useState("");
  const [maxRedraw, setMaxRedraw] = useState("");
  const [termsUrl, setTermsUrl] = useState("");
  const [remainingChars, setRemainingChars] = useState(190);
  const [imageSelected, setImageSelected] = useState(false);
  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);
  const [IsEnabled, setIsEnabled] = useState(false);

  const [ruleItems, setRuleItems] = useState([
    {
      categoryId: "",
      subCategoryId: "",
      productId: "",
      quantity: "",
      groupId: "",
      posItemUnit: "PCS",
    },
  ]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [ruleItemOptions, setRuleItemOptions] = useState({});

  useEffect(() => {
    categoryListEvent();
  }, []);

  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success) {
        setCategoryOptions(response.data);
      } else {
        toast.error("Failed to fetch categories.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching categories.");
    }
  };

  const fetchSubCategoryList = async (categoryId) => {
    try {
      const response = await subCategoryList(categoryId);
      if (response.success) {
        return response.data;
      } else {
        toast.error("Failed to fetch subcategories.");
        return [];
      }
    } catch (error) {
      toast.error("An error occurred while fetching subcategories.");
      return [];
    }
  };

  const fetchProductList = async (categoryId, subCategoryId) => {
    try {
      const response = await productDropDownList(categoryId, subCategoryId);
      if (response.success) {
        return response.data;
      } else {
        toast.error("Failed to fetch products.");
        return [];
      }
    } catch (error) {
      toast.error("An error occurred while fetching products.");
      return [];
    }
  };

  const handleCategorySelectChange = async (index, event) => {
    const selectedCategoryId = event.target.value;
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems[index].categoryId = selectedCategoryId;
    updatedRuleItems[index].subCategoryId = "";
    updatedRuleItems[index].productId = "";
    setRuleItems(updatedRuleItems);

    const subCategories = await fetchSubCategoryList(selectedCategoryId);
    setRuleItemOptions((prevOptions) => ({
      ...prevOptions,
      [index]: { ...prevOptions[index], subCategories },
    }));
  };

  const handleSubCategorySelectChange = async (index, event) => {
    const selectedSubCategoryId = event.target.value;
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems[index].subCategoryId = selectedSubCategoryId;
    updatedRuleItems[index].productId = "";
    setRuleItems(updatedRuleItems);

    const products = await fetchProductList(
      updatedRuleItems[index].categoryId,
      selectedSubCategoryId
    );
    setRuleItemOptions((prevOptions) => ({
      ...prevOptions,
      [index]: { ...prevOptions[index], products },
    }));
  };

  const handleProductSelectChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems[index] = {
      ...updatedRuleItems[index],
      productId: selectedValues,
    };
    setRuleItems(updatedRuleItems);
  };

  const addRuleItem = () => {
    setRuleItems([
      ...ruleItems,
      {
        categoryId: "",
        subCategoryId: "",
        productId: "",
        quantity: "",
        groupId: "",
        posItemUnit: "PCS",
      },
    ]);
  };

  const deleteRuleItem = (index) => {
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems.splice(index, 1);
    setRuleItems(updatedRuleItems);
    setRuleItemOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
      delete updatedOptions[index];
      return updatedOptions;
    });
  };

  const validateImage = (file, requiredWidth, requiredHeight, errorMessage) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== requiredWidth || img.height !== requiredHeight) {
          toast.error(errorMessage);
          setCompetitionImageUrl(null);
        } else {
          setCompetitionImageUrl(file);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  const handleFileImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size exceeds 150KB.");
        return;
      } else if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        toast.error("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      } else {
        validateImage(
          selectedFile,
          1200,
          800,
          "Competition image dimensions must be 1200 x 800."
        );
      }
    }
  };
  const handleTitleChange = (e) => {
    const countTitle = e.target.value;
    setTitle(countTitle);
    setRemainingChars(190 - countTitle.length);
  };
  function formatToISOString(dateString) {
    const date = new Date(dateString);
    date.setHours(0, 0, 1, 0);
    return date.toISOString();
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title == "") {
      toast.error("title name is required");
    } else if (subTitle == "") {
      toast.error("subTitle is required");
    } else if (startDate == "") {
      toast.error("startDate is required");
    } else if (rules == "") {
      toast.error("rules is required");
    } else if (prize == "") {
      toast.error("prize is required");
    } else if (isFeatured == "") {
      toast.error("isFeatured is required");
    } else if (competitionImageUrl == null) {
      toast.error("fileImage is required");
    } else if (endDate == "") {
      toast.error("endDate is required");
    } else if (maxWinners == "") {
      toast.error("maxWinners is required");
    } else if (maxRedraw == "") {
      toast.error("maxRedraw is required");
    } else if (termsUrl == "") {
      toast.error("termsUrl is required");
    } else if (ruleItems == "") {
      toast.error("ruleItems is required");
    } else {
      const userGet = await getUser();
      const form = new FormData();
      form.append("title", title);
      form.append("subTitle", subTitle);
      form.append("startDate", formatToISOString(startDate));
      form.append("endDate", formatToISOString(endDate));
      form.append("rules", rules);
      form.append("createdBy", userGet.AdminId);
      form.append("prize", prize);
      form.append("isFeatured", isFeatured);
      form.append("competitionImageUrl", competitionImageUrl);
      form.append("maxWinners", maxWinners);
      form.append("maxRedraw", maxRedraw);
      form.append("termsUrl", termsUrl);
      form.append("IsEnabled", IsEnabled);
      form.append("ruleItem", JSON.stringify(ruleItems));

      console.log("fgfdgdfgdg:", ruleItems);

      try {
        setLoading(true);
        const response = await competitionAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          load();
          handleClose();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  useEffect(() => {
    handleInside();
  }, []);
  const handleInside = () => {
    setModalShow(false);
    setCompetitionImageUrl(null);
    setTitle("");
    setSubTitle("");
    setStartDate("");
    setEndDate("");
    setRules("");
    setPrize("");
    setIsFeatured("");
    setCompetitionImageUrl(null);
    setMaxWinners("");
    setMaxRedraw("");
    setTermsUrl("");
    setRuleItems([
      {
        categoryId: "",
        subCategoryId: "",
        productId: "",
        quantity: "",
        groupId: "",
        posItemUnit: "PCS",
      },
    ]);
    setRemainingChars(190);
    setIsEnabled(false);
  };
  return (
    <>
      <Button id="btn1" variant="primary" onClick={handleShow}>
        Add Competition
      </Button>

      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Competition</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled}
                  onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`}
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={handleTitleChange}
                maxLength={190}
              />
              <>
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Maximum characters: 190)
                </Form.Text>
                <br />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  You Have{" "}
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft: "5px",
                      padding: "2px 5px",
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    {remainingChars}
                  </div>{" "}
                  Characters Left
                </Form.Text>
              </>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>SubTitle</Form.Label>
              <Form.Control
                type="text"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
              />
            </Form.Group>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy/MM/dd"
                    className="form-control"
                    minDate={today}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy/MM/dd"
                    className="form-control"
                    minDate={today}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Rules</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={rules}
                onChange={(e) => setRules(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Prize</Form.Label>
              <Form.Control
                type="text"
                value={prize}
                onChange={(e) => setPrize(e.target.value)}
              />
            </Form.Group>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-3 d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    id="isFeatured"
                    checked={isFeatured === "true"}
                    onChange={(e) =>
                      setIsFeatured(e.target.checked ? "true" : "false")
                    }
                  />
                  <Form.Label htmlFor="isFeatured" className="ms-2 mb-0">
                    Is Featured?
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Competition Image</Form.Label>
              <Form.Control type="file" onChange={handleFileImage} />
              <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension 1200
                x 800 )
              </Form.Text>
            </Form.Group>
            {competitionImageUrl !== null && (
              <img
                src={URL.createObjectURL(competitionImageUrl)}
                alt="Preview"
                style={{ width: "100px", height: "100px" }}
                onClick={() => handleImageClick()}
              />
            )}
            <Form.Group className="mb-3">
              <Form.Label>Maximum Winners</Form.Label>
              <Form.Control
                type="number"
                value={maxWinners}
                onChange={(e) => setMaxWinners(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Maximum Redraws</Form.Label>
              <Form.Control
                type="number"
                value={maxRedraw}
                onChange={(e) => setMaxRedraw(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Terms URL</Form.Label>
              <Form.Control
                type="text"
                value={termsUrl}
                onChange={(e) => setTermsUrl(e.target.value)}
              />
            </Form.Group>
            <div key={0} className="rule-item mb-3">
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-2" controlId={`categoryId${0}`}>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={ruleItems[0].categoryId}
                      onChange={(e) => handleCategorySelectChange(0, e)}
                    >
                      <option value="">Select...</option>
                      {categoryOptions.map((option) => (
                        <option
                          key={option.ProductCategoryId}
                          value={option.ProductCategoryId}
                        >
                          {option.ProductCategoryName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-2" controlId={`subCategoryId${0}`}>
                    <Form.Label>SubCategory</Form.Label>
                    <Form.Control
                      as="select"
                      value={ruleItems[0].subCategoryId}
                      onChange={(e) => handleSubCategorySelectChange(0, e)}
                    >
                      <option value="">Select...</option>
                      {ruleItemOptions[0]?.subCategories?.map((option) => (
                        <option
                          key={option.ProductSubCategoryId}
                          value={option.ProductSubCategoryId}
                        >
                          {option.ProductSubCategoryName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId={`productId${0}`}>
                  <Form.Label>Product</Form.Label>
                  <Select
                    id="select"
                    isMulti
                    options={ruleItemOptions[0]?.products?.map((product) => ({
                      value: product.ProductRecordId,
                      label: product.ProductName,
                    }))}
                    value={(ruleItems[0].productId || []).map((id) => ({
                      value: id,
                      label: ruleItemOptions[0]?.products?.find(
                        (p) => p.ProductRecordId === id
                      )?.ProductName,
                    }))}
                    onChange={(selectedOptions) =>
                      handleProductSelectChange(0, selectedOptions)
                    }
                  ></Select>
                </Form.Group>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mb-2" controlId={`posItemUnit${0}`}>
                    <Form.Label>POS Item Unit</Form.Label>
                    <Form.Control
                      as="select"
                      value={ruleItems[0].posItemUnit}
                      onChange={(e) => {
                        const updatedRuleItems = [...ruleItems];
                        updatedRuleItems[0].posItemUnit = e.target.value;
                        setRuleItems(updatedRuleItems);
                      }}
                    >
                      <option value="PCS">PCS</option>
                      <option value="6x">6x</option>
                      <option value="12x">12x</option>
                      <option value="24x">24x</option>
                      <option value="36x">36x</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-2" controlId={`quantity${0}`}>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      value={ruleItems[0].quantity}
                      onChange={(e) => {
                        const updatedRuleItems = [...ruleItems];
                        updatedRuleItems[0].quantity = e.target.value;
                        setRuleItems(updatedRuleItems);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-2" controlId={`groupId${0}`}>
                  <Form.Label>groupId</Form.Label>
                  <Form.Control
                    type="number"
                    value={ruleItems[0].groupId}
                    onChange={(e) => {
                      const updatedRuleItems = [...ruleItems];
                      updatedRuleItems[0].groupId = e.target.value;
                      setRuleItems(updatedRuleItems);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {ruleItems.map((ruleItem, index) =>
                  index !== 0 ? (
                    <div key={index} className="rule-item mb-3">
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-2"
                            controlId={`categoryId${index}`}
                          >
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                              as="select"
                              value={ruleItem.categoryId}
                              onChange={(e) =>
                                handleCategorySelectChange(index, e)
                              }
                            >
                              <option value="">Select...</option>
                              {categoryOptions.map((option) => (
                                <option
                                  key={option.ProductCategoryId}
                                  value={option.ProductCategoryId}
                                >
                                  {option.ProductCategoryName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-2"
                            controlId={`subCategoryId${index}`}
                          >
                            <Form.Label>SubCategory</Form.Label>
                            <Form.Control
                              as="select"
                              value={ruleItem.subCategoryId}
                              onChange={(e) =>
                                handleSubCategorySelectChange(index, e)
                              }
                            >
                              <option value="">Select...</option>
                              {ruleItemOptions[index]?.subCategories?.map(
                                (option) => (
                                  <option
                                    key={option.ProductSubCategoryId}
                                    value={option.ProductSubCategoryId}
                                  >
                                    {option.ProductSubCategoryName}
                                  </option>
                                )
                              )}
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <Form.Group
                          className="mb-2"
                          controlId={`productId${index}`}
                        >
                          <Form.Label>Product</Form.Label>
                          <Select
                            id="select"
                            isMulti
                            options={ruleItemOptions[index]?.products?.map(
                              (product) => ({
                                value: product.ProductRecordId,
                                label: product.ProductName,
                              })
                            )}
                            value={(ruleItem.productId || []).map((id) => ({
                              value: id,
                              label: ruleItemOptions[index]?.products?.find(
                                (p) => p.ProductRecordId === id
                              )?.ProductName,
                            }))}
                            onChange={(selectedOptions) =>
                              handleProductSelectChange(index, selectedOptions)
                            }
                          ></Select>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-2"
                            controlId={`posItemUnit${index}`}
                          >
                            <Form.Label>POS Item Unit</Form.Label>
                            <Form.Control
                              as="select"
                              value={ruleItem.posItemUnit}
                              onChange={(e) => {
                                const updatedRuleItems = [...ruleItems];
                                updatedRuleItems[index].posItemUnit =
                                  e.target.value;
                                setRuleItems(updatedRuleItems);
                              }}
                            >
                              <option value="PCS">PCS</option>
                              <option value="6x">6x</option>
                              <option value="12x">12x</option>
                              <option value="24x">24x</option>
                              <option value="36x">36x</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group
                            className="mb-2"
                            controlId={`quantity${index}`}
                          >
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                              type="number"
                              value={ruleItem.quantity}
                              onChange={(e) => {
                                const updatedRuleItems = [...ruleItems];
                                updatedRuleItems[index].quantity =
                                  e.target.value;
                                setRuleItems(updatedRuleItems);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group
                          className="mb-2"
                          controlId={`groupId${index}`}
                        >
                          <Form.Label>groupId</Form.Label>
                          <Form.Control
                            type="number"
                            value={ruleItem.groupId}
                            onChange={(e) => {
                              const updatedRuleItems = [...ruleItems];
                              updatedRuleItems[index].groupId = e.target.value;
                              setRuleItems(updatedRuleItems);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div
                        className="text-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          variant="danger"
                          onClick={() => deleteRuleItem(index)}
                          disabled={loading}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button id="btn1" onClick={addRuleItem}>
                    +Add Rule
                  </Button>
                </div>
              </div>
            </div>

            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Create </span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={
              competitionImageUrl === null
                ? ""
                : URL.createObjectURL(competitionImageUrl)
            }
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default function AddCompetition({ onAdd }) {
  return (
    <>
      <OffCanvasAddCompetition
        key={1}
        placement={"end"}
        name={"+ Add More"}
        load={onAdd}
      />
    </>
  );
}
