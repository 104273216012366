import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { categoryAdd } from "../api/apiIntegration.js";
import { getUser } from "../auth/authService.js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./components/image_model.js";

function OffCanvasAddCategorymain({ load, name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryIcon, setCategoryIcon] = useState(null);
  const [remainingChars, setRemainingChars] = useState(200);
  const [IsEnabled, setIsEnabled] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleCategoryIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension !== "jpg" && fileExtension !== "jpeg") {
        toast.error("Only JPG and JPEG images are allowed");
        setCategoryIcon(null);
        setImagePreview(null);
        setImageSelected(false);
        return;
      }
      // Check file size (150KB = 150 * 1024 bytes)
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setCategoryIcon(null);
        setImageSelected(false);
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width === 1080 && img.height === 900) {
          setCategoryIcon(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile));
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setCategoryIcon(null);
          setImagePreview(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };

  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setCategoryDesc(countDesc);
    setRemainingChars(200 - countDesc.length);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (categoryName == "") {
      toast.error("Category name is required");
    } else if (categoryDesc == "") {
      toast.error("Category description is required");
    } else if (categoryIcon == null) {
      toast.error("Icon is required");
    } else {
      const userGet = await getUser();
      e.preventDefault();
      const form = new FormData();
      form.append("productCategoryName", categoryName);
      form.append("productCategoryDesc", categoryDesc);
      form.append("orderSequence", 1);
      form.append("createdBy", userGet.AdminId);
      form.append("productCategoryIcon", categoryIcon);
      form.append("IsEnabled", IsEnabled);
      try {
        setLoading(true);
        const response = await categoryAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          load();
          handleClose();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  useEffect(() => {
    handleInside();
  }, []);
  const handleInside = () => {
    setModalShow(false);
    setCategoryIcon(null);
    setImagePreview(null);
    setImageSelected(false);
    setCategoryName("");
    setCategoryDesc("");
    setRemainingChars(200);
    setIsEnabled(false);
  };
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add More (Category)</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled}
                  onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`}
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="description">
                  <Form.Label>Category Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    onChange={handleDescChange}
                    value={categoryDesc}
                    maxLength="200"
                  />
                  <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters: 200)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingChars}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category Icon</Form.Label>
                  <Form.Control
                    type="file"
                    name="Icon"
                    onChange={handleCategoryIcon}
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                    1080px x 900px )
                  </Form.Text>
                </Form.Group>
                {categoryIcon !== null && (
                  <img
                    src={URL.createObjectURL(categoryIcon)}
                    alt="Preview"
                    style={{ width: "100px", height: "100px" }}
                    onClick={() => handleImageClick()}
                  />
                )}
              </div>
            </div>
            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={
              categoryIcon === null ? "" : URL.createObjectURL(categoryIcon)
            }
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddCategorymain({ onAdd }) {
  return (
    <>
      <OffCanvasAddCategorymain
        key={1}
        placement={"end"}
        name={"+ Add More"}
        load={onAdd}
      />
    </>
  );
}
