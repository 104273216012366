import Header from "./components/header";
import Sidebar from "./components/sidebar";
import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  HeaderFilter,
} from "devextreme-react/data-grid";
import DataRow from "./brandsData.js";
import { redirectDocument } from "react-router-dom";
import AddBanner from "./addBanner.js";
import EditBanner from "./Editbanner.js";
import { BannerList ,updateBannerstatus} from "../api/apiIntegration.js";
import { Title } from "chart.js";
import { toast, ToastContainer } from "react-toastify";
import { getUser } from "../auth/authService.js";
import LoaderOwn from "../common/loaderOwn.js";

export default function Banners() {
  const [showHeaderFilter] = useState(true);
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    list();
  }, []);
  const handleClose = () => {
    setSelectedBanner(null);
    setShowEditOffcanvas(false);
  };
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await BannerList();
      if (response.success == true) {
        setData(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleEdit = (Banner) => {
    setSelectedBanner(Banner);
    setShowEditOffcanvas(true);
  };

  const handleUpdate = (updatedBanner) => {
    setData((prevData) =>
      prevData.map((Banner) =>
        data.BannerId === updatedBanner.BannerId ? updatedBanner : Banner
      )
    );
  };

  const handleStatusToggle = async (Banner, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: Banner.BannerId,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateBannerstatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };
  const handleError = (event) => {
    event.target.src = "../images/no_image.png";
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Banners</h4>
                    <div className="ms-auto right_btn"></div>
                    <AddBanner onAdd={list} />
                  </div>
                  <div className="card-body pt-2 px-3">
                  {isLoading ? (
                      <LoaderOwn />
                    ) : (
                    <DataGrid
                      id="gridContainer"
                      dataSource={data}
                      keyExpr="BannerId"
                      columnAutoWidth={true}
                      showBorders={true}
                      width="100%"
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                      // dataRowRender={DataRow}
                    >
                      <HeaderFilter visible={showHeaderFilter} />
                      <Paging defaultPageSize={8} />
                      <Pager visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        placeholder="Search Stores..."
                      />
                      <Grouping autoExpandAll={false} />
                      <Column
                        dataField="Bannertitle "
                        width={200}
                          alignment="center"
                        cellRender={({ data }) => (
                          <div
                        
                            style={{ whiteSpace: "normal", maxWidth: "100%" }}
                          >
                            {data.Bannertitle}
                          </div>
                        )}
                      />
                      <Column
                        dataField="BannerDescription"
                        width={350}
                        cellRender={({ data }) => (
                          <div
                            style={{ whiteSpace: "normal", maxWidth: "100%" }}
                          >
                            {data.BannerDescription}
                          </div>
                        )}
                      />{" "}
                      <Column
                        dataField="BannerImage"
                        width={200}
                        cellRender={({ data }) =>
                          data.BannerImage ? (
                            <img
                              src={data.BannerImage}
                              alt="BannerImage"
                              className="BannerImage"
                              style={{ maxWidth: "100%", height: "auto" }}
                              onError={handleError}
                            />
                          ) : (
                            <img
                              src="../images/no_image.png"
                              alt="BannerImage"
                              style={{ maxWidth: "100%", height: "15%" }}
                            />
                          )
                        }
                      />{" "}
                      <Column
                        dataField="BannerexternalWebLink"
                        width={400}
                        cellRender={({ data }) => (
                          <div>
                            <a
                              href={data.BannerexternalWebLink}
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                maxWidth: "50%",
                              }}
                            >
                              {data.BannerexternalWebLink}
                            </a>
                          </div>
                        )}
                      />
                      <Column
                        alignment="center"
                        caption="Status"
                        width={150}
                        cellRender={({ data }) => (
                          <button
                            id="active_deactive"
                            className={`btn btn-${
                              data.BannerIsEnabled === true ? "success " : "danger "
                            }`}
                            onClick={() => handleStatusToggle(data)}
                          >
                            {data.BannerIsEnabled === true ? "Active" : "Deactive"}
                          </button>
                        )}
                      />
                      <Column
                        width={100}
                        cellRender={({ data }) => (
                          <Actions data={data} onEdit={handleEdit}
                          onToggleStatus={handleStatusToggle}

                          
                          />
                        )}
                      />
                    </DataGrid>
                       )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
      {selectedBanner && (
        <EditBanner
          onAdd={list}
          onClose={handleClose}
          onUpdate={handleUpdate}
          BannerData={selectedBanner}
          show={showEditOffcanvas}
          placement={"end"}
        />
      )}
    </>
  );
}
export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { BannerIsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {BannerIsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
