import React from 'react';

export default function ForgotPassword() {
    return (
    <>
        <div id="__layout">
        <div className="wrapper wrapper-full-page">
            <div className="full-page login-page">
                <div className="content">
                    <div className="container login-page">
                        <div className="col-lg-3 col-md-6 mx-auto">
                            <div className="card-login">
                                <form className="card-white">
                                    <div className="card-header">
                                        <img src="https://demos.creative-tim.com/nuxt-black-dashboard-pro/img//card-primary.png" alt="" />
                                        <h1 className="card-title">Forgot Password</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-3">
                                            <label className="form-label">Email</label>
                                            <input placeholder="Email" name="email" className="form-control" type="email" />
                                        </div>
                                        <div className="mb-2">
                                            <button type="submit" className="btn btn-primary w-100 btn-lg">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}