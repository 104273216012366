import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from 'react-bootstrap/Form';
function OffCanvasAddStorestmain({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
   
      <Button  id="btn"   size="sm"  onClick={handleShow}>{name}</Button>
      <Offcanvas id="offcanva"  show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add More (Stores)</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Form>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Location</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label>Contact</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
            </div>
          </div>
        </Form>
          <div className="text-center mt-4">
            <Button id="btn1" variant="primary">Add</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddStorestmain() {
  return (
    <>
      <OffCanvasAddStorestmain key={1} placement={"end"} name={"+ Add More"} />
    </>
  );
}


