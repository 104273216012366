const LoaderOwn = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "200px" }}
  >
    <button className="btn btn-black" type="button" disabled>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "1rem" }}
      ></span>
      <span className="sr-only">Loading...</span>
    </button>
  </div>
);
export default LoaderOwn;
