import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { categoryList, subCategoryList } from "../../api/apiIntegration";
export default function Filter({ onApply, status }) {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [productCategoryId, setProductCategoryId] = useState(null);
  const [productSubCategoryId, setProductSubCategoryId] = useState(null);
  const [productstatusId, setProductStatusId] = useState(null);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [statusProduct, setStatusstatusProduct] = useState(null);
  //Notifications User Filter Functions Start//
  const [formData, setFormData] = useState({
    filterType: 0,
    gender: "",
    fromBirthdate: null, 
    toBirthdate: null,
    nationality: "",
    fromAge: "",
    toAge: "",
  });
  
 const handleDateChange1 = (date, setter) => {
    setter(date);
  };
  const handleDateChange = (date, key) => {
    setFormData((prev) => ({ ...prev, [key]: date }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: id === "filterType" ? parseInt(value, 10) : value,
    }));
  };

  const handleApply = () => {
    const filterData = {
      filterType: parseInt(formData.filterType) || null,
      gender: formData.gender ? parseInt(formData.gender) : null,
      fromBirthdate:
      formData.filterType === 3 ? formatDate(formData.fromBirthdate) : null,
    toBirthdate:
      formData.filterType === 3 ? formatDate(formData.toBirthdate) : null,
      nationality: formData.nationality || null,
      fromAge: formData.fromAge ? parseInt(formData.fromAge) : null,
      toAge: formData.toAge ? parseInt(formData.toAge) : null,
    };
    onApply(filterData);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
//Notifications User Filter Functions End//

  const statusOption = [
    {
      name: "Ordered",
      id: 1,
    },
    {
      name: "Inprogress",
      id: 2,
    },
    {
      name: "Delivered",
      id: 2,
    },
  ];
  useEffect(() => {
    categoryListEvent();
  }, []);
  const handleUserApply = () => {
    const filterData = {
      startDate: startDate.toISOString(),
      endDate: startDate1.toISOString(),
      status: null,
    };

    onApply(filterData);
  };
  const handleWishlistApply = () => {
    const filterData = {
      startMonth: startDate.getMonth() + 1, // Months are 0-based in JavaScript
      startYear: startDate.getFullYear(),
      endMonth: startDate1.getMonth() + 1,
      endYear: startDate1.getFullYear(),
      status: null,
      status: null,
    };

    onApply(filterData);
  };
  const handlePushNotificationsApply = () => {
    const filterData = {
      startDate: startDate.toISOString(),
      endDate: startDate1.toISOString(),
      status: null,
    };

    onApply(filterData);
  };
  const handleProductApply = () => {
    const filterData = {
      categoryId: parseInt(productCategoryId, 10),
      subCategoryId:
        productSubCategoryId != null
          ? parseInt(productSubCategoryId, 10)
          : null,
      status: statusProduct,
    };

    onApply(filterData);
  };
  const handlePurchaseApply = () => {
    const filterData = {
      startDate: startDate.toISOString(),
      endDate: startDate1.toISOString(),
      status: null,
    };

    onApply(filterData);
  };
  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success == true) {
        setcategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const SubCategoryListEvent = async (id) => {
    try {
      const response = await subCategoryList(id);
      if (response.success == true) {
        setSubCategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const handleCategorySelectChange = (event) => {
    setProductCategoryId(event.target.value);
    SubCategoryListEvent(event.target.value);
  };
  const handleSubCategorySelectChange = (event) => {
    setProductSubCategoryId(event.target.value);
  };
  const handleStatusChange = (e) => {
    setStatusstatusProduct(e.target.value);
  };
  const handleStatusSelectChange = (event) => {
    setProductStatusId(event.target.value);
  };
  if (status == "notificationsfilterUser") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bi bi-filter"></i> Filter
          </Button>
          <ul  id="filtermenu" className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group className="mb-2" controlId="filterType">
                      <Form.Label>Filter Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.filterType}
                        onChange={handleChange}
                      >
                        <option value={0}>Select...</option>
                        <option value={1}>All</option>
                        <option value={2}>Gender</option>
                        <option value={3}>Date of Birth</option>
                        <option value={4}>Nationality</option>
                        <option value={5}>Age Bracket</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>

                {/* Conditionally render gender dropdown if "Gender" is selected */}
                {formData.filterType === 2 && (
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group className="mb-2" controlId="gender">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        <option value="0">Female</option>
                        <option value="1">Male</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              )}

                {/* Conditionally render date pickers if "Date of Birth" is selected */}
                {formData.filterType === 3 && (
                  <>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-2" controlId="fromBirthdate">
                          <Form.Label>From Date Of Birth</Form.Label>
                          <DatePicker
                            selected={formData.fromBirthdate}
                            onChange={(date) =>
                              handleDateChange(date, "fromBirthdate")
                            }
                            className="form-control"
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-2" controlId="toBirthdate">
                          <Form.Label>To Date Of Birth</Form.Label>
                          <DatePicker
                            selected={formData.toBirthdate}
                            onChange={(date) =>
                              handleDateChange(date, "toBirthdate")
                            }
                            className="form-control"
                            dateFormat="MM/dd/yyyy"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </>
                )}

                {/* Conditionally render nationality dropdown if "Nationality" is selected */}
                {formData.filterType === 4 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <Form.Group className="mb-2" controlId="nationality">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                          as="select"
                          value={formData.nationality}
                          onChange={handleChange}
                          id="nationality"
                        >
                          <option value="">Select...</option>
                          {/* Add nationality options here */}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                )}

                {/* Conditionally render age inputs if "Age Bracket" is selected */}
                {formData.filterType === 5 && (
                  <>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-2" controlId="fromAge">
                          <Form.Label>From Age</Form.Label>
                          <Form.Control
                            type="number"
                            value={formData.fromAge}
                            onChange={handleChange}
                            id="fromAge"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-2" controlId="toAge">
                          <Form.Label>To Age</Form.Label>
                          <Form.Control
                            type="number"
                            value={formData.toAge}
                            onChange={handleChange}
                            id="toAge"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </>
                )}
                <div className="text-center mt-1">
                  <Button id="btn1" variant="primary" onClick={handleApply}>
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  } else if (status == "user") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-filter"></i> Filter
          </Button>
          <ul className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate1}
                      onChange={(date) => setStartDate1(date)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control type="text" placeholder="Status" />
                    </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-1">
                  <Button id="btn1" variant="primary" onClick={handleUserApply}>
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  }
  else if (status == "product") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-filter"></i> Filter
          </Button>
          <ul className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <Form.Control
                      as="select"
                      value={productCategoryId}
                      onChange={handleCategorySelectChange}
                      required
                    >
                      <option value="">Select category</option>
                      {categoryOptions.map((option, index) => (
                        <option key={index} value={option.ProductCategoryId}>
                          {option.ProductCategoryName}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
                {productCategoryId != null && subCategoryOptions.length > 0 ? (
                  <div className="row">
                    <div className="col-lg-12 react-pick mt-2">
                      <Form.Control
                        as="select"
                        value={productSubCategoryId}
                        onChange={handleSubCategorySelectChange}
                        required
                      >
                        <option value="">Select subcategory</option>
                        {subCategoryOptions.map((option, index) => (
                          <option
                            key={index}
                            value={option.ProductSubCategoryId}
                          >
                            {option.ProductSubCategoryName}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="row">
                  <div className="col-lg-12 mt-2">
                  <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    value={statusProduct}
                    onChange={handleStatusChange}
                    required
                  >
                    <option value="">Select status</option>
                    <option value="true">Active</option>
                    <option value="false">Deactive</option>
                  </Form.Control>
                </Form.Group>
                  </div>
                </div>
                <div className="text-center mt-1">
                  <Button
                    id="btn1"
                    variant="primary"
                    onClick={handleProductApply}
                  >
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  }  else if (status == "purchaseHistory") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-filter"></i> Filter
          </Button>
          <ul className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate1}
                      onChange={(date) => setStartDate1(date)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-11 react-pick">
                    <Form.Control
                      as="select"
                      value={productstatusId}
                      onChange={handleStatusSelectChange}
                      required
                    >
                      <option value="">Select status</option>
                      {statusOption.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
                <div className="text-center mt-1">
                  <Button
                    id="btn1"
                    variant="primary"
                    onClick={handlePurchaseApply}
                  >
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  } else if (status == "wishlist") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-filter"></i> Filter
          </Button>
          <ul className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                    
                      selected={startDate}
                      onChange={(date) => handleDateChange1(date, setStartDate)}
                      dateFormat="MM-yyyy"
                      showMonthYearPicker
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="MM-yyyy"
                      selected={startDate1}
                      onChange={(date) => handleDateChange1(date, setStartDate1)}
                      showMonthYearPicker
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control type="text" placeholder="Status" />
                    </Form.Group>
                  </div>
                </div> */}
                <div className="text-center mt-1">
                  <Button
                    id="btn1"
                    variant="primary"
                    onClick={handleWishlistApply}
                  >
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  } else if (status == "Notifications") {
    return (
      <>
        <div
          className="dropdown filter_down"
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            id="btn"
            className="filter dropdown-toggle"
            size="sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-filter"></i> Filter
          </Button>
          <ul className="dropdown-menu">
            <li>
              <h3>Filter Options</h3>
              <Form className="pad15">
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 react-pick">
                    <DatePicker
                      className="form-control mb-3"
                      dateFormat="dd-MM-yyyy"
                      selected={startDate1}
                      onChange={(date) => setStartDate1(date)}
                    />
                  </div>
                </div>

                <div className="text-center mt-1">
                  <Button
                    id="btn1"
                    variant="primary"
                    onClick={handlePushNotificationsApply}
                  >
                    Apply
                  </Button>
                </div>
              </Form>
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
}
