import React, { useState } from "react";

const TabOwn = ({ onStatusChange, buttonName, active, onActiveChange }) => {
  const handleClick = (index) => {
    if (active !== index) {
      onActiveChange(index); 
      onStatusChange(index === 0 ? 1 : 2);
    }
  };

  return (
    <div>
      <div className="tab-button-own">
        {buttonName.map((name, index) => (
          <button
            key={index}
            style={{
              borderRadius:
                index === 0
                  ? "5px 0 0 5px"
                  : index === buttonName.length - 1
                  ? "0 5px 5px 0"
                  : "0",
            }}
            onClick={() => handleClick(index)}
            className={active === index ? "active" : ""}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabOwn;
