import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { getUser } from "../auth/authService.js";
import { AddNotification } from "../api/apiIntegration";

function OffCanvasAddNotificationmain({ name, load, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    header: "",
    description: "",
    deliveryType: 1,
    filterType:1,
    notificationType:1,
    isAlertOn: true,
    createdBy: "",
  });
  const [notificationTypes, setNotificationTypes] = useState([]);

  const handleToggleSwitch = (type) => {
    setNotificationTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleChange = (e) => {
    const { id, value, type: inputType, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: inputType === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNotificationType =
      notificationTypes.length === 1
        ? notificationTypes.includes("email")
        : false;
    console.log(isNotificationType);
    console.log(notificationTypes);
    setLoading(true);

    if (formData.header === "" && !isNotificationType) {
      toast.error("Header is required");
      setLoading(false);
      return;
    } 

    if (formData.description === "" && !isNotificationType) {
      toast.error("Description is required");
      setLoading(false);
      return;
    }

    if (notificationTypes.length === 0) {
      toast.error("At least one notification type must be selected");
      setLoading(false);
      return;
    }

    const userGet = await getUser();

    const form = {
      header: formData.header,
      description: formData.description,
      deliveryType: formData.deliveryType,
      filterType: formData.filterType,
      createdBy: userGet.AdminId,
      notificationType: formData.notificationType,
      isAlertOn: formData.isAlertOn,
    };

    const notificationsToSend = notificationTypes.map((type) => {
      const commonData = { ...form, notificationTypes: [type] };

      switch (type) {
        case "email":
          return {
            ...commonData,
            to: "pkmani2001@gmail.com",
           
          };
        case "sms":
          return { ...commonData, message: formData.description };
        case "whatsapp":
          return {
            ...commonData,
            header: formData.header,
            description: formData.description,
          };
        default:
          return commonData;
      }
    });

    try {
      const responses = await Promise.all(
        notificationsToSend.map((form) => AddNotification(form))
      );
      const allSuccess = responses.every((response) => response.success);

      if (allSuccess) {
        toast.success("Notifications sent successfully");
        handleClose();
        load();
      } else {
        toast.error("Failed to send some notifications");
      }
    } catch (error) {
      toast.error("Failed to : An unknown error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="row mt-1">
            <Offcanvas.Title className="row mb-5">
              SEND NOTIFICATIONS
            </Offcanvas.Title>
            {["push", "email", "sms", "whatsapp"].map((type) => (
              <div className="col-lg-3" key={type}>
                <Form.Group
                  className="d-flex align-items-center"
                  controlId={type}
                  style={{ marginLeft: "0.5rem" }}
                >
                  <Form.Check
                    className="me-2"
                    type="checkbox"
                    variant="info"
                    checked={notificationTypes.includes(type)}
                    onChange={() => handleToggleSwitch(type)}
                  />
                  <Form.Label style={{ margin: 0 }}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </Form.Label>
                </Form.Group>
              </div>
            ))}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="header">
                  <Form.Label>Header</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={formData.header}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Sending..." : "SEND"}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function SendNotificationmain({ onAdd }) {
  return (
    <>
      <OffCanvasAddNotificationmain
        key={1}
        placement={"end"}
        name={"SEND NOTIFICATION"}
        load={onAdd}
      />
    </>
  );
}
