import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { toast, ToastContainer } from "react-toastify";

import DataGrid, {
  Column,
  SearchPanel,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  HeaderFilter,
} from "devextreme-react/data-grid";
import DataRow from "./StoresDataRow.js";
import CreateTemplate from "./addEmailTemplate.js";
import { EmailTemplateList } from "../api/apiIntegration.js";
import LoaderOwn from "../common/loaderOwn.js";
import EditEmailTemplate from "./EditemailTemplate.js";
export default function EmailTemplate() {
  const [data, setData] = useState([]);
  const [showHeaderFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);

  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await EmailTemplateList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (EmailTemplate) => {
    setSelectedTemplate(EmailTemplate);
    setShowEditOffcanvas(true);
  };
  const handleClose = () => {
    setSelectedTemplate(null);
    setShowEditOffcanvas(false);
  };
  const handleUpdate = (updatedTemplate) => {
    setData((prevData) =>
      prevData.map((template) =>
        template.EmailTemplateID === updatedTemplate.EmailTemplateID ? updatedTemplate : template
      )
    );
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Email Templates</h4>
                    <div className="ms-auto right_btn">
                      {/* <CreateTemplate /> */}
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="EmailTemplateID"
                        columnAutoWidth={true}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        // dataRowRender={DataRow}
                      >
                        <HeaderFilter visible={showHeaderFilter} />
                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Template..."
                        />

                        <Grouping autoExpandAll={false} />
                        <Column dataField="TemplateName" 
                        width={250}
                        cellRender={({ data }) => (
                          <div
                            style={{ whiteSpace: "normal",}}
                          >
                            {data.TemplateName}
                          </div>
                        )}/>
                        {/* <Column dataField="Template name" /> */}
                        <Column dataField="Subject"     width={250} />
                        {/* <Column dataField="EmailContent"     width={250} /> */}
                        <Column
                       
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                          
                              onEdit={handleEdit}
                             
                            />
                          )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </main>
      {selectedTemplate && (
        <EditEmailTemplate
          onAdd={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          templateData={selectedTemplate}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
    </>
  );
}
export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
