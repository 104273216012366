import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Editor from "./components/Editor"
function OffCanvasCreateTemplate({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [content, setContent] = useState("");
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Templates</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlSelect1"
                >
                  <Form.Label>Template for</Form.Label>
                  <Form.Control as="select">
                    <option value="">Select an option</option>
                    <option value="Option1">Option 1</option>
                    <option value="Option2">Option 2</option>
                    <option value="Option3">Option 3</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4">
              <Form.Group className="mb-2" controlId="header">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                  
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4">
              <Form.Group className="mb-2" controlId="header">
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                   
                  />
                </Form.Group>
              </div>
            </div>
             <div className="row">
              <div className="col-lg-12 mb-4">
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                  <Form.Label>Content</Form.Label>
                  <Editor value={content} onChange={setContent} />
                </Form.Group>
              </div>
            </div>
          </Form>
          <div className="text-center mt-4">
            <Button id="btn1" variant="primary">
              Create
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function CreateTemplate() {
  return (
    <>
      <OffCanvasCreateTemplate
        key={1}
        placement={"end"}
        name={"Create Template"}
      />
    </>
  );
}
