import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ImageModal({ show, onHide, imageUrl }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
      className="custom-modal"
    >
      
      <Modal.Body className="custom-modal-body">
        <img
          src={imageUrl}
          alt="Preview"
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button variant="secondary" onClick={onHide} className="custom-close-button">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageModal;