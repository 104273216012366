import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { brandAdd } from "../api/apiIntegration.js";
import { getUser } from "../auth/authService.js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./components/image_model.js";
function OffCanvasAddBrands({ load, name, ...props }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [brandDesc, setBrandDesc] = useState("");
  const [brandurl, setBrandUrl] = useState("");
  const [fileBanner, setFileBanner] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [fileIcon, setFileIcon] = useState(null);
  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);
  const [remainingChars, setRemainingChars] = useState(250);
  const [imageSelected, setImageSelected] = useState(false);
  const [imagePreviewBanner, setImagePreviewBanner] = useState(null);
  const [imagePreviewIcon, setImagePreviewIcon] = useState(null);
  const [IsEnabled, setIsEnabled] = useState(false);
  const validateImage = (
    file,
    requiredWidth,
    requiredHeight,
    errorMessage,
    setImagePreview
  ) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== requiredWidth || img.height !== requiredHeight) {
          toast.error(errorMessage);
          setImagePreview(null);
        } else {
          setImagePreview(e.target.result);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size exceeds 150KB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        toast.error("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      }
      validateImage(
        selectedFile,
        180,
        90,
        "Icon dimensions must be 180 x 90.",
        setImagePreviewIcon
      );
      setFileIcon(selectedFile);
    }
  };
  const handleFileBanner = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 500 * 1024) {
        toast.error("File size exceeds 500KB.");
        return;
      }
      if (!["image/jpeg", "image/png"].includes(selectedFile.type)) {
        toast.error("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      }
      validateImage(
        selectedFile,
        1242,
        865,
        "Banner dimensions must be 1242 x 865.",
        setImagePreviewBanner
      );
      setFileBanner(selectedFile);
    }
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleImage1Click = () => {
    setModalShow1(true);
  };
  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setBrandDesc(countDesc);
    setRemainingChars(250 - countDesc.length);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!brandName) {
      toast.error("Brand name is required.");
      return;
    }
    if (!brandDesc) {
      toast.error("Brand description is required.");
      return;
    }
    if (!fileBanner) {
      toast.error("Banner is required.");
      return;
    }
    if (!fileIcon) {
      toast.error("Icon is required.");
      return;
    }
    if (!brandurl) {
      toast.error("Brand URL is required.");
      return;
    }
    {
      const userGet = await getUser();

      const form = new FormData();
      form.append("brandName", brandName);
      form.append("brandDesc", brandDesc);
      form.append("brandURL", brandurl);
      form.append("brandOrder", 1);
      form.append("createdBy", userGet.AdminId);
      form.append("brandBanner", fileBanner);
      form.append("brandIcon", fileIcon);
      form.append("IsEnabled", IsEnabled);
      try {
        setLoading(true);
        const response = await brandAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          load();
          handleClose();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };

  useEffect(() => {
    handleInside();
  }, []);
  const handleInside = () => {
    setModalShow(false);
    setModalShow1(false);
    setBrandName("");
    setBrandUrl("");
    setBrandDesc("");
    setFileBanner(null);
    setFileIcon(null);
    setImagePreviewBanner(null);
    setImagePreviewIcon(null);
    setImagePreviewBanner(false);
    setImageSelected(false);
    setIsEnabled(false);
  };
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Brands</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled}
                  onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`}
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="description"
                    value={brandDesc}
                    onChange={handleDescChange}
                    maxLength="250"
                  />
                  <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters: 250)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingChars}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="banner">
                  <Form.Label>Banner</Form.Label>
                  <Form.Control
                    type="file"
                    name="banner"
                    onChange={handleFileBanner}
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/PNG / Max. Size 500KB, Dimension
                    1242px x 865px)
                  </Form.Text>
                </Form.Group>
                {imagePreviewBanner && (
                  <div className="mt-3">
                    <img
                      src={imagePreviewBanner}
                      alt="Banner Preview"
                      style={{ maxWidth: "100%", height: "100px" }}
                      onClick={() => handleImageClick()}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="">
                  <Form.Label>Icon</Form.Label>
                  <Form.Control
                    type="file"
                    name="icon"
                    onChange={handleFileIcon}
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/PNG / Max. Size 150KB, Dimension
                    180px x 90px)
                  </Form.Text>
                </Form.Group>
                {imagePreviewIcon && (
                  <div className="mt-3">
                    <img
                      src={imagePreviewIcon}
                      alt="Icon Preview"
                      style={{ maxWidth: "100%", height: "100%" }}
                      onClick={() => handleImage1Click()}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="brandURL">
                  <Form.Label>Brand URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="brandURL"
                    onChange={(e) => setBrandUrl(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={imagePreviewBanner === null ? "" : imagePreviewBanner}
          />
          <ImageModal
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            imageUrl={imagePreviewIcon === null ? "" : imagePreviewIcon}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddBrands({ onAdd }) {
  return (
    <>
      <OffCanvasAddBrands
        key={1}
        placement={"end"}
        name={"+ Add More"}
        load={onAdd}
      />
    </>
  );
}
