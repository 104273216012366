import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Filter from "./components/filter";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import AddWhatsAppNotificationmain from "./addWhatsappnotifications.js";
import { WhatsAppNotificationsList ,WhatsappNotificationstFillter} from "../api/apiIntegration.js";
import { format } from "date-fns";
import LoaderOwn from "../common/loaderOwn.js";
import { toast, ToastContainer } from "react-toastify";
export default function WhatsAppNotifications() {
  const [data, setData] = useState([]);
  const [showHeaderFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    try {
      setIsLoading(true);
      const response = await WhatsAppNotificationsList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilterApply = async (filterData) => {
    console.log("Filter Data:", filterData);
    try {
      setIsLoading(true);
      const response = await WhatsappNotificationstFillter(filterData);
      if (response.success == true) {
        setData(response.data);
      } else {
        toast.error("Failed to apply filters");
      }
    } catch (error) {
      toast.error("An error occurred while filtering products");
    } finally {
      setIsLoading(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy ");
  };
  const getStatusLabel = (DeliveryType) => {
    switch (DeliveryType) {
      case 1:
        return "Immediate";
      case 2:
        return "Delayed";
      default:
        return "";
    }
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">
                      WhatsApp Notifications List
                    </h4>
                    <div className="ms-auto right_btn d-flex justify-content-end">
                    {!isLoading && (
                        <Filter
                          onAdd={list}
                          onApply={handleFilterApply}
                          status={"Notifications"}
                        />
                      )}
                      <AddWhatsAppNotificationmain onAdd={list} />
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="NotificationID"
                        columnAutoWidth={true}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        // dataRowRender={DataRow}
                      >
                        <HeaderFilter visible={showHeaderFilter} />
                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Products..."
                        />

                        <Grouping autoExpandAll={false} />
                        <Column dataField="Header" width={350} />
                        <Column
                          dataField="Description"
                          width={350}
                          cellRender={({ data }) => (
                            <div
                              style={{ whiteSpace: "normal", maxWidth: "100%" }}
                            >
                              {data.Description}
                            </div>
                          )}
                        />
                        <Column
                          dataField="DeliveryType"
                          alignment="center"
                          width={200}
                          calculateCellValue={({ DeliveryType }) =>
                            getStatusLabel(DeliveryType)
                          }
                        />
                        <Column
                          alignment="center"
                          dataField="FilterType"
                          width={200}
                          caption="AudienceType"
                        />

                        <Column
                          alignment="center"
                          dataField="CreatedDate"
                          calculateCellValue={({ CreatedDate }) =>
                            formatDate(CreatedDate)
                          }
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}
