import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import FormRange from "react-bootstrap/FormRange";
import { Dropdown } from "react-bootstrap";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs';
import * as excelJs from 'exceljs';
import InputGroup from "react-bootstrap/InputGroup";
import {
  brandList,
  productAdd,
  subCategoryList,
  categoryList,
  productTopItemCount,
  brandAdd,
  volumeunitList,
  volumeunitAdd,
} from "../api/apiIntegration";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { getUser } from "../auth/authService.js";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./components/image_model.js";
function OffCanvasAddUsermain({ load, name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [productVolume, setProductVolume] = useState("");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productTags, setProductTags] = useState("");
  const [productAlcoholPercentage, setProductAlcoholPercentage] = useState("");
  const [productRegion, setProductRegion] = useState("");
  const [productSKUCode, setProductSKUCode] = useState("");
  const [productSAPCode, setProductSAPCode] = useState("");
  const [productCategoryId, setProductCategoryId] = useState(null);
  const [productSubCategoryId, setProductSubCategoryId] = useState(null);
  const [productBrandId, setProductBrandId] = useState(null);
  const [isTopItem, setIsTopItem] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [isLuxuryCollection, setIsLuxuryCollection] = useState(false);
  const [exceptional, setExceptional] = useState(false);
  const [hyperLink, setHyperLink] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [tastingNotes, setTastingNotes] = useState("");
  const [fileImage, setFileImage] = useState(null);
  const [fileIcon, setFileIcon] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [remainingChars, setRemainingChars] = useState(2000);
  const [remainingCharsProductName, setRemainingCharsProductName] =
    useState(100);
  const [IsEnabled, setIsEnabled] = useState(false);
  const [productDiscount, setProductDiscount] = useState("");
  const [productDiscountType, setProductDiscountType] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [volumeUnitOptions, setVolumeUnitOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [productUnit, setProductUnit] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    handleInside();
  }, []);

  const handleInside = () => {
    setModalShow(false);
    setModalShow1(false);
    setProductVolume("");
    setProductDesc("");
    setProductPrice("");
    setProductTags("");
    setProductAlcoholPercentage("");
    setProductRegion("");
    setProductSKUCode("");
    setProductSAPCode("");
    setProductCategoryId(null);
    setProductSubCategoryId(null);
    setProductBrandId(null);
    setIsTopItem(false);
    setVideoLink("");
    setIsLuxuryCollection(false);
    setHyperLink("");
    setImageLink("");
    setTastingNotes("");
    setFileImage(null);
    setFileIcon(null);
    setRemainingChars(2000);
    setRemainingCharsProductName(100);
    setIsEnabled(false);
    setProductUnit("");
  };
  useEffect(() => {
    brandListEvent();
    categoryListEvent();
    volumeUnitListEvent();
  }, []);

  const brandListEvent = async () => {
    try {
      const response = await brandList();
      if (response.success == true) {
        setBrandOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const volumeUnitListEvent = async () => {
    try {
      const response = await volumeunitList();
      if (response.success == true) {
        setVolumeUnitOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success == true) {
        setcategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const SubCategoryListEvent = async (id) => {
    try {
      const response = await subCategoryList(id);
      if (response.success == true) {
        setSubCategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const handleIstopChange = async () => {
    try {
      const response = await productTopItemCount();
      if (response.success && response.enabled) {
        setIsTopItem(!isTopItem);
      } else {
        if (response.enabled) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleisLuxuryCollectionChange = () => {
    setIsLuxuryCollection(!isLuxuryCollection);
  };
  const handleExceptionalChange = () => {
    setExceptional(!exceptional);
  };
  const handleProductVolume = (e) => {
    const value = e.target.value.replace(/\s+/g, ""); // Remove all spaces
    setProductVolume(value);
  };
  const handleVolumeUnitChange = (event) => {
    setProductUnit(event.target.value);
  };
  const handleCategorySelectChange = (event) => {
    setProductCategoryId(event.target.value);
    SubCategoryListEvent(event.target.value);
  };
  const handleSubCategorySelectChange = (event) => {
    setProductSubCategoryId(event.target.value);
  };
  const handleBrandSelectChange = (event) => {
    setProductBrandId(event.target.value);
  };

  const handleFileImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension !== "jpg" && fileExtension !== "jpeg") {
        toast.error("Only JPG and JPRG images are allowed");
        setFileImage(null);
        setImagePreview(null);
        setImageSelected(false);
        return;
      }
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setFileImage(null);
        setImageSelected(false);
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileImage(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile));
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setFileImage(null);
          setImagePreview(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };
  // const handleFileImage = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFileImage(selectedFile);
  // };
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension !== "jpg" && fileExtension !== "jpeg") {
        toast.error("Only JPG images are allowed");
        setFileIcon(null);
        setImagePreview(null);
        setImageSelected(false);
        return;
      }
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setFileIcon(null);
        setImageSelected(false);
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileIcon(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile));
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900pixels");
          setFileIcon(null);
          setImagePreview(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };
  const handleDiscountValueChange = (event) => {
    const value = event.target.value;

    setProductDiscount(value);
  };
  const handleDiscountTypeValueChange = (event) => {
    const value = event.target.value;
    setProductDiscountType(value);

    if (
      value === "percentage" &&
      productDiscount &&
      !productDiscount.endsWith("%")
    ) {
      setProductDiscount(productDiscount + "%");
    } else if (value === "netAmount" && productDiscount.endsWith("%")) {
      setProductDiscount(productDiscount.replace("%", ""));
    }
  };
  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setProductDesc(countDesc);
    setRemainingChars(2000 - countDesc.length);
  };
  const handleNameChange = (e) => {
    const countDesc = e.target.value;
    setProductName(countDesc);
    setRemainingCharsProductName(100 - countDesc.length);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (productName == "") {
      toast.error("Product name is required");
    } else if (productDesc == "") {
      toast.error("Product description is required");
    } else if (productPrice == "") {
      toast.error("Product price is required");
    } else if (productTags == "") {
      toast.error("Product tags is required");
    } else if (productUnit == "") {
      toast.error("productUnit tags is required");
    } else if (productAlcoholPercentage == "") {
      toast.error("Product Alcohol Percentage is required");
    } else if (productRegion == "") {
      toast.error("Product Country is required");
    } else if (productSKUCode == "") {
      toast.error("Product SKUCode is required");
    } else if (productSAPCode == "") {
      toast.error("Product SAPCode is required");
    } else if (productCategoryId == null) {
      toast.error("Product Category is required");
    } else if (productSubCategoryId == null) {
      toast.error("Product SubCategory is required");
    } else if (productBrandId == null) {
      toast.error("Product SubCategory is required");
    } else if (isLuxuryCollection == "") {
      toast.error("Luxury Collection is required");
    } else if (hyperLink == "") {
      toast.error("Hyper Link is required");
    } else if (imageLink == "") {
      toast.error("Banner is required");
    } else if (tastingNotes == "") {
      toast.error("Tasting Notes is required");
    } else if (fileImage == null) {
      toast.error("Image is required");
    } else if (fileIcon == null) {
      toast.error("Icon is required");
    } else {
      const form = new FormData();
      form.append("productName", productName);
      form.append("productDesc", productDesc);
      form.append("productPrice", productPrice);
      form.append("productTags", productTags);
      form.append("productVolume", productVolume);
      form.append("productUnit", productUnit);
      form.append("productAlcoholPercentage", productAlcoholPercentage);
      form.append("productRegion", productRegion);
      form.append("productSKUCode", productSKUCode);
      form.append("productSAPCode", productSAPCode);
      form.append("isTopItem", isTopItem);
      form.append("productCategoryId", productCategoryId);
      form.append("productSubCategoryId", productSubCategoryId);
      form.append("orderSequence", 1);
      form.append("isLuxuryCollection", isLuxuryCollection);
      form.append("brandID", productBrandId);
      form.append("hyperLink", hyperLink);
      form.append("imageLink", imageLink);
      form.append("tastingNotes", tastingNotes);
      form.append("videoLink", videoLink);
      form.append("productDiscountType", productDiscountType);
      form.append("productDiscount", productDiscount);
      form.append("exceptional", exceptional);

      form.append("productImage", fileImage);
      form.append("productIcon", fileIcon);
      form.append("IsEnabled", IsEnabled);
      try {
        setLoading(true);
        const response = await productAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          load();
          handleClose();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked); // Update state when the switch is toggled
  };
  const handleIconClick = () => {
    setModalShow(true);
  };
  const handleImageClick = () => {
    setModalShow1(true);
  };
  useEffect(() => {
    handleInside2();
  }, []);
  const handleInside2 = () => {
    setBrandName("");
    setProductVolumeUnit("");
  };

  const [brandLoading, setBrandLoading] = useState(false);
  const [volumeUnitLoading, setVolumeUnitLoading] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [productVolumeUnit, setProductVolumeUnit] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const handleShow1 = () => setShowModal(true);
  const handleClose1 = () => {
    setShowModal(false);
    handleInside2();
  };
  const handleShow2 = () => setShowModal1(true);
  const handleClose2 = () => {
    setShowModal1(false);
    handleInside2();
  };

  const handleSubmitAddBrand = async (e) => {
    e.preventDefault();
    if (!brandName) {
      toast.error("Brand name is required.");
      return;
    }

    {
      const userGet = await getUser();

      const form = new FormData();
      form.append("brandName", brandName);
      form.append("brandOrder", 1);
      form.append("createdBy", userGet.AdminId);

      try {
        setBrandLoading(true);
        const response = await brandAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          await brandListEvent();
          handleClose1();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setBrandLoading(false);
      }
    }
  };
  const handleSubmitAddVolume = async (e) => {
    e.preventDefault();

    if (!productVolumeUnit) {
      toast.error("Product volume unit is required.");
      return;
    }

    const userGet = await getUser();

    const form = {
      productVolumeUnit: productVolumeUnit, // Ensure key matches what your API expects
    };

    try {
      setVolumeUnitLoading(true);
      const response = await volumeunitAdd(form);
      if (response.success) {
        toast.success(response.message);

        await volumeUnitListEvent();
        handleClose2(); // Function to close the modal
      } else {
        toast.error(response.message || "Failed to add volume unit.");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred.");
    } finally {
      setVolumeUnitLoading(false);
    }
  };
  const generateTemplate = async () => {
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet('Sample Worksheet');
    const hiddenSheet = workbook.addWorksheet('Options');

    // Add headers to the worksheet in the specified order
    ws.addRow([
        "productName",
        "productDesc",
        "productPrice",
        "productTags",
        "productVolume",
        "productUnit", // Dropdown options
        "productAlcoholPercentage",
        "productRegion",
        "productSKUCode",
        "productSAPCode",
        "productCategoryId", // Dropdown options
        "productSubCategoryId", // Dropdown options
        "brandID", // Dropdown options
        "isTopItem",
        "orderSequence",
        "isLuxuryCollection",
        "hyperLink",
        "imageLink",
        "tastingNotes",
        "videoLink",
        "IsEnabled"
    ]);

    // Set column widths for better visibility
    ws.columns.forEach((col) => {
        col.width = 20;
    });

    // Populate hidden sheet with dropdown options, starting from row 2
    hiddenSheet.getColumn(1).values = ['productUnit', ...volumeUnitOptions.map(option => option.ProductUnit)];
    hiddenSheet.getColumn(2).values = ['productCategoryId', ...categoryOptions.map(option => option.ProductCategoryName)];
    hiddenSheet.getColumn(3).values = ['productSubCategoryId', ...subCategoryOptions.map(option => option.ProductSubCategoryName)];
    hiddenSheet.getColumn(4).values = ['brandID', ...brandOptions.map(option => option.BrandName)];

    // Hide the options sheet
    hiddenSheet.state = 'hidden';

    // Apply data validations (dropdowns) to specific columns using explicit cell ranges
    ws.dataValidations.add('F2:F99999', {
        type: 'list',
        allowBlank: false,
        formulae: ['Options!$A$2:$A$' + (volumeUnitOptions.length + 1)],
    });

    ws.dataValidations.add('K2:K99999', {
        type: 'list',
        allowBlank: false,
        formulae: ['Options!$B$2:$B$' + (categoryOptions.length + 1)],
    });

    ws.dataValidations.add('L2:L99999', {
        type: 'list',
        allowBlank: false,
        formulae: ['Options!$C$2:$C$' + (subCategoryOptions.length + 1)],
    });

    ws.dataValidations.add('M2:M99999', {
        type: 'list',
        allowBlank: false,
        formulae: ['Options!$D$2:$D$' + (brandOptions.length + 1)],
    });

    // Style the header row
    ws.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFADD8E6' }, // Light blue fill color
    };

    ws.getRow(1).font = {
        name: 'Inter',
        size: 10,
        bold: true,
    };

    ws.eachRow((row) => {
        row.eachCell((cell) => {
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
            };
        });
    });

    // Generate the Excel file and trigger download
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    );

    const link = document.createElement('a');
    link.href = excelUrl;
    link.download = 'sample_product_upload.xlsx';
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
};







  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>

      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add New Products</Offcanvas.Title>
          {/* <Button onClick={generateTemplate}>
            Download Sample Excel File
          </Button> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled} // Bind checkbox to IsEnabled state
                  onChange={handleSwitchChange} // Toggle state on change
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`} // Use Bootstrap text color classes
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    maxLength="100"
                    onChange={handleNameChange}
                  />
                  <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters:100)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingCharsProductName}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={productCategoryId}
                    onChange={handleCategorySelectChange}
                  >
                    <option value="">Select...</option>
                    {categoryOptions.map((option, index) => (
                      <option key={index} value={option.ProductCategoryId}>
                        {option.ProductCategoryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sub Category</Form.Label>
                  <Form.Control
                    style={{
                      position: "relative",
                      top: "13px",
                    }}
                    as="select"
                    value={productSubCategoryId}
                    onChange={handleSubCategorySelectChange}
                  >
                    <option value="">Select...</option>
                    {subCategoryOptions.map((option, index) => (
                      <option key={index} value={option.ProductSubCategoryId}>
                        {option.ProductSubCategoryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Brand
                    <Button
                      variant="link"
                      onClick={handleShow1}
                      style={{
                        textDecoration: "none",
                        color: "green",
                        fontSize: "0.8rem",
                      }}
                    >
                      ( + Add More )
                    </Button>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={productBrandId}
                    onChange={handleBrandSelectChange}
                  >
                    <option value="">Select...</option>
                    {brandOptions.map((option, index) => (
                      <option key={index} value={option.BrandId}>
                        {option.BrandName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Volume
                    <Button
                      variant="link"
                      onClick={handleShow2}
                      style={{
                        textDecoration: "none",
                        color: "green",
                        fontSize: "0.8rem",
                      }}
                    >
                      ( + Add More )
                    </Button>
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <div className="input-group">
                      <Form.Control
                        type="text"
                        value={productVolume}   
                        onChange={handleProductVolume}
                        placeholder="Enter Volume"
                      />
                      <Form.Control
                        as="select"
                        value={productUnit}
                        onChange={handleVolumeUnitChange}
                      >
                        <option value="">Select...</option>
                        {volumeUnitOptions.map((option, index) => (
                          <option key={index} value={option.ProductUnit}>
                            {option.ProductUnit}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label
                    style={{
                      position: "relative",
                      top: "10px",
                    }}
                  >
                    Price
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      style={{
                        position: "relative",
                        top: "13px",
                      }}
                      type="text"
                      placeholder=""
                      onChange={(e) => setProductPrice(e.target.value)}
                    />
                    <InputGroup.Text
                      style={{
                        position: "relative",
                        top: "0.8rem",
                      }}
                    >
                      AED
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength="2000"
                    value={productDesc}
                    onChange={handleDescChange}
                  />
                  <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters: 2000)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingChars}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Images</Form.Label>
                  <Form.Control type="file" onChange={handleFileImage} />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                    1080px x 900px )
                  </Form.Text>
                </Form.Group>
                {fileImage !== null && (
                  <img
                    src={URL.createObjectURL(fileImage)}
                    alt="Preview"
                    style={{ width: "100px", height: "100px" }}
                    onClick={() => handleImageClick()}
                  />
                )}
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Icons</Form.Label>
                  <Form.Control type="file" onChange={handleFileIcon} />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                    1080px x 900px )
                  </Form.Text>
                </Form.Group>
                {fileIcon !== null && (
                  <img
                    src={URL.createObjectURL(fileIcon)}
                    alt="Preview"
                    style={{ width: "100px", height: "100px" }}
                    onClick={() => handleIconClick()}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SKU Code</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setProductSKUCode(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SAP Code</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setProductSAPCode(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setProductTags(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="row col-lg-4">
                <div className="col-lg-7">
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Top Item</Form.Label>
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isTopItem}
                      onChange={handleIstopChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="row col-lg-4 ">
                <div className="col-lg-7">
                  <Form.Group
                    className="mb-2 "
                    controlId="exampleForm.ControlInput1"
                    // style={{ marginLeft: "1.5rem" }}
                  >
                    <Form.Label>Is Luxury</Form.Label>
                  </Form.Group>
                </div>
                <div className="col-lg-2">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isLuxuryCollection}
                      onChange={handleisLuxuryCollectionChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row col-lg-4 ">
                <div className="col-lg-7 ">
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Exceptional </Form.Label>
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={exceptional}
                      onChange={handleExceptionalChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-6">
                <Form.Group className="mb-2" controlId="discountValue">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Discount Value"
                    value={productDiscount}
                    onChange={handleDiscountValueChange}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-2" controlId="discountType">
                  <Form.Label>Discount Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={productDiscountType}
                    aria-label="Select Discount Type"
                    onChange={handleDiscountTypeValueChange}
                  >
                    <option value="">Select Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="netAmount">flat amount</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Alcohol Percentage</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        setProductAlcoholPercentage(e.target.value)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setProductRegion(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product link</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setHyperLink(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Video Link</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setVideoLink(e.target.value)}
                    // required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Image Link</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setImageLink(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Tasting Notes</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    onChange={(e) => setTastingNotes(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={fileIcon === null ? "" : URL.createObjectURL(fileIcon)}
          />
          <ImageModal
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            imageUrl={fileImage === null ? "" : URL.createObjectURL(fileImage)}
          />
          <Modal
            show={showModal}
            onHide={handleClose1}
            size="lg"
            centered
            className="custom-modalproduct"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Brand</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modalproduct-body">
              <Form onSubmit={handleSubmitAddBrand}>
                <Form.Group className="mb-3" controlId="newBrandName">
                  <Form.Label>Brand Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter brand name"
                    // value={newBrandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </Form.Group>
                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    id="btn1"
                    variant="primary"
                    disabled={brandLoading}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {brandLoading && (
                        <ThreeDots color="#fff" height={20} width={30} />
                      )}
                      {!brandLoading && <span>Add</span>}
                    </div>
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          {/* Volume Unit Create Modal */}
          <Modal
            show={showModal1}
            onHide={handleClose2}
            size="lg"
            centered
            className="custom-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Volume Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
              <Form onSubmit={handleSubmitAddVolume}>
                <Form.Group className="mb-3" controlId="VolumeUnit">
                  <Form.Label>Volume</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Unit"
                    value={productVolumeUnit}
                    onChange={(e) => setProductVolumeUnit(e.target.value)}
                  />
                </Form.Group>
                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    id="btn1"
                    variant="primary"
                    disabled={volumeUnitLoading}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {volumeUnitLoading && (
                        <ThreeDots color="#fff" height={20} width={30} />
                      )}
                      {!volumeUnitLoading && <span>Add Volume Unit</span>}
                    </div>
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddProductmain({ onAdd }) {
  return (
    <>
      <OffCanvasAddUsermain
        key={1}
        placement={"end"}
        name={"+ Add More"}
        load={onAdd}
      />
    </>
  );
}
