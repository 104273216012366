import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bannerEdit } from "../api/apiIntegration";
import { getUser } from "../auth/authService.js";
import ImageModal from "./components/image_model.js";

const EditBanner = ({ onAdd, show, onClose, onUpdate, BannerData }) => {
  const [banner, setBanner] = useState(BannerData);

  const [loading, setLoading] = useState(false);
  const [bannertitle, setbannertitle] = useState(banner.Bannertitle);
  const [bannerDescription, setbannerDescription] = useState(
    banner.BannerDescription
  );
  const [bannerexternalWebLink, setbannerexternalWebLink] = useState(
    banner.BannerexternalWebLink
  );
  const [bannerImage, setbannerImage] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [imagePreview, setImagePreview] = useState(banner.BannerImage);
  const [modalShow, setModalShow] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(banner.BannerIsEnabled);
  useEffect(() => {}, [BannerData]);

  const handleTitleChange = (event) => {
    setbannertitle(event.target.value);
  };
  const handleweblinkChange = (event) => {
    setbannerexternalWebLink(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setbannerDescription(event.target.value);
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleUploadImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "png"
      ) {
        toast.error("Only JPG & PNG & JPEG images are allowed");
        setbannerImage(null);
        setImagePreview(banner.BannerImage); // Revert to the existing image
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width === 1080 && img.height === 900) {
          setbannerImage(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile)); // Update preview
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setbannerImage(null);
          setImagePreview(banner.BannerImage); // Revert to the existing image
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userGet = await getUser();
    const formData = new FormData();
    formData.append("bannerId", banner.BannerId);
    formData.append("bannertitle", bannertitle);
    // formData.append("weblink", weblink);
    formData.append("bannerDescription", bannerDescription);
    formData.append("bannerImage", bannerImage);
    formData.append("createdBy", userGet.AdminId);
    formData.append("IsEnabled", IsEnabled);
    console.log(formData);
    try {
      setLoading(true);
      const response = await bannerEdit(formData);
      if (response.success == true) {
        toast.success(response.message);
        onAdd();
        onClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Banner</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="Text"
                  name="Title"
                  value={bannertitle}
                  onChange={handleTitleChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="Text"
                  name="Description"
                  value={bannerDescription}
                  onChange={handleDescriptionChange}
                  as="textarea"
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>web link</Form.Label>
                <Form.Control
                  type="url"
                  name="weblink"
                  value={bannerexternalWebLink}
                  onChange={handleweblinkChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <Form.Group className="mb-2" controlId="productCategoryName">
                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  type="file"
                  name="banner"
                  onChange={handleUploadImage}
                />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1080px x 900px )
                </Form.Text>
              </Form.Group>

              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Banner Preview"
                    style={{ maxWidth: "100%", height: "100px" }}
                    onClick={() => handleImageClick()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span> Update Category</span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={imagePreview}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default EditBanner;
