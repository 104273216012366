import React, { useCallback, useState } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { Width } from "devextreme-react/cjs/chart";

export default function changedPassword() {
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <div className="card-title mb-5">
                      <button type="button" className="btn mt-5" id="back">
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                  <div id="h2" className="card-header d-flex align-items-center" >
                    <h2 className="card-title mb-3 ">Change Password</h2>
                  </div>
                  <div  id="underline" className="card-body pt-5 px-3">
                    <form>
                      <div className="form-group mb-3">
                        <label htmlFor="currentPassword" className="form-label mb-2"> 
                          Old Password
                        </label>
                        <input
                          style={{ width: "40%" ,padding:"20px"}}
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          name="currentPassword"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="newPassword"className="form-label mb-2" >New Password</label>
                        <input
                          style={{ width: "40%" ,padding:"20px"}}
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          name="newPassword"
                        />
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="confirmPassword"  className="form-label mb-2">
                          Confirm New Password
                        </label>
                        <input
                          style={{ width: "40%" ,padding:"20px"}}
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                      </div>
                      <button id="save" type="submit" className="btn px-5  mb-3">
                        save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
