import React, { useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { useParams, useLocation } from "react-router-dom";
import { TotalEarnedPoints } from "../api/apiIntegration";
import { format } from "date-fns";
import LoaderOwn from "../common/loaderOwn.js";

import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";

export default function TotalEarnpointsList() {
  const { userId } = useParams();
  const location = useLocation();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showHeaderFilter] = useState(true);

  useEffect(() => {
    list();
  }, [userId]);

  const list = async () => {
    try {
      const response = await TotalEarnedPoints(userId);
      if (response.success) {
        setTransactions(response.data.earnedPointsList);
      }
    } catch (error) {
      console.error("Failed to fetch total earned points", error);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy ");
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Manual Transactions</h4>
                    <div className="ms-auto right_btn"></div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {/* {isLoading ? (
                      <LoaderOwn />
                    ) : ( */}
                    <DataGrid
                      id="gridContainer"
                      keyExpr="earnedPointsRecordID"
                      dataSource={transactions}
                      columnAutoWidth={true}
                      showBorders={true}
                      width="100%"
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                    >
                      <HeaderFilter visible={showHeaderFilter} />
                      <Paging defaultPageSize={8} />
                      <Pager visible={true} />

                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        placeholder="Search Transactions..."
                      />

                      <Grouping autoExpandAll={false} />

                      <Column
                        caption="Earned"
                        alignment="center"
                        width={200}
                        dataField="earnedPointsAmount"
                        format={{ type: "fixedPoint", precision: 2 }}
                      />
                      <Column
                        alignment="center"
                        caption="Redeemed"
                        width={200}
                        dataField=""
                        format={{ type: "fixedPoint", precision: 2 }}
                        calculateCellValue={(transactions) => {
                          return transactions.redeemed !== null &&
                          transactions.redeemed !== undefined
                            ? transactions.redeemed
                            : 0;
                        }}
                      />
                      <Column
                        alignment="center"
                        caption="Earn Points Balance"
                        width={250}
                        dataField="earnPointsBalance"
                        format={{ type: "fixedPoint", precision: 2 }}
                      />
                      <Column
                        width={250}
                        alignment="left"
                        caption="Created Date"
                        dataField="createdDate"
                        calculateCellValue={({ createdDate }) =>
                          formatDate(createdDate)
                        }
                      />
                      <Column
                        alignment="left"
                        caption="Expiration Date"
                        dataField="earnedPointsExpirationDate"
                        calculateCellValue={({ createdDate }) =>
                          formatDate(createdDate)
                        }
                      />
                    </DataGrid>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
