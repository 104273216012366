import React from 'react';

const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.banner}</td>
      <td>{rowInfo.data.Title}</td>
      <td>{rowInfo.data.Dec}</td>
      <td>{rowInfo.data.Url}</td>
      <td>{rowInfo.data.CreatedAt}</td>
      <td>{rowInfo.data.Actions}</td>



     
    </tr>
  </React.Fragment>

);
export default DataRow;
