import React, { useState } from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";
function EditUser({ onAdd,show, onClose, userData, onUpdate, placement }) {
  const [formData, setFormData] = useState(userData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://bbr-api.billsfox.com/api/user/update/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        const updatedUser = await response.json();
        onUpdate(updatedUser);
        onAdd();
        onClose();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <Offcanvas show={show} onHide={onClose} placement={"end"}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit User</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>FirstName</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="FirstName"
              name="FirstName"
              value={formData.FirstName}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>LastName</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="LastName"
              name="LastName"
              value={formData.LastName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>PhoneNumber</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="PhoneNumber"
              name="PhoneNumber"
              value={formData.PhoneNumber}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              className="form-control"
              id="Email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nationality</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="Nationality"
              name="Nationality"
              value={formData.Nationality}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="Location"
              name="Location"
              value={formData.Location}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Update User
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default EditUser;
