import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  onRowClick,
} from "devextreme-react/data-grid";
import { toast, ToastContainer } from "react-toastify";
import { getUser } from "../auth/authService.js";

import { format } from "date-fns";
import {
  orderList,
  purchaseListFilter,
  updateOrderStatus,
} from "../api/apiIntegration.js";
import Filter from "./components/filter";
import LoaderOwn from "../common/loaderOwn.js";
export default function PurchaseHistory() {
  const [showHeaderFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await orderList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilterApply = async (filterData) => {
    console.log("Filter Data:", filterData);
    try {
      setIsLoading(true);
      const response = await purchaseListFilter(filterData);
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleStatusToggle = async (data, newStatus) => {
    const userGet = await getUser();

    var form = {
      id: data.orderOrderID,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateOrderStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "0";
      case 1:
        return "In Progress";
      case 2:
        return "Ready for Pick Up";
      case 3:
        return "Pending in POS";
      case 4:
        return "Closed Orders";
      default:
        return "";
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy hh:mm a");
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Purchase History</h4>
                    <div className="ms-auto right_btn d-flex">
                      {!isLoading ? (
                        <Filter
                          onApply={handleFilterApply}
                          status={"purchaseHistory"}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="orderReferenceID"
                        columnAutoWidth={false}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                      >
                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />
                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                        />
                        <Grouping autoExpandAll={false} />
                        <Column
                          dataField="orderReferenceID"
                          caption="Order Reference ID"
                          width={180}
                        />
                        <Column
                          dataField="orderCustomerName"
                          width={150}
                          caption="Customer name"
                        />
                        <Column
                          alignment="left"
                          dataField="orderVatAmount"
                          width={100}
                          caption="VAT"
                          calculateCellValue={(data) =>
                            data.orderVatAmount !== null
                              ? data.orderVatAmount
                              : 0
                          }
                        />
                        <Column
                          alignment="center"
                          dataField="orderNetAmount"
                          width={150}
                          caption="Net Amount"
                        />
                        <Column
                          alignment="center"
                          dataField="orderRedeemAmount"
                          width={150}
                          caption="Redeem Amount"
                          calculateCellValue={(data) =>
                            data.orderRedeemAmount !== null
                              ? data.orderRedeemAmount
                              : 0
                          }
                        />
                        <Column
                          alignment="center"
                          dataField="orderGrossAmount"
                          width={150}
                          caption="Gross Amount"
                          calculateCellValue={(data) =>
                            data.orderGrossAmount !== null
                              ? data.orderGrossAmount
                              : 0
                          }
                        />
                         <Column
                          alignment="left"
                          dataField="orderProgressStatus"
                          width={150}
                          caption="Ordered Status"
                          calculateCellValue={(data) =>
                            getStatusLabel(data.orderProgressStatus)
                          }
                        />
                        <Column
                          dataField="orderCreatedDate"
                          width={200}
                          caption="Orderd At"
                          calculateCellValue={(data) =>
                            formatDate(data.orderCreatedDate)
                          }
                        />
                        <Column
                          dataField="orderDeliveryDate"
                          width={180}
                          caption="Picked Up At"
                          calculateCellValue={(data) =>
                            formatDate(data.orderDeliveryDate)
                          }
                        />
                        {/* <Column
                          alignment="center"
                          caption="Status"
                          width={150}
                          cellRender={({ data }) => (
                            <button
                              id="active_deactive"
                              className={`btn btn-${
                                data.IsEnabled === true ? "success " : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.IsEnabled === true ? "Active" : "Deactive"}
                            </button>
                          )}
                        /> */}
                        <Column
                          width={150}
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                              // onEdit={handleEdit}
                              // onDelete={handleDelete}
                              // onToggleStatus={handleStatusToggle}
                            />
                          )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
    </>
  );
}
export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {/* {IsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                // onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                // onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
}
