import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categoryEdit } from "../api/apiIntegration";
import { getUser } from "../auth/authService.js";
import ImageModal from "./components/image_model.js";

const EditCategory = ({ onAdd, show, onClose, categoryData, onUpdate }) => {
  const [category, setcategory] = useState(categoryData);
  const [modalShow, setModalShow] = useState(false);
  const [productCategoryName, setProductcategoryName] = useState(
    category.ProductCategoryName
  );
  const [productCategoryDesc, setProductcategoryDesc] = useState(
    category.ProductCategoryDesc
  );
 
  const [remainingChars, setRemainingChars] = useState(200 - category.ProductCategoryDesc.length);
  const [productCategoryIcon, setProductCategoryIcon] = useState(null);
  const [imagePreview, setImagePreview] = useState(
    category.ProductCategoryIcon
  );
  const [loading, setLoading] = useState(false);

  const [imageSelected, setImageSelected] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(category.IsEnabled);
  
  const handleProductCategoryName = (event) => {
    setProductcategoryName(event.target.value);
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  useEffect(() => {}, [categoryData]);

  const handleFileIcon = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
  
      // Check file extension
      if (fileExtension !== "jpg" && fileExtension !== "jpeg") {
        toast.error("Only JPG and JPEG images are allowed");
        setProductCategoryIcon(null);
        setImageSelected(false);
        return;
      }
  
      // Check file size (150KB = 150 * 1024 bytes)
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setProductCategoryIcon(null);
        setImageSelected(false);
        return;
      }
  
      const img = new Image();
      img.onload = () => {
        // Check image dimensions
        if (img.width === 1080 && img.height === 900) {
          setProductCategoryIcon(selectedFile);
          setImageSelected(true);
        } else {
          toast.error("Image dimensions must be exactly 1200x800 pixels");
          setProductCategoryIcon(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };
  
  const handleProductCategoryDesc = (e) => {
    const countDesc = e.target.value;
    setProductcategoryDesc(countDesc);
    setRemainingChars(200 - countDesc.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userGet = await getUser();
    const formData = new FormData();
    formData.append("productCategoryId", category.ProductCategoryId);
    formData.append("productCategoryName", productCategoryName);
    formData.append("productCategoryIcon", productCategoryIcon);
    formData.append("productCategoryDesc", productCategoryDesc);
    formData.append("createdBy", userGet.AdminId);
    formData.append("IsEnabled", IsEnabled);

    console.log(formData);
    try {
      setLoading(true);
      const response = await categoryEdit(formData);
      if (response.success == true) {
        toast.success(response.message);
        onAdd();
        onClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked); // Update state when the switch is toggled
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Category</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productCategoryName">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  name="productCategoryName"
                  value={productCategoryName}
                  onChange={handleProductCategoryName}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productCategoryDesc">
                <Form.Label>Category Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="productCategoryDesc"
                  value={productCategoryDesc}
                  onChange={handleProductCategoryDesc}
                />
                 <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters: 200)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingChars}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productCategoryIcon">
                <Form.Label>Category Icon</Form.Label>
                <Form.Control type="file" onChange={handleFileIcon} />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1080px x 900px )
                </Form.Text>
              </Form.Group>
              {productCategoryIcon === null ? (
                <img
                  src={imagePreview}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              ) : (
                <img
                  src={URL.createObjectURL(productCategoryIcon)}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              )}
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span> Update Category</span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={
            productCategoryIcon === null
              ? imagePreview
              : URL.createObjectURL(productCategoryIcon)
          }
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditCategory;
