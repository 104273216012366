import React, { useCallback, useState } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";

import DataGrid, {
  Column,
  SearchPanel,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
 
  HeaderFilter,

} from "devextreme-react/data-grid";
import DataRow from "./StoresDataRow.js";
import AddStorestmain from "./addstores.js";


const stores=[
    {
        Location:"Dubai Outlet MallDubai Al-Ain Road",
        Address:" Parking Lot - Burjuman Metro Exit 4 - Sheikh Khalifa Bin Zayed St - Dubai",
        Contact:"+971 58 808 2391",
        Actions:<Actions/>

    },
    {
        Location:"Dubai Flea Market",
        Address:"Zabeel Park - Za'abeel - Al Kifaf - Dubai ",
        Contact:" +971 55 508 8214",
        Actions:<Actions/>

    },
    {
        Location:"Grand Souk - Bur Dubai",
        Address:" 2 34th St - Bur Dubai - Al Fahidi - Dubai",
        Contact:" +971 52 434 7719",
        Actions:<Actions/>

    },
    {
        Location:"Covent Garden Market Dubai",
        Address:" at JBR - The Walk - Jumeirah Beach Residence",
        Contact:"+971 58 937 4621",
        Actions:<Actions/>

    },
];




export default function Stores() {
 
    const [showHeaderFilter] = useState(true);
    return (
      <>
        <header>
          <Header />
          <Sidebar />
        </header>


        <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Stores</h4>
                    <div className="ms-auto right_btn">
                      <AddStorestmain />
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    <DataGrid
                      id="gridContainer"
                      dataSource={stores}
                      // keyExpr="ID"
                      columnAutoWidth={true}
                      showBorders={true}
                      width="100%"
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                      dataRowRender={DataRow}
                    >
                    
                      <HeaderFilter visible={showHeaderFilter} />
                      <Paging defaultPageSize={8} />
                      <Pager visible={true} />

                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        placeholder="Search Stores..."
                      />

                      <Grouping autoExpandAll={false} />
                      <Column dataField="Location"  />
                      <Column dataField="Address" />
                      <Column dataField="Contact" />
                    
                      
                    </DataGrid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>




        </>
  );
}
export function Actions() {
  return (
    <div className="dropdown">
      <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <span className="option_icon"><img src="images/more_icon.svg" alt="More options" /></span>
      </a>
      <ul className="dropdown-menu">
        <li><a className="dropdown-item" href="#"><img src="images/blue_edit_icon.svg" alt="Edit" /> <span>Edit</span></a></li>
        <li><a className="dropdown-item" href="#"><img src="images/blue_delete_icon.svg" alt="Delete" /> <span>Delete</span></a></li>
      </ul>
    </div>
  );
}