import React, { useState, useEffect } from "react";
import { PieChart } from "@rsuite/charts";
import { userTotalChart } from "../../api/apiIntegration";
import LoaderOwn from "../../common/loaderOwn";
const UserPieChartOwn = () => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(getChartOptions());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userTotalChart();
        if (response.success) {
          const dataGet = response.data;
          const data = [
            ["Active Last (0-4months) ", dataGet.activeUserCount.count],
            ["Dormant (5-12 months) ", dataGet.dormantUserCount.count],
            ["Lapsed (13 months and more) ", dataGet.lapsedUserCount.count],
          ];
          setSeries(data);
          setIsLoading(false);
        } else {
          console.error("Failed to fetch data:", response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  function getChartOptions() {
    return {
      chart: {
        height: 400,
        type: "pie",
        toolbar: {
          show: true,
        },
      },
     
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }
  const customColors = ["#265D7F", "#33FF57", "#3357FF"];
  if (isLoading) {
    return <LoaderOwn />;
    
  }
  return (
    
    <PieChart
      name="User"
      data={series}
      colors={customColors}
      style={{ height: "480px" }}
    />
  );
};

export default UserPieChartOwn;
