import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header.js";
import Sidebar from "./components/sidebar.js";
import {
  SMTPSettingsUpdate,
  SMTPSettingsList,
  sendTestEmail,
} from "../api/apiIntegration";
import { format } from "date-fns";
import LoaderOwn from "../common/loaderOwn.js";
import { ThreeDots } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { Button, Form, Toast } from "react-bootstrap";
import { getUser } from "../auth/authService.js";

export default function SMTPNotifications({ onAdd, onClose }) {
  const [form, setForm] = useState({
    SMTPSettingsID: 1,
    emailHost: "",
    emailPort: "",
    emailUserName: "",
    emailPassword: "",
    emailEncryption: "",
    emailFromAddress: "",
    emailFromName: "",
    createdBy: "",
  });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [testEmail, setTestEmail] = useState("pkmani2001@gmail.com");
  const [isTestEmailLoading, setIsTestEmailLoading] = useState(false);
  const AdminId = async () => {
    try {
      const user = await getUser();
      setForm((prevForm) => ({
        ...prevForm,
        createdBy: user.AdminId,
      }));
    } catch (error) {
      toast.error("Failed to get user information.");
    }
  };

  useEffect(() => {
    AdminId();
    list();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleTestEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsTestEmailLoading(true);
      const response = await sendTestEmail(testEmail);
      console.log("Test Email Response:", response);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while sending the test email."
      );
    } finally {
      setIsTestEmailLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await SMTPSettingsUpdate(form);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await SMTPSettingsList();
      if (response.success) {
        const smtpSettings = response.data[0];
        console.log("Fetched SMTP Settings:", smtpSettings);
        setForm((prevForm) => ({
          ...prevForm,
          SMTPSettingsID:
            smtpSettings.SMTPSettingsID || prevForm.SMTPSettingsID,
          emailHost: smtpSettings.EmailHost || prevForm.emailHost,
          emailPort: smtpSettings.EmailPort || prevForm.emailPort,
          emailUserName: smtpSettings.EmailUserName || prevForm.emailUserName,
          emailPassword: smtpSettings.EmailPassword || prevForm.emailPassword,
          emailEncryption:
            smtpSettings.EmailEncryption || prevForm.emailEncryption,
          emailFromAddress:
            smtpSettings.EmailFromAddress || prevForm.emailFromAddress,
          emailFromName: smtpSettings.EmailFromName || prevForm.emailFromName,
        }));
      } else {
        toast.error("Failed to fetch SMTP settings.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching SMTP settings.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">SMTP Configuration</h4>
                    <div className="ms-auto right_btn"></div>
                  </div>
                  {loading ? (
                    <LoaderOwn />
                  ) : (
                    <div className="card-body pt-2 px-3">
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <Form.Group className="mb-3">
                                  <Form.Label>Mail Host</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailHost"
                                    value={form.emailHost}
                                    onChange={handleChange}
                                    placeholder="MAIL HOST"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail Port</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailPort"
                                    value={form.emailPort}
                                    onChange={handleChange}
                                    placeholder="MAIL PORT"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail UserName</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailUserName"
                                    value={form.emailUserName}
                                    onChange={handleChange}
                                    placeholder="MAIL USERNAME"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="emailPassword"
                                    value={form.emailPassword}
                                    onChange={handleChange}
                                    placeholder="MAIL PASSWORD"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail Encryption</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailEncryption"
                                    value={form.emailEncryption}
                                    onChange={handleChange}
                                    placeholder="MAIL ENCRYPTION"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail From Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailFromAddress"
                                    value={form.emailFromAddress}
                                    onChange={handleChange}
                                    placeholder="MAIL FROM ADDRESS"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                  <Form.Label>Mail From Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="emailFromName"
                                    value={form.emailFromName}
                                    onChange={handleChange}
                                    placeholder="MAIL FROM NAME"
                                  />
                                </Form.Group>

                                <div className="text-end mt-3">
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={loading}
                                  >
                                    {loading && (
                                      <ThreeDots
                                        color="#fff"
                                        height={1}
                                        width={100}
                                      />
                                    )}
                                    {loading
                                      ? "Saving..."
                                      : "Save Configuration"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0 h6">Test SMTP Configuration</h5>
                  </div>
                  <div className="card-body">
                    <Form onSubmit={handleTestEmailSubmit} className="mt-4">
                      <Form.Group className="mb-3">
                        <Form.Label>Test Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={testEmail}
                          onChange={(e) => setTestEmail(e.target.value)}
                          placeholder="Enter email to send test"
                        />
                      </Form.Group>
                      <div className="text-center mt-3">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isTestEmailLoading}
                        >
                          {isTestEmailLoading
                            ? "Sending..."
                            : "Send Test Email"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
