import React, { useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { toast, ToastContainer } from "react-toastify";
import DataGrid, {
  Column,
  Grouping,
  Paging,
  SearchPanel,
  HeaderFilter,
  Pager,
} from "devextreme-react/data-grid";
import {
  competitionsList,
  updateCompetitionStatus,
  deleteCompetition,
} from "../api/apiIntegration.js";
import { getUser } from "../auth/authService.js";
import { format } from "date-fns";
import Editcompetition from "./EditCompetition.js";
import AddCompetition from "./addCompetition.js";
import LoaderOwn from "../common/loaderOwn.js";
import DeleteCompetitionModal from "./DeleteCompetition.js";
import { useNavigate } from "react-router-dom";
export default function Competitions() {
  const [showHeaderFilter] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [competitionToDelete, setCompetitionToDelete] = useState(null);
  const navigate = useNavigate();

  const handleTitleClick = (competition) => {
    navigate(`/competitions/${competition.competitionId}`);  };
  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await competitionsList();
      if (response.success == true) {
        
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (competition) => {
    setSelectedCompetition(competition);
    setShowEditOffcanvas(true);
  };
  const handleClose = () => {
    setSelectedCompetition(null);
    setShowEditOffcanvas(false);
  };
  const handleUpdate = (updatedcompetition) => {
    setData((prevData) =>
      prevData.map((competition) =>
        data.competitionId === updatedcompetition.competitionId ? updatedcompetition : competition
          
          
      )
    );
  };
  const handleDelete = (competition) => {
    setCompetitionToDelete(competition);
    setShowDeleteModal(true);
  };
  const confirmDelete = async () => {
    try {
      await deleteCompetition(competitionToDelete.competitionId);
      setData((prevData) =>
        prevData.filter(
          (competition) =>
            competition.competitionId !== competitionToDelete.competitionId
        )
      );
      toast.success("competition deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete competition.");
    } finally {
      setShowDeleteModal(false);
      setCompetitionToDelete(null);
    }
  };

  const handleStatusToggle = async (competition, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: competition.competitionId,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateCompetitionStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy hh:mm a");
  };
  const handleError = (event) => {
    event.target.src = "../images/no_image.png";
  };
 
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Competitions</h4>
                    <div className="ms-auto right_btn"></div>
                    <AddCompetition onAdd={list} />
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                    
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="competitionId"
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={false}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        
                      >
                        <HeaderFilter visible={showHeaderFilter} />

                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Competitions..."
                        />

                        <Grouping autoExpandAll={false} />
                        <Column
                          alignment="center"
                          width={200}
                          caption="Image"
                          dataField="competitionImageUrl"
                          cellRender={({ data }) =>
                            data.competitionImageUrl ? (
                              <img
                                src={data.competitionImageUrl}
                                alt="competitionImageUrl"
                                style={{ maxWidth: "70%", height: "auto" }}
                                onError={handleError}
                              />
                            ) : (
                              <img
                                src="../images/no_image.png"
                                alt="ImageUrl"
                                style={{ maxWidth: "20%", height: "15%" }}
                              />
                            )
                          }
                        />
                        <Column
                          dataField="title"
                          width={200}
                          caption="Title"
                          cellRender={({ data }) => (
                            // <div 
                            // style={{ whiteSpace: "normal" }}  onClick={() => handleTitleClick(data)}>
                            //   {data.competitionTitle}
                            // </div>
                            <div>
                            <a
                             
                              style={{
                                whiteSpace: "normal",
                                textDecoration: "none",
                                color: "blue",
                                maxWidth: "50%",
                              }}
                              onClick={() => handleTitleClick(data)}
                            >
                              {data.title}
                            </a>
                          </div>
                          )}
                        />
                        <Column
                          width={200}
                          dataField="subTitle"
                          caption="SubTitle"
                          cellRender={({ data }) => (
                            <div style={{ whiteSpace: "normal" }}>
                              {data.subTitle}
                            </div>
                          )}
                        />
                        <Column
                          width={150}
                          dataField="maxWinners"
                          caption="MaxWinners"
                          alignment="center"
                        />
                        <Column
                          width={200}
                          caption="Prize"
                          dataField="prize"
                          cellRender={({ data }) => (
                            <div style={{ whiteSpace: "normal" }}>
                              {data.prize}
                            </div>
                          )}
                        />
                        <Column
                          width={200}
                          caption="Start Date"
                          dataField="startDate"
                          calculateCellValue={({ startDate }) =>
                            formatDate(startDate)
                          }
                        />
                           <Column
                          width={200}
                          caption="End Date"
                          dataField="endDate"
                          calculateCellValue={({ endDate }) =>
                            formatDate(endDate)
                          }
                        />
                        <Column
                          width={300}
                          caption="Rules"
                          dataField="rules"
                          cellRender={({ data }) => (
                            <div style={{ whiteSpace: "normal" }}>
                              {data.rules}
                            </div>
                          )}
                        />

                        <Column
                          alignment="center"
                          caption="Status"
                          width={150}
                          dataField=""
                          cellRender={({ data }) => (
                            <button
                            id="active_deactive"
                              className={`btn btn-${
                                data.isEnabled === true
                                  ? "success "
                                  : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.isEnabled === true
                                ? "Active"
                                : "Deactive"}
                            </button>
                          )}
                        />
                        <Column
                        caption="Action"
                        alignment="center"
                          width={150}
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                              onToggleStatus={handleStatusToggle}
                            />
                          )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
      {selectedCompetition && (
        <Editcompetition
         load={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          competitionData={selectedCompetition}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
      <DeleteCompetitionModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}

export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const {   isEnabled } = data;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {isEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" /> <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" /> <span>Active</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
