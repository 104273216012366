import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { TopSpenderMonthChart } from "../../api/apiIntegration.js";
import LoaderOwn from "../../common/loaderOwn.js";

const MonthChartNationality = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    try {
      setIsLoading(true);
      const response = await TopSpenderMonthChart();
      if (response.success === true) {
        setData(response.data);
      } else {
       
      }
    } catch (error) {
     
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderOwn />;
  }

  if (!data) {
    return <div></div>;
  }

  const nationalities = Array.from(
    new Set([
      ...data.currentMonth.map((item) => item.Nationality),
      ...data.previousMonth.map((item) => item.Nationality),
    ])
  );

  const chartSeries = nationalities.map((nationality) => {
    const currentMonthItem = data.currentMonth.find(
      (item) => item.Nationality === nationality
    );
    const previousMonthItem = data.previousMonth.find(
      (item) => item.Nationality === nationality
    );

    return {
      name: nationality,
      data: [
        currentMonthItem ? currentMonthItem.TotalAmount : 0,
        previousMonthItem ? previousMonthItem.TotalAmount : 0,
      ],
    };
  });

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    stroke: {
      width: 2,
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: ["Current Month", "Previous Month"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Total Amount",
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default MonthChartNationality;