import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Filter from "./components/filter";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  onRowClick,
} from "devextreme-react/data-grid";
import { toast, ToastContainer } from "react-toastify";
import { format } from "date-fns";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DataRow from "./DataRow.js";
import EditUser from "./Edituser.js";
import LoaderOwn from "../common/loaderOwn.js";
import {
  userList,
  userListFilter,
  updateUserstatus,
} from "../api/apiIntegration.js";
import DeleteUser from "./DeleteUser.js";
import { getUser } from "../auth/authService.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const pageSizes = [10, 25, 50, 100];

export default function User() {
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();

  const handleUserClick = (user) => {
    navigate(`/user/${user.UserId}`);
  };
  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    try {
      setLoading(true);
      const response = await userList();
      if (response.success == true) {
        const formattedData = response.data.map((user) => ({
          ...user,
          CreatedDate: format(
            new Date(user.CreatedDate),
            "dd-MMM-yyyy hh:mm a"
          ),
        }));
        setData(formattedData);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy hh:mm a");
  };
  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditOffcanvas(true);
  };

  const handleUpdate = (updatedUser) => {
    setData((prevData) =>
      prevData.map((user) =>
        user.UserId === updatedUser.UserId ? updatedUser : user
      )
    );
    setShowEditOffcanvas(false);
  };
  const handleFilterApply = async (filterData) => {
    console.log("Filter Data:", filterData);
    try {
      setLoading(true);
      const response = await userListFilter(filterData);
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await userToDelete.UserId;
      setData((prevData) =>
        prevData.filter((user) => user.UserId !== userToDelete.UserId)
      );
      toast.success("user deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete user.");
    } finally {
      setShowDeleteModal(false);
      setUserToDelete(null);
    }
  };
  const handleStatusToggle = async (user, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: user.UserId,
      status: newStatus,
      currentUserId: userGet.AdminId,
    };
    try {
      const response = await updateUserstatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };
  
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">User</h4>
                    <div className="ms-auto right_btn d-flex">
                      {!loading ? (
                        <Filter
                          onAdd={list}
                          onApply={handleFilterApply}
                          status={"user"}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <div className="card-body pt-2 px-3">
                    {loading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="UserId"
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                      >
                        <Paging defaultPageSize={10} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                        />
                        <Grouping autoExpandAll={false} />

                        <Column
                          dataField="FirstName"
                          cellRender={({ data }) => (
                            <div
                              style={{
                                whiteSpace: "normal",
                                maxWidth: "100%",
                              }}
                              onClick={() => handleUserClick(data)}
                            >
                              {data.FirstName}
                            </div>
                          )}
                        />
                        <Column dataField="LastName" />

                        <Column
                          dataField="PhoneNumber"
                          caption="Phone NO"
                          cellRender={({ data }) => (
                            <div> {data.PhoneNumber}</div>
                          )}
                        />

                        <Column
                          dataField="Email"
                          cellRender={({ data }) => <div>{data.Email}</div>}
                        />
                        <Column dataField="Nationality" caption="country" />
                        {/* <Column dataField="Location" /> */}
                        {/* <Column dataField="Account" /> */}
                        {/* <Column dataField="Status" /> */}
                        {/* <Column dataField="Options" /> */}

                        <Column
                          dataField="CreatedDate"
                          caption="Created Date"
                          dataType="string"
                          sortOrder="desc"
                          calculateCellValue={({ CreatedDate }) =>
                            new Date(CreatedDate)
                          }
                          cellRender={({ data }) =>
                            formatDate(data.CreatedDate)
                          }
                        />
                        {/* <Column
                          alignment="center"
                          caption="Status"
                          width={150}
                          cellRender={({ data }) => (
                            <button
                              id="active_deactive"
                              className={`btn btn-${
                                data.IsEnabled === true ? "success " : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.IsEnabled === true ? "Active" : "Deactive"}
                            </button>
                          )}
                        /> */}
                        <Column
                          caption="Options"
                          alignment="center"
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                              onToggleStatus={handleStatusToggle}
                            />
                          )}
                          width={200}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>

      {selectedUser && (
        <EditUser
          onAdd={list}
          show={showEditOffcanvas}
          onClose={() => setShowEditOffcanvas(false)}
          userData={selectedUser}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
      <DeleteUser
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}

export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="dropdown" style={{ position: "absolute" }}>
          <a
            href="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="option_icon">
              <img src="images/more_icon.svg" />
            </span>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onEdit(data)}
              >
                <img src="images/blue_edit_icon.svg" alt="" /> <span>Edit</span>
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onDelete(data)}
              >
                <img src="images/blue_delete_icon.svg" alt="" />{" "}
                <span>Delete</span>
              </a>
            </li>
            {/* <li>
              {IsEnabled ? (
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => onToggleStatus(data, false)}
                  >
                    <img src="images/disabled.png" alt="Deactive" />{" "}
                    <span>Deactive</span>
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => onToggleStatus(data, true)}
                  >
                    <img src="images/check-mark.png" alt="Active" />{" "}
                    <span>Active</span>
                  </a>
                </li>
              )}
              <a className="dropdown-item" href="#">
                <img src="images/view-icons.svg" alt="" /> <span>Block</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
