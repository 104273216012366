import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import axios from "axios";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  ColumnChooser,
  ColumnFixing,
} from "devextreme-react/data-grid";
import {
  brandList,
  deleteBrand,
  updateBrandStatus,
} from "../api/apiIntegration.js";
import AddBrands from "./addBrands.js";
import EditBrand from "./Editbrand.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderOwn from "../common/loaderOwn.js";
import { Modal, Button } from "react-bootstrap";
import DeleteBrand from "./DeleteBrand.js";
import { getUser } from "../auth/authService.js";

export default function Brands() {
  const [showHeaderFilter] = useState(true);
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);
  useEffect(() => {
    list();
  }, []);
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await brandList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleEdit = (brand) => {
    setSelectedBrand(brand);
    setShowEditOffcanvas(true);
  };
  const handleClose = () => {
    setSelectedBrand(null);
    setShowEditOffcanvas(false);
  };
  const handleUpdate = (updatedbrand) => {
    setData((prevData) =>
      prevData.map((brand) =>
        data.BrandId === updatedbrand.BrandId ? updatedbrand : brand
      )
    );
  };

  const handleDelete = (brand) => {
    setBrandToDelete(brand);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteBrand(brandToDelete.BrandId);
      setData((prevData) =>
        prevData.filter((brand) => brand.BrandId !== brandToDelete.BrandId)
      );
      toast.success("Brand deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete brand.");
    } finally {
      setShowDeleteModal(false);
      setBrandToDelete(null);
    }
  };
  const handleStatusToggle = async (brand, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: brand.BrandId,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateBrandStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };

  const handleError = (event) => {
    event.target.src = "../images/no_image.png";
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div class="content pt-3">
          <div className="px-4">
            <div className="row">
              <div class="col-lg-12">
                <div class="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Brands</h4>
                    <div className="ms-auto right_btn">
                      <AddBrands onAdd={list} />
                    </div>
                  </div>
                  <div class="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        keyExpr="BrandId"
                        columnAutoWidth={true}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                      >
                        <HeaderFilter visible={showHeaderFilter} />
                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Products..."
                        />

                        <Grouping autoExpandAll={false} />
                        <Column
                          dataField="BrandBanner"
                          width={200}
                          cellRender={({ data }) =>
                            data.BrandBanner ? (
                              <img
                                src={data.BrandBanner}
                                alt="BrandBanner"
                                className="BrandBanner"
                                style={{ maxWidth: "100%", height: "auto" }}
                                onError={handleError}
                              />
                            ) : (
                              <img
                                src="../images/no_image.png"
                                alt="BrandBanner"
                                style={{ maxWidth: "100%", height: "15%" }}
                              />
                            )
                          }
                        />
                        <Column dataField="BrandName" width={180} />
                        <Column
                          dataField="BrandDesc"
                          width={350}
                          cellRender={({ data }) => (
                            <div
                              style={{ whiteSpace: "normal", maxWidth: "100%" }}
                            >
                              {data.BrandDesc}
                            </div>
                          )}
                        />
                        <Column
                          dataField="BrandURL"
                          width={400}
                          cellRender={({ data }) => (
                            <div>
                              <a
                                href={data.BrandURL}
                                style={{
                                  textDecoration: "underline",
                                  color: "blue",
                                  maxWidth:"50%"
                                }}
                              >
                                {data.BrandURL}
                              </a>
                            </div>
                          )}
                        />
                        <Column
                          alignment="center"
                          caption="Status"
                          width={150}
                          cellRender={({ data }) => (
                            <button
                              id="active_deactive"
                              className={`btn btn-${
                                data.IsEnabled === true ? "success " : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.IsEnabled === true ? "Active" : "Deactive"}
                            </button>
                          )}
                        />
                        <Column
                          width={100}
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                              onToggleStatus={handleStatusToggle}
                            />
                          )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
      {selectedBrand && (
        <EditBrand
          load={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          brandData={selectedBrand}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
      <DeleteBrand
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}
export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {IsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
