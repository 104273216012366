import React from 'react';


const formatDate = new Intl.DateTimeFormat('en-US').format;
const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.Profile}</td>
      <td>+91-9876543210</td>
      <td>xyz@mail.com</td>
      <td>India</td>
      <td>Tamilnadu</td>
      <td>{rowInfo.data.Accounting}</td>
      <td>{rowInfo.data.Status}</td>
      {/*<td>{rowInfo.data.Option}</td> */}
      <td>{rowInfo.data.Action}</td>
      {/* <td>{formatDate(new Date(rowInfo.data.BirthDate))}</td>
      <td>{formatDate(new Date(rowInfo.data.HireDate))}</td> */}
    </tr>
  </React.Fragment>
);

export default DataRow;
