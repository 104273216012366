import React from 'react';

const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.ItemName}</td>
      <td>{rowInfo.data.Category}</td>
      <td>{rowInfo.data.Price}</td>
      <td>{rowInfo.data.Stock}</td>
      <td>{rowInfo.data.Actions}</td>

    </tr>
  </React.Fragment>

);
export default DataRow;


