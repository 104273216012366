import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { TopSpenderWeekendChart } from "../../api/apiIntegration.js"; // Replace with your API function
import LoaderOwn from "../../common/loaderOwn.js";

const WeekendChartNationality = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    try {
      setIsLoading(true);
      const response = await TopSpenderWeekendChart();
      if (response.success === true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderOwn />;
  }

  if (!data) {
    return <div></div>;
  }

  const nationalities = Array.from(
    new Set([
      ...data.previousWeekend.map((item) => item.Nationality),
      ...data.weekendBefore.map((item) => item.Nationality),
    ])
  );

  // Prepare series data
  const chartSeries = nationalities.map((nationality) => {
    const previousWeekendItem = data.previousWeekend.find(
      (item) => item.Nationality === nationality
    );
    const weekendBeforeItem = data.weekendBefore.find(
      (item) => item.Nationality === nationality
    );

    return {
      name: nationality,
      data: [
        previousWeekendItem ? previousWeekendItem.TotalAmount : 0,
        weekendBeforeItem ? weekendBeforeItem.TotalAmount : 0,
      ],
    };
  });

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    stroke: {
      width: 2,
    },
    title: {
      text: "",
    },
    xaxis: {
      categories: ["Previous Weekend", "Weekend Before"],
      title: {
        text: "Weekend",
      },
    },
    yaxis: {
      title: {
        text: "Total Amount",
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default WeekendChartNationality;
