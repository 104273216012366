import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { getUser } from "../auth/authService.js";
import { Dropdown } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import ImageModal from "./components/image_model.js";
import { Modal } from "react-bootstrap";
import {
  brandList,
  subCategoryList,
  categoryList,
  productEdit,
  brandAdd,
  volumeunitList,
  volumeunitAdd,
} from "../api/apiIntegration";
const EditProducts = ({ onAdd, show, onClose, ProductsData, onUpdate }) => {
  const [Products, setProducts] = useState(ProductsData);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(Products.ProductName);
  const [productDesc, setProductDesc] = useState(Products.ProductDesc);
  const [productPrice, setProductPrice] = useState(Products.ProductPrice);
  const [productTags, setProductTags] = useState(Products.ProductTags);
  const [productVolume, setProductVolume] = useState(Products.ProductVolume);
  const [productUnit, setProductUnit] = useState(Products.ProductUnit);
  const [productAlcoholPercentage, setProductAlcoholPercentage] = useState(
    Products.ProductAlcoholPercentage
  );
  const [productRegion, setProductRegion] = useState(Products.ProductRegion);
  const [productCategoryId, setProductCategoryId] = useState(
    Products.CategoryId
  );
  const [productSubCategoryId, setProductSubCategoryId] = useState(
    Products.SubCategoryId
  );
  const [isLuxuryCollection, setIsLuxuryCollection] = useState(
    Products.IsLuxuryCollection
  );
  const [productDiscount, setProductDiscount] = useState(
    Products.ProductDiscount
  );
  const [productDiscountType, setProductDiscountType] = useState(
    Products.ProductDiscountType
  );
  const [exceptional, setExceptional] = useState(Products.Exceptional);
  const [productBrandId, setProductBrandId] = useState(Products.BrandID);
  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(Products.ProductImage);
  const [imagePreview1, setImagePreview1] = useState(Products.ProductIcon);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [fileIcon, setFileIcon] = useState(null);
  const [isTopItem, setIsTopItem] = useState(Products.IsTopItem);
  const [videoLink, setVideoLink] = useState(Products.VideoLink);
  const [hyperLink, setHyperLink] = useState(Products.HyperLink);
  const [imageLink, setImageLink] = useState(Products.ImageLink);
  const [productSKUCode, setProductSKUCode] = useState(Products.ProductSKUCode);
  const [productSAPCode, setProductSAPCode] = useState(Products.ProductSAPCode);
  const [tastingNotes, setTastingNotes] = useState(Products.TastingNotes);
  const [imageSelected, setImageSelected] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(Products.IsEnabled);
  const maxCharsProductName = 100;
  const maxCharsDesc = 2000;
  const [remainingCharsProductName, setRemainingCharsProductName] = useState(
    maxCharsProductName - Products.ProductName.length
  );
  const [remainingChars, setRemainingChars] = useState(
    maxCharsDesc - Products.ProductDesc.length
  );
  const [volumeUnitOptions, setVolumeUnitOptions] = useState([]);
  useEffect(() => {
    brandListEvent();
    categoryListEvent();
    volumeUnitListEvent();
    if (Products.CategoryId != null) {
      SubCategoryListEvent(Products.CategoryId);
    }
  }, [ProductsData]);
  const handleIstopChange = () => {
    setIsTopItem(!isTopItem);
  };
  const handleisLuxuryCollectionChange = () => {
    setIsLuxuryCollection(!isLuxuryCollection);
  };
  const handleExceptionalChange = () => {
    setExceptional(!exceptional);
  };
  const handleDiscountValueChange = (event) => {
    const value = event.target.value;

    setProductDiscount(value);
  };
  const handleDiscountTypeValueChange = (event) => {
    const value = event.target.value;
    setProductDiscountType(value);

    if (
      value === "percentage" &&
      productDiscount &&
      !productDiscount.endsWith("%")
    ) {
      setProductDiscount(productDiscount + "%");
    } else if (value === "netAmount" && productDiscount.endsWith("%")) {
      setProductDiscount(productDiscount.replace("%", ""));
    }
  };
  const handleProductRegion = (event) => {
    setProductRegion(event.target.value);
  };
  const handleProductDesc = (e) => {
    const countDesc = e.target.value;
    setProductDesc(countDesc);
    setRemainingChars(maxCharsDesc - countDesc.length);
  };
  const handleProductPrice = (event) => {
    setProductPrice(event.target.value);
  };
  const handleProductTags = (event) => {
    setProductTags(event.target.value);
  };
  const handleProductVolume = (event) => {
    setProductVolume(event.target.value);
  };
  const handleBrandSelectChange = (event) => {
    setProductBrandId(event.target.value);
  };
  const handlesetProductAlcoholPercentage = (event) => {
    setProductAlcoholPercentage(event.target.value);
  };
  const handleFileImage = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      if (!selectedFile.type.startsWith("image/jpeg")) {
        toast.error("Only JPG images are allowed");
        setFileImage(null);
        setImageSelected(false);
        return;
      }
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setFileImage(null);
        setImageSelected(false);
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileImage(selectedFile);
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setFileImage(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      if (!selectedFile.type.startsWith("image/jpeg")) {
        toast.error("Only JPG images are allowed");
        setFileIcon(null);
        setImageSelected(false);
        return;
      }
      if (selectedFile.size > 150 * 1024) {
        toast.error("File size must not exceed 150KB");
        setFileIcon(null);
        setImageSelected(false);
        return;
      }
      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileIcon(selectedFile);
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setFileIcon(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };

  const handleProductCategoryId = (event) => {
    setProductCategoryId(event.target.value);
    SubCategoryListEvent(event.target.value);
  };
  const handleProductSubCategoryId = (event) => {
    setProductSubCategoryId(event.target.value);
  };
  const handleVolumeUnitChange = (event) => {
    setProductUnit(event.target.value);
  };

  const handleNameChange = (e) => {
    const countDesc = e.target.value;
    setProductName(countDesc);
    setRemainingCharsProductName(100 - countDesc.length);
  };
  const brandListEvent = async () => {
    try {
      const response = await brandList();
      if (response.success == true) {
        setBrandOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success == true) {
        setcategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const SubCategoryListEvent = async (id) => {
    try {
      const response = await subCategoryList(id);
      if (response.success == true) {
        setSubCategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  const volumeUnitListEvent = async () => {
    try {
      const response = await volumeunitList();
      if (response.success == true) {
        setVolumeUnitOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("productId", Products.ProductId);
      formData.append("productName", productName);
      formData.append("productDesc", productDesc);
      formData.append("productPrice", productPrice);
      formData.append("productTags", productTags);
      formData.append("productVolume", productVolume);
      formData.append("productUnit", productUnit);
      formData.append("productAlcoholPercentage", productAlcoholPercentage);
      formData.append("productRegion", productRegion);
      formData.append("productImage", fileImage);
      formData.append("productIcon", fileIcon);
      formData.append("productCategoryId", productCategoryId);
      formData.append("productSubCategoryId", productSubCategoryId);
      formData.append("imageLink", imageLink);
      formData.append("tastingNotes", tastingNotes);
      formData.append("videoLink", videoLink);
      formData.append("isLuxuryCollection", isLuxuryCollection);
      formData.append("isTopItem", isTopItem);
      formData.append("productDiscountType", productDiscountType);
      formData.append("productDiscount", productDiscount);
      formData.append("exceptional", exceptional);
      formData.append("hyperLink", hyperLink);
      formData.append("productSKUCode", productSKUCode);
      formData.append("productSAPCode", productSAPCode);
      formData.append("orderSequence", 1);
      formData.append("brandID", productBrandId);

      formData.append("IsEnabled", IsEnabled);

      try {
        setLoading(true);
        const response = await productEdit(formData);
        if (response.success == true) {
          toast.success(response.message);
          onAdd();
          onClose();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating Products :", error);
      toast.error("An error occurred while updating the Products .");
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked); // Update state when the switch is toggled
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleIconClick = () => {
    setModalShow1(true);
  };
  useEffect(() => {
    handleInside2();
  }, []);
  const handleInside2 = () => {
    setBrandName("");
    setProductVolumeUnit("");
  };

  const [brandLoading, setBrandLoading] = useState(false);
  const [volumeUnitLoading, setVolumeUnitLoading] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [productVolumeUnit, setProductVolumeUnit] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const handleShow1 = () => setShowModal(true);
  const handleClose1 = () => {
    setShowModal(false);
    handleInside2();
  };
  const handleShow2 = () => setShowModal1(true);
  const handleClose2 = () => {
    setShowModal1(false);
    handleInside2();
  };

  const handleSubmitAddBrand = async (e) => {
    e.preventDefault();
    if (!brandName) {
      toast.error("Brand name is required.");
      return;
    }

    {
      const userGet = await getUser();

      const form = new FormData();
      form.append("brandName", brandName);
      form.append("brandOrder", 1);
      form.append("createdBy", userGet.AdminId);

      try {
        setBrandLoading(true);
        const response = await brandAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          await brandListEvent();
          handleClose1();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setBrandLoading(false);
      }
    }
  };
  const handleSubmitAddVolume = async (e) => {
    e.preventDefault();

    if (!productVolumeUnit) {
      toast.error("Product volume unit is required.");
      return;
    }

    const form = {
      productVolumeUnit: productVolumeUnit,
    };

    try {
      setVolumeUnitLoading(true);
      const response = await volumeunitAdd(form);
      if (response.success) {
        toast.success(response.message);

        await volumeUnitListEvent();
        handleClose2(); // Function to close the modal
      } else {
        toast.error(response.message || "Failed to add volume unit.");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred.");
    } finally {
      setVolumeUnitLoading(false);
    }
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Products</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="productName">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="100"
                  name="productName"
                  value={productName}
                  onChange={handleNameChange}
                />
                <>
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Maximum characters:30)
                  </Form.Text>
                  <br />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    You Have{" "}
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        padding: "2px 5px",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingCharsProductName}
                    </div>
                    Characters Left
                  </Form.Text>
                </>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="Category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={productCategoryId}
                  onChange={handleProductCategoryId}
                  required
                >
                  <option value="">Select...</option>
                  {categoryOptions.map((option, index) => (
                    <option key={index} value={option.ProductCategoryId}>
                      {option.ProductCategoryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="SubCategory">
                <Form.Label>Sub Category</Form.Label>

                <Form.Control
                  style={{
                    position: "relative",
                    top: "11px",
                  }}
                  as="select"
                  value={productSubCategoryId}
                  onChange={handleProductSubCategoryId}
                  required
                >
                  <option value="">Select...</option>
                  {subCategoryOptions.map((option, index) => (
                    <option key={index} value={option.ProductSubCategoryId}>
                      {option.ProductSubCategoryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Brand
                  <Button
                    variant="link"
                    onClick={handleShow1}
                    style={{
                      textDecoration: "none",
                      color: "green",
                      fontSize: "12px",
                    }}
                  >
                    ( + Add More )
                  </Button>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={productBrandId}
                  onChange={handleBrandSelectChange}
                  required
                >
                  <option value="">Select...</option>
                  {brandOptions.map((option, index) => (
                    <option key={index} value={option.BrandId}>
                      {option.BrandName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Volume
                  <Button
                    variant="link"
                    onClick={handleShow2}
                    style={{
                      textDecoration: "none",
                      color: "green",
                      fontSize: "0.8rem",
                    }}
                  >
                    ( + Add More )
                  </Button>
                </Form.Label>
                <div className="d-flex align-items-center">
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      value={productVolume}
                      onChange={handleProductVolume}
                      placeholder="Enter Volume"
                    />
                    <Form.Control
                      as="select"
                      value={productUnit}
                      onChange={handleVolumeUnitChange}
                    >
                      <option value="">CL</option>
                      {volumeUnitOptions.map((option, index) => (
                        <option key={index} value={option.ProductUnit}>
                          {option.ProductUnit}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="productPrice">
                <Form.Label
                  style={{
                    position: "relative",
                    top: "10px",
                  }}
                >
                  Price
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{
                      position: "relative",
                      top: "11px",
                    }}
                    type="number"
                    name="productPrice"
                    value={productPrice}
                    onChange={handleProductPrice}
                  />
                  <InputGroup.Text
                    style={{
                      position: "relative",
                      top: "0.7rem",
                    }}
                    id="basic-addon2"
                  >
                    AED
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group className="mb-2" controlId="productDesc">
                <Form.Label>ProductDesc</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="productDesc"
                  value={productDesc}
                  maxLength={maxCharsDesc}
                  onChange={handleProductDesc}
                />
                <>
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Maximum characters: {maxCharsDesc})
                  </Form.Text>
                  <br />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    You Have{" "}
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        padding: "2px 5px",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingChars}
                    </div>{" "}
                    Characters Left
                  </Form.Text>
                </>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Images</Form.Label>
                <Form.Control type="file" onChange={handleFileImage} />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1080px x 900px )
                </Form.Text>
              </Form.Group>
              {fileImage === null ? (
                <img
                  src={imagePreview}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              ) : (
                <img
                  src={URL.createObjectURL(fileImage)}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleImageClick()}
                />
              )}
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Icons</Form.Label>
                <Form.Control type="file" onChange={handleFileIcon} />
                <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
                  (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                  1080px x 900px )
                </Form.Text>
              </Form.Group>
              {fileIcon === null ? (
                <img
                  src={imagePreview1}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleIconClick()}
                />
              ) : (
                <img
                  src={URL.createObjectURL(fileIcon)}
                  style={{ width: "100px", height: "100px" }}
                  onClick={() => handleIconClick()}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>SKU Code</Form.Label>
                <Form.Control
                  type="text"
                  value={productSKUCode}
                  onChange={(e) => setProductSKUCode(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>SAP Code</Form.Label>
                <Form.Control
                  type="text"
                  value={productSAPCode}
                  onChange={(e) => setProductSAPCode(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tags</Form.Label>
                <Form.Control
                  type="text"
                  value={productTags}
                  onChange={handleProductTags}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row mt-2">
            <div className="row col-lg-4">
              <div className="col-lg-7">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Top Item</Form.Label>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isTopItem}
                    onChange={handleIstopChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className="row col-lg-4">
              <div className="col-lg-7">
                <Form.Group
                  className="mb-2 "
                  controlId="exampleForm.ControlInput1"
                  // style={{ marginLeft: "1.5rem" }}
                >
                  <Form.Label>Is Luxury</Form.Label>
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isLuxuryCollection}
                    onChange={handleisLuxuryCollectionChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="row col-lg-4">
              <div className="col-lg-7 ">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Exceptional </Form.Label>
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={exceptional}
                    onChange={handleExceptionalChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="discountValue">
                <Form.Label>Discount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Discount Value"
                  value={productDiscount}
                  onChange={handleDiscountValueChange}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="discountType">
                <Form.Label>Discount Type</Form.Label>
                <Form.Control
                  as="select"
                  value={productDiscountType}
                  aria-label="Select Discount Type"
                  onChange={handleDiscountTypeValueChange}
                >
                  <option value="">Select Type</option>
                  <option value="percentage">Percentage</option>
                  <option value="netAmount">flat amount</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Alcohol Percentage</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={productAlcoholPercentage}
                    onChange={handlesetProductAlcoholPercentage}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={productRegion}
                  onChange={handleProductRegion}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Product link</Form.Label>
                <Form.Control
                  type="text"
                  value={hyperLink}
                  onChange={(e) => setHyperLink(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Video Link</Form.Label>
                <Form.Control
                  type="text"
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Image Link</Form.Label>
                <Form.Control
                  type="text"
                  value={imageLink}
                  onChange={(e) => setImageLink(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tasting Notes</Form.Label>
                <Form.Control
                  type="text"
                  value={tastingNotes}
                  onChange={(e) => setTastingNotes(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span> Update Products</span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={
            fileImage === null ? imagePreview : URL.createObjectURL(fileImage)
          }
        />
        <ImageModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
          imageUrl={
            fileIcon === null ? imagePreview : URL.createObjectURL(fileIcon)
          }
        />
        <Modal
          show={showModal}
          onHide={handleClose1}
          size="lg"
          centered
          className="custom-modalproduct"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modalproduct-body">
            <Form onSubmit={handleSubmitAddBrand}>
              <Form.Group className="mb-3" controlId="newBrandName">
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter brand name"
                  // value={newBrandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </Form.Group>
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  id="btn1"
                  variant="primary"
                  disabled={brandLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {brandLoading && (
                      <ThreeDots color="#fff" height={20} width={30} />
                    )}
                    {!brandLoading && <span>Add</span>}
                  </div>
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* Volume Unit Create Modal */}
        <Modal
          show={showModal1}
          onHide={handleClose2}
          size="lg"
          centered
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Volume Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modal-body">
            <Form onSubmit={handleSubmitAddVolume}>
              <Form.Group className="mb-3" controlId="VolumeUnit">
                <Form.Label>Volume</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Unit"
                  value={productVolumeUnit}
                  onChange={(e) => setProductVolumeUnit(e.target.value)}
                />
              </Form.Group>
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  id="btn1"
                  variant="primary"
                  disabled={volumeUnitLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {volumeUnitLoading && (
                      <ThreeDots color="#fff" height={20} width={30} />
                    )}
                    {!volumeUnitLoading && <span>Add Volume Unit</span>}
                  </div>
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditProducts;
