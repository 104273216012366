import axios from "axios";
import { jwtDecode } from "jwt-decode";
import config from "../common/config";

const storeTokens = ({ accessToken, refreshToken }) => {
  console.log(accessToken);
  localStorage.setItem("token", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

const removeTokens = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(config.loginUrl, { email, password });
    storeTokens({
      accessToken: response.data.data.token,
      refreshToken: response.data.data.token,
    });
    localStorage.setItem("user", JSON.stringify(response.data.data.user));
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const logout = () => {
  removeTokens();
  localStorage.clear();
};

export const getCurrentUser = () => {
  try {
    const token = localStorage.getItem("token");
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

export const getToken = () => {
  return localStorage.getItem("token");
};
export const getUser = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};
export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const isAuthenticated = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  try {
    const { exp } = jwtDecode(token);
    if (exp < Date.now() / 1000) {
      return refreshToken()
        .then(() => true)
        .catch(() => false);
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const refreshToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) throw new Error("No refresh token available");

  const response = await axios.post(config.tokenRefresh, { refreshToken });
  if (response.data.accessToken && response.data.refreshToken) {
    storeTokens(response.data);
    return response.data.accessToken;
  } else {
    logout();
    throw new Error("Failed to refresh token");
  }
};
