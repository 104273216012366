import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { subCategoryAdd, categoryList } from "../api/apiIntegration";
import { getUser } from "../auth/authService.js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./components/image_model.js";
function OffCanvasAddSubCategorymain({ id, load, name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);
  const [productSubCategoryName, setSubCategoryName] = useState("");
  const [productSubCategoryDesc, setSubCategoryDesc] = useState("");
  const [productCategoryId, setCategoryId] = useState(id);
  const [loading, setLoading] = useState(false);
  const [fileIcon, setFileIcon] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [remainingChars, setRemainingChars] = useState(200);
  const [imagePreview, setImagePreview] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(false);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const handleFileIcon = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check for JPG format
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension !== "jpg" && fileExtension !== "jpeg") {
        toast.error("Only JPG and JPEG images are allowed");
        setFileIcon(null);
        setImagePreview(null);
        setImageSelected(false);
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width <= 1080 && img.height <= 900) {
          setFileIcon(selectedFile);
          setImagePreview(URL.createObjectURL(selectedFile));
          setImageSelected(true);
        } else {
          toast.error("Image dimensions should not exceed 1080x900 pixels");
          setFileIcon(null);
          setImagePreview(null);
          setImageSelected(false);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }
  };
  const handleDescChange = (e) => {
    const countDesc = e.target.value;
    setSubCategoryDesc(countDesc);
    setRemainingChars(200 - countDesc.length);
  };
  const handleCategorySelectChange = (event) => {
    setCategoryId(event.target.value);
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (productSubCategoryName == "") {
      toast.error("Sub Category name is required");
    } else if (productSubCategoryDesc == "") {
      toast.error("Sub Category description is required");
    } else if (fileIcon == null) {
      toast.error("Icon is required");
    } else {
      const userGet = await getUser();

      const form = new FormData();
      form.append("productSubCategoryName", productSubCategoryName);
      form.append("productSubCategoryDesc", productSubCategoryDesc);
      form.append("productCategoryId", productCategoryId);
      form.append("orderSequence", 1);
      form.append("createdBy", userGet.AdminId);
      form.append("IsEnabled", IsEnabled);
      form.append("productSubCategoriesIcon", fileIcon);
      try {
        setLoading(true);
        const response = await subCategoryAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          load();
          handleClose();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };

  useEffect(() => {
    handleInside();
    categoryListEvent();
  }, []);
  const handleInside = () => {
    setModalShow(false);
    setFileIcon(null);
    setImagePreview(null);
    setImageSelected(false);
    setSubCategoryName("");
    setSubCategoryDesc("");
    setRemainingChars(200);
    setIsEnabled(false);
  };
  const categoryListEvent = async () => {
    try {
      const response = await categoryList();
      if (response.success == true) {
        setcategoryOptions(response.data);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add More (SubCategory)</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled}
                  onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`}
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={productCategoryId}
                    onChange={handleCategorySelectChange}
                  >
                    <option value="">Select...</option>
                    {categoryOptions.map((option, index) => (
                      <option key={index} value={option.ProductCategoryId}>
                        {option.ProductCategoryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SubCategory Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => setSubCategoryName(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SubCategory Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder=""
                    onChange={handleDescChange}
                    value={productSubCategoryDesc}
                    maxLength="200"
                  />
                  <>
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      (Maximum characters: 200)
                    </Form.Text>
                    <br />
                    <Form.Text
                      className="text-muted"
                      style={{ fontSize: "10px" }}
                    >
                      You Have{" "}
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "5px",
                          padding: "2px 5px",
                          border: "1px solid #ccc",
                          borderRadius: "3px",
                          fontWeight: "bold",
                        }}
                      >
                        {remainingChars}
                      </div>{" "}
                      Characters Left
                    </Form.Text>
                  </>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="">
                  <Form.Label>SubCategory Icon</Form.Label>
                  <Form.Control
                    type="file"
                    name="icon"
                    onChange={handleFileIcon}
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                    1080px x 900px )
                  </Form.Text>
                </Form.Group>
                {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ maxWidth: "100px", height: "100px" }}
                      onClick={() => handleImageClick()}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={imagePreview === null ? "" : imagePreview}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default function AddSubCategorymain({ id, onAdd }) {
  return (
    <>
      <OffCanvasAddSubCategorymain
        key={1}
        placement={"end"}
        name={"+ Add More"}
        load={onAdd}
        id={id}
      />
    </>
  );
}
