import React, { useState } from "react";
import { login } from "../auth/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export default function Logindesign() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email == "") {
      toast.error("Email is requried");
    } else if (password == "") {
      toast.error("password is requried");
    } else {
      try {
        setLoading(true);
        const response = await login(email, password);
        if (response.success == true) {
          toast.success(response.message);
          navigate("/dashboard");
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error(
            "Login failed. Please check your credentials and try again."
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <body id="bodys">
    <div id="__layout">
      <div className="wrapper wrapper-full-page">
        <div id="top-page" className="full-page custom-background">
          <div id="barrcuda" className="col-lg-12 d-flex justify-content-end">
          
          </div>
          <div className="">
          <div className="col-lg-10 d-flex justify-content-end">
              <img
                className="bbrlogo"
                src="../images/logo1.png"
                height="150"
                alt="Logo"
              />
            </div>
            <div id="logincontainer">
              <div className="row mx-5">
                <div className="card-header text-center">
                  <h1 className="card-title"></h1>
                </div>
                <div className="col-lg-5 mt-5 ">
                  <div className="card-login d-flex justify-content-center">
                  <form className="card-white" onSubmit={handleSubmit}>
                  <div className="card-body custom-card-body">
                        <div className="row mb-3">
                          <div className="col-sm-12">
                            <label
                              htmlFor="email"
                              id="labelform"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              id="email1"
                              placeholder="Email"
                              name="email"
                              className="form-control"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-sm-12">
                            <label
                              htmlFor="password"
                              id="labelform"
                              className="form-label"
                            >
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                id="password1"
                                placeholder="Password"
                                name="password"
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <button
                                id="eye1"
                                className="btn"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                  boxShadow: "none",
                                  cursor: "pointer",
                                }}
                              >
                                {showPassword ? (
                                  <i className="bi bi-eye-slash-fill"></i>
                                ) : (
                                  <i className="bi bi-eye-fill"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row-lg-12 mb-3">
                          <div className="col-sm-6">
                            <div className="d-flex justify-content flex-wrap mb-3">
                              {/* <a
                                className="forgot-link"
                                href="/forgot-password"
                              >
                                Forgot Password?
                              </a> */}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <div className="mb-2">
                              <button
                                type="submit"
                                className="btn  btn-login  "
                                disabled={loading}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {loading && (
                                    <ThreeDots
                                      color="#fff"
                                      height={20}
                                      width={30}
                                    />
                                  )}
                                  {!loading && <span>Login</span>}
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    </body>
  );
}
