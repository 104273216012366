import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function DeleteProduct({ show, onHide, confirmDelete }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
      className="custom-modal"
    >
      <Modal.Header closeButton className="custom-modal-header">
      <Modal.Title id="contained-modal-title-vcenter">
          Delete Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <p>Are you sure you want to delete this product?</p>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button variant="secondary" onClick={onHide} className="custom-cancel-button">
          Cancel
        </Button>
        <Button variant="danger" onClick={confirmDelete} className="custom-delete-button">
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteProduct;
