import React, { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import {
  top10NationalityChart,
  TopSpenderWeekendChart,
} from "../../api/apiIntegration";
import LoaderOwn from "../../common/loaderOwn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const colors = [
  "#FF5733", // Red
  "#33FF57", // Green
  "#3357FF", // Blue
  "#FF33A1", // Pink
  "#FF8333", // Orange
  "#33FFD5", // Aqua
  "#A533FF", // Purple
  "#FFE933", // Yellow
  "#FF3333", // Bright Red
  "#33FF8D", // Light Green
];
const Top10NationalityPieChart = () => {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("current_month");

  useEffect(() => {
    fetchData(currentTab);
  }, [currentTab]);

  const fetchData = async (tab) => {
    setIsLoading(true);
    let response;

    try {
      if (tab === "current_month") {
        response = await top10NationalityChart({ period: "current_month" });
        if (response.success) {
          const data = processCurrentMonthData(response.data);
          setSeries(data);
        }
      } else if (tab === "weekend") {
        response = await TopSpenderWeekendChart();
        if (response.success) {
          const data = processWeekendData(response.data);
          setSeries(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const processCurrentMonthData = (dataGet) => {
    return dataGet.map((item, index) => {
      const lastMonthAmount = item.LastMonthAmount;
      const totalAmount = item.TotalAmount;
      let percentageChange = null;
      let percentageChangeText = "";

      if (lastMonthAmount && lastMonthAmount !== 0) {
        percentageChange = (
          ((totalAmount - lastMonthAmount) / lastMonthAmount) *
          100
        ).toFixed(2);

        if (!isNaN(percentageChange) && percentageChange !== "Infinity") {
          const icon =
            percentageChange >= 0 ? "images/arrow.png" : "images/down.png";

          percentageChangeText = `<img src="${icon}" alt="${
            percentageChange >= 0 ? "Increase" : "Decrease"
          } Icon" style="width:16px;height:16px;margin-right:4px;"/>${
            percentageChange >= 0 ? "+" : ""
          }${percentageChange}%<br>Last month`;
        }
      }

      return {
        name: `${item.Nationality} (Current month: $${totalAmount}) ${percentageChangeText}`,
        y: lastMonthAmount,
        color: colors[index % colors.length],
      };
    });
  };

  const processWeekendData = (dataGet) => {
    const previousWeekendData = dataGet.previousWeekend;
    const weekendBeforeData = dataGet.weekendBefore;

    const previousWeekendMap = new Map(
      previousWeekendData.map((item) => [item.Nationality, item.TotalAmount])
    );

    return weekendBeforeData.map((item, index) => {
      const totalAmount = item.TotalAmount;
      const lastAmount = previousWeekendMap.get(item.Nationality) || 0;
      let percentageChange = null;
      let percentageChangeText = "";

      if (lastAmount && lastAmount !== 0) {
        percentageChange = (
          ((totalAmount - lastAmount) / lastAmount) *
          100
        ).toFixed(2);

        if (!isNaN(percentageChange) && percentageChange !== "Infinity") {
          const icon =
            percentageChange >= 0 ? "images/arrow.png" : "images/down.png";

          percentageChangeText = `<img src="${icon}" alt="${
            percentageChange >= 0 ? "Increase" : "Decrease"
          } Icon" style="width:16px;height:16px;margin-right:4px;"/>${
            percentageChange >= 0 ? "+" : ""
          }${percentageChange}%`;
        }
      }

      return {
        name: `${item.Nationality} (Previous Weekend: $${lastAmount}) ${percentageChangeText}`,
        y: totalAmount,
        color: colors[index % colors.length],
      };
    });
  };

  const options = {
    chart: {
      type: "pie",
      toolbar: {
        show: true,
        tools: {
          download: true, // Enable the download button
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        return opts.w.config.labels[opts.dataPointIndex];
      },
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    legend: { show: false, position: "bottom" },
    labels: series.map((item) => item.name),
  };
  const CustomLegend = ({ series }) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {series.map((item, index) => (
          <div
            key={index}
            style={{
              margin: "5px 10px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                width: "16px",
                height: "16px",
                marginRight: "8px",
                backgroundColor: item.color,
                display: "inline-block",
              }}
            ></span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "1",
              }}
              dangerouslySetInnerHTML={{
                __html: item.name.replace("Last month", ""),
              }}
            ></span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="col-lg-6">
      <div className="card card-chart">
        <div className="card-header">
          <h5 className="card-category">Top 5 spenders</h5>
        </div>
        <div className="card-body">
          <div className="tab_pad customs_tab">
            <Tabs
              className="pos-abs"
              activeKey={currentTab}
              onSelect={(k) => setCurrentTab(k)}
            >
              <Tab eventKey="current_month" title="Current Month vs Last Month">
                {isLoading ? (
                  <LoaderOwn />
                ) : (
                  <div>
                    <ApexCharts
                      options={options}
                      series={series.map((item) => item.y)}
                      type="pie"
                      height="480"
                    />
                    <CustomLegend series={series} />
                  </div>
                )}
              </Tab>
              <Tab eventKey="weekend" title="Weekend">
                {isLoading ? (
                  <LoaderOwn />
                ) : (
                  <div>
                    <ApexCharts
                      options={options}
                      series={series.map((item) => item.y)}
                      type="pie"
                      height="480"
                    />
                    <CustomLegend series={series} />
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top10NationalityPieChart;
