// import { competitionsList, deleteBrand } from "../api/apiIntegration";
// import PushNotifications from "../pages/pushnotifications";

const baseUrl = "https://bbr-api.billsfox.com/api/";
const localUrl = "http://localhost:3001/api/";
const config = {
  loginUrl: `${baseUrl}admin/login`,
  tokenRefresh: `${baseUrl}refresh`,
  userListUrl: `${baseUrl}user/list`,
  userListFilterUrl: `${baseUrl}user/filter`,
  NotificationuserListFilterUrl: `${baseUrl}user/notificatins-user-filter`,
  categoriesListUrl: `${baseUrl}categories/list`,
  categoriesEditUrl: `${baseUrl}categories/edit`,
  categoriesAddUrl: `${baseUrl}categories/add`,
  SubcategoriesListUrl: `${baseUrl}subCategories/list`,
  SubcategoriesEditUrl: `${baseUrl}subCategories/edit`,
  SubcategoriesAddUrl: `${baseUrl}subCategories/add`,
  ProductListUrl: `${baseUrl}product/list`,
  volumeUnitListUrl: `${baseUrl}product/volumeunit-list`,
  volumeUnitAddUrl: `${baseUrl}product/volumecreate`,
  ProductDropDownListUrl: `${baseUrl}product/productdropdown`,
  ProducttTopItemCountUrl: `${baseUrl}product/top-item-count`,
  ProductEditUrl: `${baseUrl}product/edit`,
  ProductAddUrl: `${baseUrl}product/add`,
  ProductFilterUrl: `${baseUrl}product/filter`,
  brandListUrl: `${baseUrl}brand/list`,
  brandEditUrl: `${baseUrl}brand/edit`,
  brandAddUrl: `${baseUrl}brand/add`,
  orderListUrl: `${baseUrl}order/list`,
  orderFilterUrl: `${baseUrl}order/filter`,
  userListChartUrl: `${baseUrl}user/chart`,
  userListChartCurrentVsPreviousUrl: `${baseUrl}user/currentVsprevious`,
  userCountChartUrl: `${baseUrl}user/userCount-chart`,
  Top10BrandUrl: `${baseUrl}brand/orderChart-currentmonth`,
  Top10CategoryUrl: `${baseUrl}categories/orderChart-currentmonth`,
  Top10SubCategoryUrl: `${baseUrl}subCategories/orderChart-currentmonth`,
  Top10NationalityUrl: `${baseUrl}user/topSpenders-chart`,
  deleteBrandUrl: `${baseUrl}`,
  deleteProductUrl: `${baseUrl}  `,
  deleteCategoryUrl: `${baseUrl}  `,
  deleteSubCategoryUrl: `${baseUrl}  `,
  competitionsListUrl: `${baseUrl}competition/list`,
  competitionsDetailsUrl: `${baseUrl}competition/details`,
  competitionsEditUrl: `${baseUrl}competition/edit`,
  competitionAddUrl: `${baseUrl}competition/add`,
  updateCompetitionStatusUrl: `${baseUrl}competition/status`,
  deleteCompetitionUrl: `${baseUrl}  `,
  updateBrandStatusUrl: `${baseUrl}brand/status`,
  updateUserStatusUrl: `${baseUrl}user/status`,
  updateOrderStatusUrl: `${baseUrl}order/status`,
  updateProductStatusUrl: `${baseUrl}product/status`,
  updateCategoryStatusUrl: `${baseUrl}categories/status`,
  updateSubCategoryStatusUrl: `${baseUrl}subCategories/status`,
  orderCategoryChartUrl: `${baseUrl}order/order-category-chart`,
  bannerAddUrl: `${baseUrl}banner/add`,
  bannerEditUrl: `${baseUrl}banner/edit`,
  bannerListUrl: `${baseUrl}banner/list`,
  updateBannerStatusUrl: `${baseUrl}banner/status`,
  WishlistListUrl: `${baseUrl}wishList/list`,
  wishlistFilterUrl: `${baseUrl}wishList/filter`,
  userdetailsUrl: `${baseUrl}user/details`,
  competitionEntriesListUrl: `${baseUrl}competition/competitionEntriesList`,
  competitionWinnerListUrl: `${baseUrl}competition/competitionWinnerEntriesList`,
  userTransactionsUrl: `${baseUrl}user/transaction`,
  expiringPointsUrl: `${baseUrl}user/expiringPoint`,
  TotalEarnedPointstUrl: `${baseUrl}user/earnedPoints`,
  PushNotificationsListUrl: `${baseUrl}notification/list`,
  PushNotificationAddUrl: `${baseUrl}notification/add`,
  PushNotificationFilterUrl: `${baseUrl}notification/filter`,
  EmailNotificationAddUrl: `${baseUrl}emailnotification/add`,
  EmailNotificationListUrl: `${baseUrl}emailnotification/list`,
  EmailNotificationFilterUrl: `${baseUrl}emailnotification/filter`,
  SmsNotificationListUrl: `${baseUrl}smsnotification/list`,
  SmsNotificationAddUrl: `${baseUrl}smsnotification/add`,
  SMSNotificationFilterUrl: `${baseUrl}smsnotification/filter`,
  WhatsappNotificationListUrl: `${baseUrl}whatsappnotification/list`,
  WhatsappNotificationAddUrl: `${baseUrl}whatsappnotification/add`,
  WhatsappNotificationFilterUrl: `${baseUrl}whatsappnotification/filter`,
  unRedeemdedChartUrl: `${baseUrl}earnedpoint/unRedeemed-chart`,
  top5CategoryChartUrl: `${baseUrl}categories/orderChart`,
  top5SubcategoriesChartUrl: `${baseUrl}subCategories/orderChart`,
  top5BrandChartUrl: `${baseUrl}brand/orderChart`,
  top5NationalityChartUrl: `${baseUrl}user/userNationality-chart`,
  expiryPointsChartUrl: `${baseUrl}earnedpoint/expired-points`,
  RedeemedPointsChartUrl: `${baseUrl}earnedpoint/redeemed-points`,
  orderTransactionChartUrl: `${baseUrl}order/order-transaction-chart`,
  expiryPointsNext3MonthsChartUrl: `${baseUrl}earnedpoint/expired-points-next3months`,
  expiryPointsCurrentMonthChartUrl: `${baseUrl}earnedpoint/expired-points-currentmonths`,
  SMTPSettingsUrl: `${baseUrl}smtpsettings/edit`,
  SMTPSettingsListUrl: `${baseUrl}smtpsettings/list`,
  SendMailUrl: `${baseUrl}smtpsettings/sendmail`,
  AddNotificationAddUrl: `${baseUrl}emailnotification/addnotification`,
  EmailTemplateListUrl: `${baseUrl}email-templates/list`,
  EmailTemplateEditUrl: `${baseUrl}email-templates/edit`,
  topSpenderWeekendChartUrl: `${baseUrl}user/topSpenders-weekend`,
  topSpenderMonthChartUrl: `${baseUrl}user/topSpenders-chart`,
  topBrandMonthChartUrl: `${baseUrl}brand/brand-monthchart`,
  topBrandWeekendChartUrl: `${baseUrl}brand/brand-weekchart`,
  orderYearVsLastYearChartUrl: `${baseUrl}order/year-date-last-year`,
  productUploadXlUrl: `${baseUrl}product/uploadXl`,
  productsampleXlUrl: `${baseUrl}product/sampleXl`,
};

export default config;
