import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";

import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  onRowClick,
  Position,
} from "devextreme-react/data-grid";
import AddCategorymain from "./addCatrgory.js";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import EditCategory from "./EditCategory.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  categoryList,
  deleteCategory,
  updateCategoryStatus,
} from "../api/apiIntegration.js";
import LoaderOwn from "../common/loaderOwn.js";
import DeleteCategory from "./DeleteCategory.js";
import { getUser } from "../auth/authService.js";

export default function Category() {
  const navigate = useNavigate();

  const handleRowClick = (e) => {
    navigate(`/subcategory/${e.data.ProductCategoryId}`);
  };

  const [showHeaderFilter] = useState(true);
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryToDelete, setcategoryToDelete] = useState(null);
  useEffect(() => {
    list();
  }, []);
  const handleClose = () => {
    setSelectedCategory(null);
    setShowEditOffcanvas(false);
  };
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await categoryList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleEdit = (Category) => {
    setSelectedCategory(Category);
    setShowEditOffcanvas(true);
  };

  const handleUpdate = (updatedCategory) => {
    setData((prevData) =>
      prevData.map((Category) =>
        data.ProductCategoryId === updatedCategory.ProductCategoryId
          ? updatedCategory
          : Category
      )
    );
  };
  const handleDelete = (Category) => {
    setcategoryToDelete(Category);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteCategory(categoryToDelete.ProductCategoryId);
      setData((prevData) =>
        prevData.filter(
          (Category) =>
            Category.ProductCategoryId !== categoryToDelete.ProductCategoryId
        )
      );
      toast.success("Category deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete Category.");
    } finally {
      setShowDeleteModal(false);
      setcategoryToDelete(null);
    }
  };

  const handleStatusToggle = async (Category, newStatus) => {
    const userGet = await getUser();
    var form = {
      id: Category.ProductCategoryId,
      status: newStatus,
      userId: userGet.AdminId,
    };
    try {
      const response = await updateCategoryStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy hh:mm a");
  };
  const handleError = (event) => {
    event.target.src = "../images/no_image.png";
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Categories</h4>
                    <div className="ms-auto right_btn">
                      <AddCategorymain onAdd={list} />
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <DataGrid
                        id="gridContainer"
                        dataSource={data}
                        keyExpr="ProductCategoryId"
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={false}
                        showBorders={true}
                        width="100%"
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        onRowClick={handleRowClick}
                      >
                        <HeaderFilter visible={showHeaderFilter} />

                        <Paging defaultPageSize={8} />
                        <Pager visible={true} />

                        <SearchPanel
                          visible={true}
                          highlightCaseSensitive={true}
                          placeholder="Search Categories..."
                        />

                        <Grouping autoExpandAll={false} />
                        <Column
                          dataField="ProductCategoryIcon"
                          caption="Category Icon"
                          width={200}
                          cellRender={({ data }) =>
                            data.ProductCategoryIcon ? (
                              <img
                                src={data.ProductCategoryIcon}
                                alt="ProductCategoryIcon"
                                style={{ maxWidth: "70%", height: "auto" }}
                                onError={handleError}
                              />
                            ) : (
                              <img
                                src="./images/no_image.png"
                                alt="ProductCategoryIcon"
                                style={{ maxWidth: "20%", height: "15%" }}
                              />
                            )
                          }
                        />

                        <Column
                          dataField="ProductCategoryName"
                          width={180}
                          caption="Category Name"
                        />

                        <Column
                          dataField="ProductCategoryDesc"
                          width={250}
                          caption="Category Description
"
                          cellRender={({ data }) => (
                            <div style={{ whiteSpace: "normal" }}>
                              {data.ProductCategoryDesc}
                            </div>
                          )}
                        />

                        <Column
                          width={250}
                          dataField="LastEditedDate"
                          caption="Last Edited Date"
                          calculateCellValue={({ LastEditedDate }) =>
                            formatDate(LastEditedDate)
                          }
                        />
                        <Column
                          alignment="center"
                          caption="Status"
                          width={250}
                          cellRender={({ data }) => (
                            <button
                              id="active_deactive"
                              className={`btn btn-${
                                data.IsEnabled === true ? "success " : "danger "
                              }`}
                              onClick={() => handleStatusToggle(data)}
                            >
                              {data.IsEnabled === true ? "Active" : "Deactive"}
                            </button>
                          )}
                        />
                        <Column
                          width={150}
                          cellRender={({ data }) => (
                            <Actions
                              data={data}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                              onToggleStatus={handleStatusToggle}
                            />
                          )}
                        />
                      </DataGrid>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>

      {selectedCategory && (
        <EditCategory
          onAdd={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          categoryData={selectedCategory}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
      <DeleteCategory
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}

export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />{" "}
              <span>Delete</span>
            </a>
          </li>
          {IsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
