import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { getUser } from "../auth/authService.js";
import { bannerAdd } from "../api/apiIntegration.js";
import { ThreeDots } from "react-loader-spinner";
import ImageModal from "./components/image_model.js";

function OffCanvasAddBanner({ loadOwn, name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    handleInside();
  };
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [bannertitle, setbannertitle] = useState("");
  const [bannerDescription, setbannerDescription] = useState("");
  const [bannerexternalWebLink, setbannerexternalWebLink] = useState("");
  const [bannerImage, setbannerImage] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [IsEnabled, setIsEnabled] = useState(false);

  const handlebannerImage = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg" &&
        fileExtension !== "png"
      ) {
        toast.error("Only JPG & PNG & JPEG images are allowed");
        setbannerImage(null);
        setImageSelected(false);
        setImagePreview(null);
        return;
      } 
      const maxSize = 150 * 1024; // 150KB
      if (selectedFile.size > maxSize) {
        toast.error("File size exceeds 150KB.");
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width === 1080 && img.height === 900) {
          setbannerImage(selectedFile);
          setImageSelected(true);
          setImagePreview(URL.createObjectURL(selectedFile));
        } else {
          toast.error("Image dimensions should not exceed 1200x800 pixels");
          setbannerImage(null);
          setImageSelected(false);
          setImagePreview(null);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    }

    // if (selectedFile) {
    //   const img = new Image();
    //   img.onload = () => {
    //     if (img.width <= 1200 && img.height <= 800) {
    //       setbannerImage(selectedFile);
    //       setImageSelected(true);
    //     } else {
    //       toast.error("Image dimensions should not exceed 1200x800 pixels");
    //       setbannerImage(null);
    //       setImageSelected(false);
    //     }
    //   };
    //   img.src = URL.createObjectURL(selectedFile);
    // }
  };
  useEffect(() => {
    handleInside();
  }, []);
  const handleInside = () => {
    setbannertitle("");
    setbannerDescription("");
    setbannerexternalWebLink("");
    setbannerImage(null);
    setImageSelected(false);
    setImagePreview(null);
    setModalShow(false);
    setIsEnabled(false);
  };

  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (bannertitle == "") {
      toast.error("bannertitle name is required");
    } else if (bannerDescription == "") {
      toast.error("bannerDescription is required");
    } else if (bannerexternalWebLink == "") {
      toast.error("bannerexternalWebLink is required");
    } else if (!bannerImage == null) {
      toast.error("bannerImage is required");
    } else {
      const userGet = await getUser();

      const form = new FormData();
      form.append("bannertitle", bannertitle);
      form.append("bannerDescription", bannerDescription);
      form.append("bannerexternalWebLink", bannerexternalWebLink);
      form.append("createdBy", userGet.AdminId);
      form.append("bannerImage", bannerImage);
      form.append("IsEnabled", IsEnabled);

      try {
        setLoading(true);
        const response = await bannerAdd(form);
        if (response.success == true) {
          toast.success(response.message);
          loadOwn();
          handleClose();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add More Banners</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="col-lg-11 d-flex justify-content-end">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={IsEnabled}
                  onChange={handleSwitchChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`ms-3 fw-bold ${
                  IsEnabled ? "text-dark" : "text-danger"
                }`}
              >
                {IsEnabled ? "Active" : "Inactive"}{" "}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title:</Form.Label>
                  <Form.Control
                    type="Text"
                    name="title"
                    onChange={(e) => setbannertitle(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description:</Form.Label>
                  <Form.Control
                    type="Text"
                    name="Text"
                    onChange={(e) => setbannerDescription(e.target.value)}
                    as="textarea"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>External web link:</Form.Label>
                  <Form.Control
                    type="Text"
                    onChange={(e) => setbannerexternalWebLink(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex justify-content-between w-100">
                    Banner Image
                  </Form.Label>
                  <Form.Control type="file" onChange={handlebannerImage} />
                  <Form.Text
                    className="text-muted"
                    style={{ fontSize: "10px" }}
                  >
                    (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension
                    1080px x 900px )
                  </Form.Text>
                </Form.Group>
                {imagePreview !== null && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ maxWidth: "100px", height: "100px" }}
                      onClick={() => handleImageClick()}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="text-center mt-4">
              <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading && <ThreeDots color="#fff" height={20} width={30} />}
                  {!loading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
          <ImageModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            imageUrl={imagePreview === null ? "" : imagePreview}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddBanner({ onAdd }) {
  return (
    <>
      <OffCanvasAddBanner
        loadOwn={onAdd}
        key={1}
        placement={"end"}
        name={"+ Add More"}
      />
    </>
  );
}
