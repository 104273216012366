import React, { useEffect, useState, useCallback } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { toast, ToastContainer } from "react-toastify";
import { format } from "date-fns";
import LoaderOwn from "../common/loaderOwn.js";
import Filter from "./components/filter";

import DataGrid, {
  Column,
  Grouping,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import AddEmailNotificationmain from "./addEmailnotifications";
import { EmailNotificationsList ,EmailNotificationstFillter} from "../api/apiIntegration.js";

export default function EmailNotifications() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showHeaderFilter] = useState(true);

  useEffect(() => {
    list();
  }, []);

  const list = async () => {
    try {
      setIsLoading(true);
      const response = await EmailNotificationsList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilterApply = async (filterData) => {
    console.log("Filter Data:", filterData);
    try {
      setIsLoading(true);
      const response = await EmailNotificationstFillter(filterData);
      if (response.success == true) {
        setData(response.data);
      } else {
        toast.error("Failed to apply filters");
      }
    } catch (error) {
      toast.error("An error occurred while filtering products");
    } finally {
      setIsLoading(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy ");
  };
  const getStatusLabel = (DeliveryType) => {
    switch (DeliveryType) {
      case 1:
        return "Immediate";
      case 2:
        return "Delayed";
      default:
        return "";
    }
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">
                      Email Notifications list
                    </h4>
                    <div className="ms-auto right_btn d-flex justify-content-end">
                    {!isLoading && (
                        <Filter
                          onAdd={list}
                          onApply={handleFilterApply}
                          status={"Notifications"}
                        />
                      )}
                      <AddEmailNotificationmain onAdd={list} />
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                  {isLoading ? (
                      <LoaderOwn />
                    ) : (
                    <DataGrid
                      id="gridContainer"
                      dataSource={data}
                      keyExpr="NotificationID"
                      columnAutoWidth={true}
                      showBorders={true}
                      width="100%"
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                    >
                      <HeaderFilter visible={showHeaderFilter} />
                      <Paging defaultPageSize={8} />
                      <Pager visible={true} />

                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        placeholder="Search Notifications..."
                      />

                      <Grouping autoExpandAll={false} />
                      <Column
                        dataField="Subject"
                        width={350}
                        cellRender={({ data }) => (
                          <div
                            style={{ whiteSpace: "normal", maxWidth: "100%" }}
                          >
                            {data.Subject}
                          </div>
                        )}
                        caption="Subject"
                      />
                      <Column
                        dataField="Body"
                        width={350}
                        caption="Message"
                        cellRender={({ data }) => (
                          <div
                            style={{ whiteSpace: "normal", maxWidth: "100%" }}
                          >
                            {data.Body}
                          </div>
                        )}
                      />
                     <Column
                          dataField="DeliveryType"
                          alignment="center"
                          width={200}
                          calculateCellValue={({ DeliveryType }) =>
                            getStatusLabel(DeliveryType)
                          }
                        />
                      <Column
                        dataField="FilterType"
                        caption="Audience Type"
                        alignment="center"
                      />
                      {/* <Column
                        dataField="audienceTypeValue"
                        caption="Audience Type Value"
                      /> */}
                      <Column
                        alignment="center"
                        dataField="CreatedDate"
                        caption="Date Created"
                        dataType="date"
                      />
                    </DataGrid>
                     )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
}
