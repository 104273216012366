import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { orderCategoryChart } from "../../api/apiIntegration";
import LoaderOwn from "../../common/loaderOwn";

const generateXAxisLabels = (type) => {
  const labels = [];
  const today = new Date();

  if (type === "lastYear") {
    const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const month = currentDate.toLocaleString("default", { month: "short" });
      const year = currentDate.getFullYear();
      labels.push(`${month} ${year}`);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  }

  return labels;
};

const updateSeries = (data, labels) => {
  const categoryMap = new Map();

  data.forEach((item) => {
    item.data.forEach((entry) => {
      if (!categoryMap.has(entry.categoryName)) {
        categoryMap.set(entry.categoryName, new Array(labels.length).fill(0));
      }
    });
  });

  data.forEach((item) => {
    const monthLabel = new Date(item.month).toLocaleDateString("default", {
      year: "numeric",
      month: "short",
    });
    const index = labels.indexOf(monthLabel);

    item.data.forEach((entry) => {
      if (categoryMap.has(entry.categoryName)) {
        const categoryData = categoryMap.get(entry.categoryName);
        if (index >= 0 && index < categoryData.length) {
          categoryData[index] = entry.amount;
        }
        categoryMap.set(entry.categoryName, categoryData);
      }
    });
  });

  const series = Array.from(categoryMap, ([name, data]) => ({
    name,
    data,
  }));

  const seriesWithChanges = series.map(({ name, data }) => {
    const percentageChanges = data.map((value, index) => {
      if (index === 0) return 0;
      const previousValue = data[index - 1];
      if (previousValue === 0) return 0;
      return ((value - previousValue) / previousValue) * 100;
    });

    return {
      name,
      data,
      percentageChanges,
    };
  });

  return seriesWithChanges;
};

const getChartOptions = (chartType, labels, seriesWithChanges) => ({
  chart: {
    height: 450,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
    type: chartType || "bar",
  },
  plotOptions: {
    bar: {
      columnWidth: "10%",
      distributed: false,
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
    formatter: (value, { seriesIndex, dataPointIndex }) => {
      const seriesData = seriesWithChanges[seriesIndex];
      const percentageChange = seriesData.percentageChanges[dataPointIndex];
      const icon = percentageChange > 0 ? "▲" : "▼";
      return `${value} (${icon} ${percentageChange.toFixed(2)}%)`;
    },
    style: {
      colors: ["#000"],
      fontSize: "12px",
    },
    offsetY: -10,
  },
  grid: {
    show: true,
  },
  xaxis: {
    categories: labels,
    title: {
      text: "Month", // Label for y-axis
      style: {
        colors: ["#ba54f5"],
        fontWeight:"600",
        fontSize: "15px",
        fontFamily:"outfit,sans-serif" // Customize the font size if needed
      },
      offsetX: -10,
    },
    tickPlacement: "on",
  },
  yaxis: {
  
    title: {
      text: "Sales Amount", // Label for y-axis
      style: {
        colors: ["#ba54f5"],
        fontWeight:"600",
        fontSize: "15px",
        fontFamily:"outfit,sans-serif" // Customize the font size if needed
      },
      offsetX: -10,
    },

  },
  legend: {
    show: true,
    position: "bottom",
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    shared: false,
    intersect: true,
    y: {
      formatter: (value, { seriesIndex, dataPointIndex }) => {
        const seriesData = seriesWithChanges[seriesIndex];
        if (!seriesData || !seriesData.percentageChanges) return value;

        const percentageChange = seriesData.percentageChanges[dataPointIndex];
        const icon =
          percentageChange > 0
            ? '<img src="images/up.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>'
            : '<img src="images/down.png" alt="Decreased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
        return `${value} (${percentageChange.toFixed(2)}% ${icon})`;
      },
    },
  },
});

const ValueSalesWeekly = () => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(getChartOptions("bar", [], []));
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("bar");

  useEffect(() => {
    const fetchMonthData = async () => {
      try {
        setIsLoading(true);
        const response = await orderCategoryChart({ status: "lastweek" });

        if (response.success) {
          const dataGet = response.data;
          const labels = generateXAxisLabels("lastYear");
          const seriesWithChanges = updateSeries(dataGet, labels);

          setSeries(seriesWithChanges);
          setOptions(getChartOptions(chartType, labels, seriesWithChanges));
        } else {
          console.error("Failed to fetch data:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMonthData();
  }, [chartType]);

  if (isLoading) {
    return <LoaderOwn />;
  }

  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type={chartType}
        height={450}
      />
    </div>
  );
};

export default ValueSalesWeekly;
