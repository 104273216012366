import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { SmsAddNotification } from "../api/apiIntegration";
import { toast } from "react-toastify";
import { getUser } from "../auth/authService.js";
import DatePicker from "react-datepicker";

function OffCanvasAddNotificationmain({ name, load, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    message: "",
    deliveryType: "",
    filterType: "",
    notificationType: "0",
    isAlertOn: true,
    gender: "",
    fromBirthdate: null,
    toBirthdate: null,
    nationality: "",
    fromAge: "",
    toAge: "",
  });
  const handleDateChange = (date, type) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]:
        type === "checkbox"
          ? checked
          : id === "filterType"
          ? parseInt(value, 10)
          : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.message === "") {
      toast.error("Message is required");
      return;
    }
    if (formData.deliveryType === "") {
      toast.error("Delivery Type is required");
      return;
    }
    if (formData.filterType === "") {
      toast.error("Filter Type is required");
      return;
    }
    if (formData.notificationType === "") {
      toast.error("Notification Type is required");
      return;
    }
    setLoading(true);
    const userGet = await getUser();

    const form = {
      message: formData.message,
      deliveryType: formData.deliveryType,
      filterType: formData.filterType,
      createdBy: userGet.AdminId,
      notificationType: formData.notificationType,
      isAlertOn: formData.isAlertOn,
      gender: formData.filterType === 2 ? formData.gender : null,
      fromBirthdate:
        formData.filterType === 3 ? formatDate(formData.fromBirthdate) : null,
      toBirthdate:
        formData.filterType === 3 ? formatDate(formData.toBirthdate) : null,
      nationality: formData.filterType === 4 ? formData.nationality : null,
      fromAge: formData.filterType === 5 ? formData.fromAge : null,
      toAge: formData.filterType === 5 ? formData.toAge : null,
    };

    try {
      const response = await SmsAddNotification(form);
      if (response.success) {
        toast.success(response.message);
        handleClose();
        load();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("Failed to add notification: An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <Button id="btn" size="sm" onClick={handleShow}>
        {name}
      </Button>
      <Offcanvas id="offcanva" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add To Email Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="deliveryType">
                  <Form.Label>Delivery Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={formData.deliveryType}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="filterType">
                  <Form.Label>Filter Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.filterType}
                    onChange={handleChange}
                  >
                    <option value={0}>Select...</option>
                    <option value={1}>All</option>
                    <option value={2}>Gender</option>
                    <option value={3}>Date of Birth</option>
                    <option value={4}>Nationality</option>
                    <option value={5}>Age Bracket</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            {/* Conditionally render gender dropdown if "Gender" is selected */}
            {formData.filterType === 2 && (
              <div className="row">
                <div className="col-lg-12">
                  <Form.Group className="mb-2" controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      value={formData.gender}
                      onChange={handleChange}
                      id="gender"
                    >
                      <option value="">Select...</option>
                      <option value="0">Female</option>
                      <option value="1">Male</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            )}

            {/* Conditionally render date pickers if "Date of Birth" is selected */}
            {formData.filterType === 3 && (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group className="mb-2" controlId="fromBirthdate">
                      <Form.Label>From Date Of Birth</Form.Label>
                      <DatePicker
                        selected={formData.fromBirthdate}
                        onChange={(date) =>
                          handleDateChange(date, "fromBirthdate")
                        }
                        className="form-control"
                        dateFormat="MM/dd/yyyy"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-2" controlId="toBirthdate">
                      <Form.Label>To Date Of Birth</Form.Label>
                      <DatePicker
                        selected={formData.toBirthdate}
                        onChange={(date) =>
                          handleDateChange(date, "toBirthdate")
                        }
                        className="form-control"
                        dateFormat="MM/dd/yyyy"
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            )}

            {/* Conditionally render nationality dropdown if "Nationality" is selected */}
            {formData.filterType === 4 && (
              <div className="row">
                <div className="col-lg-12">
                  <Form.Group className="mb-2" controlId="nationality">
                    <Form.Label>Nationality</Form.Label>
                    <Form.Control
                      as="select"
                      value={formData.nationality}
                      onChange={handleChange}
                      id="nationality"
                    >
                      <option value="">Select...</option>
                      {/* Add nationality options here */}
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            )}

            {/* Conditionally render age inputs if "Age Bracket" is selected */}
            {formData.filterType === 5 && (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group className="mb-2" controlId="fromAge">
                      <Form.Label>From Age</Form.Label>
                      <Form.Control
                        type="number"
                        value={formData.fromAge}
                        onChange={handleChange}
                        id="fromAge"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-2" controlId="toAge">
                      <Form.Label>To Age</Form.Label>
                      <Form.Control
                        type="number"
                        value={formData.toAge}
                        onChange={handleChange}
                        id="toAge"
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="notificationType">
                  <Form.Label>Notification Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={formData.notificationType}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Form.Group className="mb-2" controlId="isAlertOn">
                  <Form.Check
                    type="checkbox"
                    label="Is Alert On"
                    checked={formData.isAlertOn}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="text-center mt-4">
            <Button
                id="btn1"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Sending..." : "SEND"}
              </Button>
        
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddEmailNotificationmain({ onAdd }) {
  return (
    <>
      <OffCanvasAddNotificationmain
        key={1}
        placement={"end"}
        name={"ADD EMAIL NOTIFICATION"}
        load={onAdd}
      />
    </>
  );
}
