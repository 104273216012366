import React, { useCallback, useState } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { useNavigate } from "react-router-dom";
import { logout } from "../auth/authService";
export default function Logout() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logout();
    navigate("/");
  };
  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <div className="logout-container">
        <div className="logout-card">
          <h2>Logout</h2>
          <p>Are you sure you want to logout?</p>
          <div className="buttons">
            <button onClick={handleLogout}>Logout</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}
