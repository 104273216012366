import React from 'react';

const DataRow = (rowInfo) => (
  <React.Fragment>
    <tr className="main-row">
      <td>{rowInfo.data.ProductName}</td>
      <td>{rowInfo.data.Noofwishlist}</td>
      <td>{rowInfo.data.Currentstock}</td>
      <td>{rowInfo.data.LastSoldAt}</td>
      <td>{rowInfo.data.Actions}</td>

    </tr>
  </React.Fragment>

);
export default DataRow;
