import Chart from "react-apexcharts";
import axios from "axios";
import config from "../../common/config";
import PropTypes from "prop-types";
import _ from "lodash";

import React, { useEffect, useState, Component, useRef } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  subDays,parse,isValid
} from "date-fns";
import { userTotalChart } from "../../api/apiIntegration.js";
export class UserBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: {
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Active Users", "Dormant Users", "Lapsed Users"],
        },
        title: {
          text: "",
          align: "center",
        },
        colors: ["#004169"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " users";
            },
          },
        },
      },
    };
  }

  render() {
    const { data } = this.props;

    // Convert the data object to an array format expected by ApexCharts
    const chartSeries = [
      {
        name: "User Count",
        data: [
          data.activeUserCount,
          data.dormantUserCount,
          data.lapsedUserCount,
        ],
      },
    ];

    return (
      <Chart
        options={this.state.chartOptions}
        series={chartSeries}
        type="bar"
        height={380}
      />
    );
  }
}

export class UserActivityRangeBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 400,
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: (val, opts) => {
            const label = opts.w.globals.labels[opts.dataPointIndex];
            const startDate = new Date(val[0]);
            const endDate = new Date(val[1]);
            const diff = Math.ceil(
              (endDate - startDate) / (1000 * 60 * 60 * 24)
            );
            return `${label}: ${diff} ${diff > 1 ? "days" : "day"}`;
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM yyyy",
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          row: {
            colors: ["#f3f4f5", "#fff"],
            opacity: 1,
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      },
    };
  }

  componentDidMount() {
    this.processData(this.props.apiResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiResponse !== prevProps.apiResponse) {
      this.processData(this.props.apiResponse);
    }
  }

  processData(data) {
    if (!data) {
      console.error("No data provided");
      return;
    }

    const series = [
      {
        name: "Active Users",
        data: [
          {
            x: "Active Users",
            y: [
              new Date(data.activeUserCount.dateRange.start).getTime(),
              new Date(data.activeUserCount.dateRange.end).getTime(),
            ],
            fillColor: "#008FFB",
          },
        ],
      },
      {
        name: "Dormant Users",
        data: [
          {
            x: "Dormant Users",
            y: [
              new Date(data.dormantUserCount.dateRange.start).getTime(),
              new Date(data.dormantUserCount.dateRange.end).getTime(),
            ],
            fillColor: "#00E396",
          },
        ],
      },
      {
        name: "Lapsed Users",
        data: [
          {
            x: "Lapsed Users",
            y: [
              data.lapsedUserCount.dateRange.start
                ? new Date(data.lapsedUserCount.dateRange.start).getTime()
                : new Date(data.lapsedUserCount.dateRange.end).getTime() -
                  365 * 24 * 60 * 60 * 1000, // Default to 1 year before end date if start is missing
              new Date(data.lapsedUserCount.dateRange.end).getTime(),
            ],
            fillColor: "#775DD0",
          },
        ],
      },
    ];

    this.setState({ series });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="rangeBar"
        height={400}
      />
    );
  }
}

export class EarnedPointsHistogram extends React.Component {
  render() {
    const { data } = this.props;

    const transformedData = data.map((item, index) => ({
      x: `${item.expiry_Month} ${item.expiry_year}`,
      y: item.expiry_earned_points,
      ...(index === 4 ? { fillColor: "#195478", strokeColor: "#195478" } : {}),
    }));

    const options = {
      chart: {
        type: "bar",
      },
      series: [
        {
          name: "Earned Points",
          data: transformedData,
        },
      ],
      plotOptions: {
        bar: {
          columnWidth: "95%",
          strokeWidth: 2,
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      fill: {
        colors: "#004169",
        opacity: 0.3,
      },
      stroke: {
        width: 2,
        colors: ["#004169"],
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: "category",
        labels: { formatter: (x) => x },
        title: { text: "Month", offsetY: 70 },
        axisBorder: {
          color: "#000000",
        },
      },
      yaxis: {
        title: { text: "Earned Points" },
        min: 0,
        max: Math.max(...data.map((d) => d.expiry_earned_points)),
        axisBorder: {
          show: true,
          color: "#000000",
        },
      },
      tooltip: {
        x: { formatter: (x) => `Month: ${x}` },
      },
    };

    return (
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={380}
      />
    );
  }
}
export class NewCustomersMonth extends React.Component {
  constructor(props) {
    super(props);

    const { apiResponse } = props;

    const currentMonth = apiResponse.thisMonthCount || 0;
    const lastMonth = apiResponse.lastMonthCount || 0;
    const lastYearThisMonth = apiResponse.lastYearThisMonthCount || 0;
    const lastYearLastMonth = apiResponse.lastYearLastMonthCount || 0;

    // Data for each group
    const seriesDataGroup1 = [currentMonth, lastMonth];
    const seriesDataGroup2 = [lastYearThisMonth, lastYearLastMonth];

    // Calculate percentage changes for tooltips
    const percentageChange = [
      {
        label: apiResponse.thisMonth,
        percentage: lastYearThisMonth ? ((currentMonth - lastYearThisMonth) / lastYearThisMonth) * 100 : 0,
      },
      {
        label: apiResponse.lastMonth,
        percentage: lastMonth ? ((lastMonth - currentMonth) / currentMonth) * 100 : 0,
      },
      {
        label: apiResponse.lastYearThisMonth,
        percentage: currentMonth ? ((lastYearThisMonth - currentMonth) / lastYearThisMonth) * 100 : 0,
      },
      {
        label: apiResponse.lastYearLastMonth,
        percentage: lastMonth ? ((lastYearLastMonth - lastMonth) / lastYearLastMonth) * 100 : 0,
      },
    ];

    // Define x-axis categories
    const categories = [
      `${apiResponse.thisMonth}      ${apiResponse.lastMonth}`,
      `${apiResponse.lastYearThisMonth}    ${apiResponse.lastYearLastMonth}`,
    ];

    this.state = {
      series: [
        {
          name: 'Group 1',
          data: seriesDataGroup1,
        },
        {
          name: 'Group 2',
          data: seriesDataGroup2,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'zoom',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '10%', // Adjust width to control bar size and gap
            barSpacing: '50%', // Adjust spacing between bars
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (val, { seriesIndex, dataPointIndex }) => {
              const percentage = percentageChange[seriesIndex * 2 + dataPointIndex]?.percentage || 0;
              const icon = percentage > 0
                ? '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>'
                : percentage < 0
                ? '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>'
                : '';
              return `${val} customers (${Math.abs(percentage).toFixed(2)}% ${icon})`;
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: 'New Customers Count',
            style: {
              colors: ['#ba54f5'],
              fontWeight: '600',
              fontSize: '15px',
              fontFamily: 'outfit,sans-serif',
            },
            offsetX: -10,
          },
          labels: {
            formatter: (val) => val.toLocaleString(),
          },
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              fontSize: '14px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 400,
            },
          },
          title: {
            text: 'Month & Years',
            style: {
              colors: ['#ba54f5'],
              fontWeight: '600',
              fontSize: '15px',
              fontFamily: 'outfit,sans-serif',
            },
            offsetX: -80,
          },
        },
        legend: {
          show: false, // Hide the entire legend
        },
        colors: ['#1a3f56'],
      },
    };
  }

  render() {
    const { options, series } = this.state;

    return (
      <div className="chart-container">
        <Chart options={options} series={series} type="bar" height={380} />
        <div className="custom-legend">
        <div><span style={{ color: '#1a3f56' }}></span> count</div>
          <div><img src="images/arrow.png" alt="Increased" style={{ width: '16px', height: '16px' }} /> Increased</div>
          <div><img src="images/down.png" alt="Decreased" style={{ width: '16px', height: '16px' }} /> Decreased</div>
        </div>
      </div>
    );
  }
}
export class UserPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [0, 0, 0],
      options: this.getChartOptions(),
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const response = await userTotalChart();
      if (response.success) {
        const dataGet = response.data;
        const data = [
          dataGet.activeUserCount.count,
          dataGet.dormantUserCount.count,
          dataGet.lapsedUserCount.count,
        ];
        this.setState({
          series: data,
          isLoading: false,
        });
      } else {
        console.error("Failed to fetch data:", response);
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error:", error);
      this.setState({ isLoading: false });
    }
  }

  getChartOptions() {
    return {
      chart: {
        height: 400,
        type: "pie",
        toolbar: {
          show: true,
        },
      },
      labels: ["Active Last 4 Months", "Dormant Users", "Lapsed Users"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 400,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  render() {
    const { options, series, isLoading } = this.state;

    return (
      <Chart
        key="pie12"
        options={options}
        series={series}
        type="pie"
        height={400}
      />
    );
  }
}
export class NewCustomersDaily extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        { name: "Count", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
      options: this.getChartOptions(props.chartType),
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.updateChartData();
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "realtime",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#1a3f56", "#00FF00", "#FF0000"], // Colors for the series
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span style='color:#1a3f56;'>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
        markers: {
          fillColors: ["#1a3f56", "transparent", "transparent"], // Show color for Count, transparent for others
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        labels: {
          formatter: function (value, timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString("en-US", {
              hour: "numeric",
              hour12: true,
            });
          },
          style: {
            colors: ["#ba54f5"],
            fontSize: "12px",
          },
        },
        title: {
          text: " Hours ", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
          offsetX: -1,
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
              fontFamily:"outfit,sans-serif"
            },
            formatter: function (value) {
              return value !== undefined ? value.toFixed(0) : 0;
            },
           
          },
          title: {
            text: "New Customers Count", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
          max: 15,
        },
        {
          opposite: true,
          labels: {
            style: {
              colors: ["#ff6347"],
              fontSize: "12px",
            },
            formatter: function (value) {
              return value !== undefined ? value.toFixed(2) + "%" : "0%";
            },
          },
          tickAmount: 3,
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: [
          {
            formatter: function (val) {
              return val !== undefined ? val + " customers" : "0 customers";
            },
          },
          {
            formatter: function (val) {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: function (val) {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };
  
    // Handling chart type
    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          curve: "smooth",
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
      };
    }
    return commonOptions;
  };
  
  
  
  updateChartData = () => {
    const { data, chartType } = this.props;
    const chartOptions = this.getChartOptions(chartType);
  
    if (data && data.length > 0) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
  
      const categories = [];
      const countSeriesData = Array(24).fill(0);
      const positivePercentageChangeData = Array(24).fill(0);
      const negativePercentageChangeData = Array(24).fill(0);
  
      data.forEach((item, index) => {
        const hour = item.hour;
        countSeriesData[hour] = item.count;
        categories.push(
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            hour
          ).getTime()
        );
  
        if (index > 0) {
          const previousCount = data[index - 1].count;
          const percentageChange =
            previousCount !== 0
              ? ((item.count - previousCount) / previousCount) * 100
              : 0;
  
          if (percentageChange >= 0) {
            positivePercentageChangeData[hour] = percentageChange;
          } else {
            negativePercentageChangeData[hour] = percentageChange;
          }
        }
      });
  
      for (let hour = 0; hour < 24; hour++) {
        const timestamp = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          hour
        ).getTime();
        if (!categories.includes(timestamp)) {
          categories.push(timestamp);
          countSeriesData[hour] = 0;
          positivePercentageChangeData[hour] = 0;
          negativePercentageChangeData[hour] = 0;
        }
      }
  
      categories.sort((a, b) => a - b);
  
      this.setState({
        series: [
          { name: "Count", data: countSeriesData },
          {
            name: "Increased",
            data: positivePercentageChangeData,
            type: 'line',
          },
          {
            name: "Decreased",
            data: negativePercentageChangeData,
            type: 'line',
          },
        ],
        options: {
          ...chartOptions,
          colors: ["#1a3f56", "#00FF00", "#FF0000"], // Assign colors to different series
          stroke: {
            curve: "smooth",
            width: [2, 0, 0], // Hide the lines for percentage data
          },
          markers: {
          
            colors: ["#1a3f56", "transparent", "transparent"], // Set markers color for Increased and Decreased to transparent
          },
          xaxis: {
            ...chartOptions.xaxis,
            categories: categories,
          },
        },
      });
    } else {
      this.setState({
        series: [
          { name: "Count", data: [] },
          { name: "Increased", data: [] },
          { name: "Decreased", data: [] },
        ],
        options: {
          ...chartOptions,
          xaxis: {
            ...chartOptions.xaxis,
            categories: [],
          },
        },
      });
    }
  };

  render() {
    const { options, series } = this.state;
    const { chartType } = this.props;

    return (
      <Chart
        options={options}
        series={series}
        type={chartType || "line"}
        height={380}
      />
    );
  }
}
export class NewCustomersWeek extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        { name: "Count", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
      options: this.getChartOptions(props.chartType),
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.updateChartData();
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "realtime",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      
      colors: ["#1a3f56", "#00FF00", "#FF0000"], // Colors for the series
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span style='color:#1a3f56;'>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
        markers: {
          fillColors: ["#1a3f56", "transparent", "transparent"], // Show color for Count, transparent for others
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        labels: {
          formatter: function (value) {
            const date = new Date(value);
            return date.toLocaleString("en-US", { weekday: "short" }); // Display day of the week (Sun, Mon, etc.)
          },
          style: {
            colors: ["#ba54f5"],
            fontSize: "12px",
          },
        },
        title: {
          text: "Last 7 Days", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
        
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: function (value) {
              return value !== undefined ? value.toFixed(0) : 0;
            },
          },
          title: {
            text: "New Customers Count", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
          // max: 15,
        },
        
        {
          opposite: true,
          labels: {
            style: {
              colors: ["#ff6347"],
              fontSize: "12px",
            },
            formatter: function (value) {
              return value !== undefined ? value.toFixed(2) + "%" : "0%";
            },
          },
          tickAmount: 3,
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: [
          {
            formatter: function (val) {
              return val !== undefined ? val + " customers" : "0 customers";
            },
          },
          {
            formatter: function (val) {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: function (val) {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          curve: "smooth",
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
      };
    }
    return commonOptions;
  };

  updateChartData = () => {
    const { data, chartType } = this.props;
    const chartOptions = this.getChartOptions(chartType);

    if (data && data.length > 0) {
      const categories = [];
      const countSeriesData = [];
      const positivePercentageChangeData = [];
      const negativePercentageChangeData = [];

      data.forEach((item, index) => {
        let timestamp;
        try {
          const date = new Date(item.day);
          if (isNaN(date.getTime())) {
            throw new Error("Invalid date");
          }
          timestamp = date.getTime();
        } catch (error) {
          console.error("Invalid date format:", item.day);
          timestamp = Date.now(); // Fallback to current timestamp
        }

        const dayName = new Date(timestamp).toLocaleString("en-US", { weekday: "long" }); // Full weekday name
        categories.push(timestamp); // Use timestamp for x-axis
        countSeriesData.push(item.count);

        if (index > 0) {
          const previousCount = data[index - 1].count;
          const percentageChange =
            previousCount !== 0
              ? ((item.count - previousCount) / previousCount) * 100
              : 0;

          if (percentageChange >= 0) {
            positivePercentageChangeData.push(percentageChange);
            negativePercentageChangeData.push(0);
          } else {
            positivePercentageChangeData.push(0);
            negativePercentageChangeData.push(percentageChange);
          }
        } else {
          positivePercentageChangeData.push(0);
          negativePercentageChangeData.push(0);
        }
      });

      this.setState({
        series: [
          { name: "Count", data: countSeriesData },
          {
            name: "Increased",
            data: positivePercentageChangeData,
            type: 'line',
          },
          {
            name: "Decreased",
            data: negativePercentageChangeData,
            type: 'line',
          },
        ],
        options: {
          ...chartOptions,
          xaxis: {
            ...chartOptions.xaxis,
            categories: categories,
          },
        },
      });
    } else {
      this.setState({
        series: [
          { name: "Count", data: [] },
          { name: "Increased", data: [] },
          { name: "Decreased", data: [] },
        ],
        options: {
          ...chartOptions,
          xaxis: {
            ...chartOptions.xaxis,
            categories: [],
          },
        },
      });
    }
  };

  render() {
    const { options, series } = this.state;
    const { chartType } = this.props;

    return (
      <Chart
        options={options}
        series={series}
        type={chartType || "line"}
        height={380}
      />
    );
  }
}

export class TopCategoriesDaily extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "heatmap",
          height: 350,
          toolbar: {
            show: true,
          },
        },
        colors: [], // Dynamic colors based on rank
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: [], // To be set dynamically
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter: (val, { seriesIndex, dataPointIndex, w }) => {
              const categoryName = w.globals.seriesNames[seriesIndex];
              const totalAmount =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .totalAmount;

              // Display default message if value is 0
              if (totalAmount === 0) {
                return `${categoryName}: 0`;
              }

              // Display actual total amount otherwise
              return `${totalAmount}`;
            },
          },
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [], // To be set dynamically
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.updateChart(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateChart(this.props.data);
    }
  }

  getRankColor = (rank) => {
    // Define colors for each rank
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  };

  updateChart = (data) => {
    if (data && data.Data.length > 0) {
      let categoryTotals = {};
      let rankColors = {};

      // Prepare category totals and rank colors
      data.Data.forEach((dayData) => {
        dayData.Categories.forEach((category) => {
          if (!categoryTotals[category.CategoryName]) {
            categoryTotals[category.CategoryName] = [];
          }
          categoryTotals[category.CategoryName].push({
            hour: dayData.hour,
            rank: category.Rank,
            totalAmount: category.TotalAmount,
          });

          if (!rankColors[category.Rank]) {
            rankColors[category.Rank] = this.getRankColor(category.Rank);
          }
        });
      });

      // Prepare x-axis categories (hours)
      const xAxisCategories = Array.from({ length: 24 }, (_, i) => `${i}:00`);

      // Fill in missing hours for each category
      const topCategories = Object.keys(categoryTotals);
      topCategories.forEach((category) => {
        xAxisCategories.forEach((hour) => {
          const hourIndex = parseInt(hour.split(":")[0], 10);
          if (
            !categoryTotals[category].some((entry) => entry.hour === hourIndex)
          ) {
            categoryTotals[category].push({
              hour: hourIndex,
              rank: 0, // No rank for zero values
              totalAmount: 0,
            });
          }
        });
      });

      // Prepare series data for the heatmap
      const seriesData = topCategories.map((categoryName) => {
        return {
          name: categoryName,
          data: xAxisCategories.map((hour) => {
            const hourIndex = parseInt(hour.split(":")[0], 10);
            const entry = categoryTotals[categoryName].find(
              (e) => e.hour === hourIndex
            );
            return {
              x: hour,
              y: entry ? entry.rank : 0,
              totalAmount: entry ? entry.totalAmount : 0,
              color: rankColors[entry ? entry.rank : 0] || "#ffffff",
            };
          }),
        };
      });

      // Define color scale ranges in order of rank
      const colorRanges = [
        { from: 1, to: 1, name: "First", color: rankColors[1] || "#ffffff" },
        { from: 2, to: 2, name: "Second", color: rankColors[2] || "#ffffff" },
        { from: 3, to: 3, name: "Third", color: rankColors[3] || "#ffffff" },
        { from: 4, to: 4, name: "Fourth", color: rankColors[4] || "#ffffff" },
        { from: 5, to: 5, name: "Fifth", color: rankColors[5] || "#ffffff" },
      ];

      // Update the chart options and series
      this.setState({
        series: seriesData,
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: xAxisCategories,
          },
          colors: Object.values(rankColors), // Set dynamic colors
          plotOptions: {
            heatmap: {
              colorScale: {
                ranges: colorRanges,
              },
            },
          },
        },
      });
    } else {
      console.error("Data is missing or empty", data);
    }
  };

  render() {
    return (
      <div className="col">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}
export class TopCategoriesWeekly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "heatmap",
          height: 350,
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.7,
            colorScale: {
              ranges: [],
            },
          },
        },
        colors: [], // Dynamic colors based on rank
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          categories: [], // To be set dynamically
        },
        yaxis: {
          categories: [], // To be set dynamically
        },
        tooltip: {
          y: {
            formatter: (val, { seriesIndex, dataPointIndex, w }) => {
              const categoryName = w.globals.seriesNames[seriesIndex];
              const totalAmount =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .totalAmount;

              // Display default message if value is 0
              if (totalAmount === 0) {
                return `${categoryName}: 0`;
              }

              // Display actual total amount otherwise
              return `${totalAmount}`;
            },
          },
        },
        legend: {
          position: "bottom",
        },
      },
    };
  }

  componentDidMount() {
    this.updateChart(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateChart(this.props.data);
    }
  }

  getRankColor = (rank) => {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  };

  updateChart = (data) => {
    if (data && data.Data.length > 0) {
      let categoryTotals = {};
      let rankColors = {};

      data.Data.forEach((dayData) => {
        dayData.Categories.forEach((category) => {
          if (!categoryTotals[category.CategoryName]) {
            categoryTotals[category.CategoryName] = [];
          }
          categoryTotals[category.CategoryName].push({
            date: new Date(dayData.datemark).getTime(),
            rank: category.Rank,
            totalAmount: category.TotalAmount,
          });

          if (!rankColors[category.Rank]) {
            rankColors[category.Rank] = this.getRankColor(category.Rank);
          }
        });
      });

      const allDates = data.dateRange.map((date) => new Date(date).getTime());
      const topCategories = Object.keys(categoryTotals);

      topCategories.forEach((category) => {
        allDates.forEach((date) => {
          if (!categoryTotals[category].some((entry) => entry.date === date)) {
            categoryTotals[category].push({
              date: date,
              rank: 0, // No rank for zero values
              totalAmount: 0,
            });
          }
        });
      });

      const seriesData = topCategories.map((categoryName) => {
        return {
          name: categoryName,
          data: allDates.map((date) => {
            const entry = categoryTotals[categoryName].find(
              (e) => e.date === date
            );
            return {
              x: date,
              y: entry ? entry.rank : 0,
              totalAmount: entry ? entry.totalAmount : 0,
            };
          }),
        };
      });

      // Define color scale ranges with custom names
      const colorRanges = [
        { from: 1, to: 1, name: "First", color: rankColors[1] || "#ffffff" },
        { from: 2, to: 2, name: "Second", color: rankColors[2] || "#ffffff" },
        { from: 3, to: 3, name: "Third", color: rankColors[3] || "#ffffff" },
        { from: 4, to: 4, name: "Fourth", color: rankColors[4] || "#ffffff" },
        { from: 5, to: 5, name: "Fifth", color: rankColors[5] || "#ffffff" },
      ];

      this.setState({
        series: seriesData,
        options: {
          ...this.state.options,
          colors: Object.values(rankColors), // Set dynamic colors
          plotOptions: {
            heatmap: {
              colorScale: {
                ranges: colorRanges,
              },
            },
          },
          yaxis: {
            ...this.state.options.yaxis,
            categories: topCategories,
          },
          xaxis: {
            ...this.state.options.xaxis,
            categories: allDates,
          },
        },
      });
    }
  };

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="heatmap"
        height={380}
      />
    );
  }
}

export class TopCategoriesMonthly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "pie",
          height: 350,
        },
        labels: [],
        legend: {
          position: "bottom",
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val.toFixed(2)}`;
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            const totalAmount = opts.w.globals.series[opts.seriesIndex];
            return `${
              opts.w.globals.labels[opts.seriesIndex]
            }: ${totalAmount.toFixed(2)}`;
          },
        },
      },
    };
  }

  componentDidMount() {
    console.log("componentDidMount data:", this.props.data);
    if (this.props.data && Array.isArray(this.props.data.Data)) {
      this.updateChart(this.props.data);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data &&
      this.props.data &&
      Array.isArray(this.props.data.Data)
    ) {
      console.log("componentDidUpdate data:", this.props.data);
      this.updateChart(this.props.data);
    }
  }

  updateChart = (data) => {
    console.log("updateChart data:", data);
    if (Array.isArray(data.Data) && data.Data.length > 0) {
      const categories = data.Data.map((item) => item.CategoryName);
      const seriesData = data.Data.map((item) => item.TotalAmount);

      this.setState({
        series: seriesData,
        options: {
          ...this.state.options,
          labels: categories,
        },
      });
    } else {
      console.error("Invalid data structure or empty data provided.");
    }
  };

  render() {
    const { series, options } = this.state;

    if (!series.length) {
      return <p>No data available to display the chart.</p>;
    }

    return (
      <div className="col">
        <Chart options={options} series={series} type="pie" height={350} />
      </div>
    );
  }
}
export class TopSubCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "heatmap",
          height: 350,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        colors: [], // Dynamic colors based on rank
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: [], // To be set dynamically
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
              const category = w.globals.initialSeries[seriesIndex].name;
              const amount =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex].value;
              return `: ${amount}`;
            },
          },
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.7,
            colorScale: {
              ranges: [], // To be set dynamically
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.updateChart(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateChart(this.props.data);
    }
  }

  getRankColor = (rank) => {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  };

  updateChart = (data) => {
    if (data && data.dateRange && data.Data) {
      const dateLabels = data.dateRange;
      const subCategories = {};

      // Process data
      data.Data.forEach((dayData) => {
        if (dayData.datemark) {
          const date = dayData.datemark.split("T")[0];
          dayData.SubCategories.forEach((subCat) => {
            if (!subCategories[subCat.SubCategoriesName]) {
              subCategories[subCat.SubCategoriesName] = [];
            }
            subCategories[subCat.SubCategoriesName].push({
              date,
              value: subCat.TotalAmount,
              rank: subCat.Rank,
            });
          });
        }
      });

      // Rank and filter top 5 subcategories
      const rankedSubCategories = Object.entries(subCategories)
        .map(([name, dataPoints]) => ({
          name,
          dataPoints,
        }))
        .sort((a, b) => {
          // Rank by highest value if needed (or other criteria)
          const aTotal = a.dataPoints.reduce((sum, dp) => sum + dp.value, 0);
          const bTotal = b.dataPoints.reduce((sum, dp) => sum + dp.value, 0);
          return bTotal - aTotal; // Descending order
        })
        .slice(0, 5);

      // Prepare series data
      const seriesData = rankedSubCategories.map((subCat) => {
        const dataPoints = dateLabels.map((date) => {
          const dayData = subCat.dataPoints.find((d) => d.date === date);
          return {
            x: date,
            y: dayData ? dayData.rank : 0, // Use rank for coloring
            value: dayData ? dayData.value : 0, // Add value for tooltip
            fillColor: this.getRankColor(dayData ? dayData.rank : 0), // Color based on rank
          };
        });
        return {
          name: subCat.name,
          data: dataPoints,
        };
      });

      const rankNames = ["First", "Second", "Third", "Fourth", "Fifth"];
      const rankColors = rankNames.map((name, index) => {
        const rank = index + 1;
        return {
          from: rank,
          to: rank,
          name: name,
          color: this.getRankColor(rank),
        };
      });

      this.setState({
        series: seriesData,
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: dateLabels,
          },
          colors: rankColors.map((range) => range.color),
          plotOptions: {
            heatmap: {
              colorScale: {
                ranges: rankColors,
              },
            },
          },
        },
      });
    } else {
      console.error("Invalid data format or missing required properties");
    }
  };

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="heatmap"
        height={380}
      />
    );
  }
}
export class TopSubCategoriesDaily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "heatmap",
          height: 350,
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: [], // To be set dynamically
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
              const subCategory =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .subCategoryName;
              const amount =
                w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .amount;
              return `: ${amount}`;
            },
          },
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [], // To be set dynamically
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.updateChart(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateChart(this.props.data);
    }
  }

  getRankColor = (rank) => {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  };

  updateChart = (data) => {
    if (data && data.Data && Array.isArray(data.Data) && data.Date) {
      const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
      const subCategoriesData = {};

      data.Data.forEach((item) => {
        if (Array.isArray(item.Categories)) {
          item.Categories.forEach((subCategory) => {
            const rank = subCategory.Rank || 0;
            if (!subCategoriesData[subCategory.SubCategoryName]) {
              subCategoriesData[subCategory.SubCategoryName] = Array(24).fill({
                amount: 0,
                rank: 0,
                subCategoryName: "",
              });
            }
            subCategoriesData[subCategory.SubCategoryName][item.hour] = {
              amount: subCategory.TotalAmount,
              rank: rank,
              subCategoryName: subCategory.SubCategoryName,
            };
          });
        } else {
          console.warn(
            "Expected Categories to be an array but got:",
            item.Categories
          );
        }
      });

      // Prepare series data
      const seriesData = Object.keys(subCategoriesData).map((name) => ({
        name,
        data: subCategoriesData[name].map((entry, hour) => ({
          x: hours[hour],
          y: entry.rank,
          amount: entry.amount,
          subCategoryName: entry.subCategoryName,
        })),
      }));

      // Define color scale ranges dynamically based on rank
      const rankColors = {
        1: this.getRankColor(1),
        2: this.getRankColor(2),
        3: this.getRankColor(3),
        4: this.getRankColor(4),
        5: this.getRankColor(5),
      };

      const colorRanges = [
        { from: 1, to: 1, name: "First", color: rankColors[1] || "#ffffff" },
        { from: 2, to: 2, name: "Second", color: rankColors[2] || "#ffffff" },
        { from: 3, to: 3, name: "Third", color: rankColors[3] || "#ffffff" },
        { from: 4, to: 4, name: "Fourth", color: rankColors[4] || "#ffffff" },
        { from: 5, to: 5, name: "Fifth", color: rankColors[5] || "#ffffff" },
      ];

      this.setState({
        series: seriesData,
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: hours,
          },
          plotOptions: {
            heatmap: {
              colorScale: {
                ranges: colorRanges,
              },
            },
          },
        },
      });
    } else {
      console.error('Invalid data format or missing "Date" property');
    }
  };

  render() {
    return (
      <div className="col">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={380}
        />
      </div>
    );
  }
}

export class TransactionsAndSalesChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "transactions-sales-chart",
          height: 350,
          type: "line",
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [3, 3],
          curve: "smooth",
        },
        markers: {
          size: 5,
          colors: ["#FF4560", "#008FFB"],
          strokeWidth: 2,
          strokeColors: "#fff",
          hover: {
            size: 7,
          },
        },
        title: {
          text: "Transactions and Sales Value",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: props.data.map((item) => item.month),
          title: {
            text: "Month",
          },
        },
        yaxis: [
          {
            title: {
              text: "Number of Transactions",
            },
          },
          {
            opposite: true,
            title: {
              text: "Sales Value",
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => {
              return val.toFixed(0);
            },
          },
        },
      },
      series: [
        {
          name: "Number of Transactions",
          type: "line",
          data: props.data.map((item) => item.transactions),
        },
        {
          name: "Sales Value",
          type: "line",
          data: props.data.map((item) => item.sales),
          yAxis: 1,
        },
      ],
    };
  }

  render() {
    return (
      <div className="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}
export class TopSellingBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands (Current Period)",
          align: "left",
        },
        xaxis: {
          type: "category",
          categories: [],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Brands",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              const seriesData =
                this.state.series[seriesIndex].data[dataPointIndex];
              return `Rank: ${value} | Total Amount: ${seriesData.totalAmount.toFixed(
                2
              )}`;
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
    };
  }

  componentDidMount() {
    this.processData(this.props.data); // Process data initially
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.processData(this.props.data); // Process data when props change
    }
  }

  processData(data) {
    if (!data || !data.Data || data.Data.length === 0) {
      console.error("Invalid data format:", data);
      return;
    }

    const { dateRange, Data: chartData } = data;

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("default", {
        month: "short",
        day: "2-digit",
      });
    };

    const brandSet = new Set();
    chartData.forEach((item) => {
      item.Brands.forEach((brand) => {
        brandSet.add(brand.BrandName);
      });
    });

    const yaxisCategories = Array.from(brandSet);
    const xaxisCategories = dateRange.map((date) => formatDate(date));

    const series = yaxisCategories.map((brand) => ({
      name: brand,
      data: dateRange.map((date) => {
        const item = chartData.find((d) => d.datemark === date);
        const brandData = item
          ? item.Brands.find((b) => b.BrandName === brand)
          : null;
        return {
          x: formatDate(date),
          y: brandData ? brandData.Rank : 0, // Use Rank instead of TotalAmount
          totalAmount: brandData ? brandData.TotalAmount : 0, // Store TotalAmount for tooltip
        };
      }),
    }));

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: xaxisCategories,
        },
        yaxis: {
          ...this.state.options.yaxis,
          categories: yaxisCategories,
        },
        plotOptions: {
          ...this.state.options.plotOptions,
          heatmap: {
            ...this.state.options.plotOptions.heatmap,
            colorScale: {
              ranges: this.getColorRanges(),
            },
          },
        },
      },
      series: series,
    });
  }

  // Method to get color ranges based on rank
  getColorRanges() {
    return [
      { from: 1, to: 1, name: "First", color: "#003077" },
      { from: 2, to: 2, name: "Second ", color: "#2a4b7e" },
      { from: 3, to: 3, name: "Third", color: "#7497cc" },
      { from: 4, to: 4, name: "Fourth", color: "#aec2e0" },
      { from: 5, to: 5, name: "Fifth", color: "#a8b7c2" },
    ];
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}
export class TopSellingBrandLastweek extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: this.getColorRanges(), // Dynamically set color ranges
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands (Last Week)",
          align: "left",
        },
        xaxis: {
          type: "category",
          categories: [],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Brands",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              const seriesData =
                this.state.series[seriesIndex].data[dataPointIndex];
              return `Total Amount: ${seriesData.totalAmount.toFixed(2)}`; // Show total amount
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
    };
  }

  // Method to get color based on rank
  getColorForRank(rank) {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  }

  // Method to get color ranges based on rank
  getColorRanges() {
    return [
      { from: 1, to: 1, name: "First", color: this.getColorForRank(1) },
      { from: 2, to: 2, name: "Second", color: this.getColorForRank(2) },
      { from: 3, to: 3, name: "Third", color: this.getColorForRank(3) },
      { from: 4, to: 4, name: "Fourth", color: this.getColorForRank(4) },
      { from: 5, to: 5, name: "Fifth", color: this.getColorForRank(5) },
    ];
  }

  componentDidMount() {
    this.processData(this.props.data); // Process data initially
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.processData(this.props.data); // Process data when props change
    }
  }

  processData(data) {
    if (!data || !data.Data || data.Data.length === 0) {
      console.error("Invalid data format:", data);
      return;
    }

    const { dateRange, Data: chartData } = data;

    // Extract unique brand names
    const brandSet = new Set();
    chartData.forEach((item) => {
      item.Brands.forEach((brand) => {
        brandSet.add(brand.BrandName);
      });
    });

    const yaxisCategories = Array.from(brandSet);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("default", {
        month: "short",
        day: "2-digit",
      });
    };
    const xaxisCategories = dateRange.map((date) => formatDate(date));

    const series = yaxisCategories.map((brand) => ({
      name: brand,
      data: dateRange.map((date) => {
        const item = chartData.find((d) => d.datemark === date);
        const brandData = item
          ? item.Brands.find((b) => b.BrandName === brand)
          : null;
        return {
          x: formatDate(date),
          y: brandData ? brandData.Rank : 0, // Use Rank instead of TotalAmount
          totalAmount: brandData ? brandData.TotalAmount : 0, // Store TotalAmount for tooltip
        };
      }),
    }));

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: xaxisCategories,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          categories: yaxisCategories,
          title: {
            text: "Brands",
          },
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: this.getColorRanges(),
            },
          },
        },
      },
      series: series,
    });
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}
export class TopSellingBrandDaily extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: this.getColorRanges(), // Color ranges based on rank
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands",
          align: "left",
        },
        xaxis: {
          categories: this.generateXAxisLabels(),
          title: {
            text: "Hour",
          },
        },
        yaxis: {
          categories: [],
          title: {
            text: "Brands",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              const seriesData =
                this.state.series[seriesIndex].data[dataPointIndex];
              return ` ${seriesData.totalAmount.toFixed(2)}`;
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
    };
  }

  // Method to get color based on rank
  getColorForRank(rank) {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  }

  // Method to get color ranges based on rank
  getColorRanges() {
    return [
      { from: 1, to: 1, name: "Rank 1", color: this.getColorForRank(1) },
      { from: 2, to: 2, name: "Rank 2", color: this.getColorForRank(2) },
      { from: 3, to: 3, name: "Rank 3", color: this.getColorForRank(3) },
      { from: 4, to: 4, name: "Rank 4", color: this.getColorForRank(4) },
      { from: 5, to: 5, name: "Rank 5", color: this.getColorForRank(5) },
    ];
  }

  generateXAxisLabels() {
    const labels = [];
    for (let i = 0; i < 24; i++) {
      const hour = i % 24 === 0 ? 12 : i % 12;
      const period = i < 12 || i === 24 ? "AM" : "PM";
      const label = `${hour === 0 ? 12 : hour} ${period}`;
      labels.push(label);
    }
    return labels;
  }

  componentDidMount() {
    this.processData(this.props.data.Data);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.Data !== prevProps.data.Data) {
      this.processData(this.props.data.Data);
    }
  }

  processData(data) {
    if (!data) return;

    const yaxisCategories = [
      ...new Set(
        data.flatMap((item) => item.Brands.map((brand) => brand.BrandName))
      ),
    ];

    const series = yaxisCategories.map((brand) => ({
      name: brand,
      data: Array.from({ length: 24 }, (_, i) => {
        const hourData = data.find((item) => item.hour === i);
        const brandData = hourData
          ? hourData.Brands.find((b) => b.BrandName === brand)
          : null;
        return {
          x: `${i}:00`,
          y: brandData ? brandData.Rank : 0,
          totalAmount: brandData ? brandData.TotalAmount : 0, // Use Rank instead of TotalAmount
        };
      }),
    }));

    this.setState({
      options: {
        ...this.state.options,
        yaxis: {
          categories: yaxisCategories,
          title: {
            text: "Brands",
          },
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: this.getColorRanges(),
            },
          },
        },
      },
      series: series,
    });
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}
export class TopSellingNationality extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [], // Color ranges will be set dynamically
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Spending Nationalities(Current Period)",
          align: "left",
        },
        xaxis: {
          categories: [], // Dates will be set dynamically
          title: {
            text: "Date",
          },
        },
        yaxis: {
          categories: [], // Nationalities will be set dynamically
          title: {
            text: "Nationality",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex }) {
              // Access the series and dataPoint to get the total amount
              const totalAmount =
                this.state.series[seriesIndex].data[dataPointIndex].totalAmount;
              return `: ${totalAmount}`; // Show the total amount in the tooltip
            }.bind(this),
          },
        },
        legend: {
          position: "bottom",
        },
      },
    };
  }

  componentDidMount() {
    this.processData(this.props.data); // Process data initially
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.processData(this.props.data); // Process data when props change
    }
  }

  processData(data) {
    if (!data || !data.dateRange || !data.Data) return;

    const dates = data.dateRange;
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("default", {
        month: "short",
        day: "2-digit",
      });
    };

    const nationalitiesSet = new Set();
    const rankSet = new Set();
    data.Data.forEach((item) => {
      item.nationality.forEach((n) => {
        if (n.nationalityName) {
          nationalitiesSet.add(n.nationalityName);
          rankSet.add(n.Rank); // Collect all ranks
        }
      });
    });

    const nationalities = Array.from(nationalitiesSet);
    const ranks = Array.from(rankSet).sort((a, b) => a - b); // Sort ranks in ascending order
    const xaxisCategories = dates.map((date) => formatDate(date));
    const series = nationalities.map((nationality) => ({
      name: nationality,
      data: dates.map((date) => ({
        x: formatDate(date),
        y: 0,
        totalAmount: 0, // Initialize totalAmount for tooltip
      })),
    }));

    data.Data.forEach((item) => {
      if (!item.datemark) return; // Skip if datemark is undefined or null
      const formattedDate = item.datemark.split("T")[0]; // Split to get the date part only
      item.nationality.forEach((nation) => {
        if (nation.nationalityName) {
          const nationalityIndex = nationalities.indexOf(
            nation.nationalityName
          );
          if (nationalityIndex !== -1) {
            const dateIndex = dates.indexOf(formattedDate);
            if (dateIndex !== -1) {
              // Set y value to the rank and totalAmount
              series[nationalityIndex].data[dateIndex].y = nation.Rank;
              series[nationalityIndex].data[dateIndex].totalAmount =
                nation.TotalAmount; // Store total amount
            }
          }
        }
      });
    });

    // Define color ranges based on rank dynamically
    const colorRanges = ranks.map((rank) => ({
      from: rank,
      to: rank,
      name: `Rank ${rank}`,
      color: this.getColorForRank(rank),
    }));

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          categories: xaxisCategories,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          categories: nationalities,
          title: {
            text: "Nationality",
          },
        },
        plotOptions: {
          heatmap: {
            ...this.state.options.plotOptions.heatmap,
            colorScale: {
              ranges: colorRanges,
            },
          },
        },
      },
      series: series,
    });
  }

  getColorForRank(rank) {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={500}
        />
      </div>
    );
  }
}
export class TopSellingNationalityDaily extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [],
            },
          },
        },
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: " Top Spending Nationalities(Today)",
          align: "left",
        },
        xaxis: {
          categories: this.generateXAxisLabels(), // Custom time labels
          title: {
            text: "Hour",
          },
        },
        yaxis: {
          categories: [],
          title: {
            text: "Nationality",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex, dataPointIndex, w }) => {
              const dataPoint =
                w.config.series[seriesIndex].data[dataPointIndex];

              if (dataPoint && dataPoint.totalAmount !== null) {
                const totalAmount = dataPoint.totalAmount; // TotalAmount

                return `${totalAmount.toFixed(2)}`;
              }
              return "0";
            },
          },
        },
      },
    };

    // Bind method to ensure it has the correct context
    this.generateXAxisLabels = this.generateXAxisLabels.bind(this);
    this.processData = this.processData.bind(this);
    this.getColorForRank = this.getColorForRank.bind(this);
  }

  // Method to generate X-axis labels for hours in PM format
  generateXAxisLabels() {
    const labels = [];
    const startHour = 0;
    const endHour = 23;

    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 24 === 0 ? 12 : i % 12;
      const period = i < 12 || i === 24 ? "AM" : "PM";
      const label = `${hour === 0 ? 12 : hour} ${period}`;
      labels.push(label);
    }

    return labels;
  }

  componentDidMount() {
    this.processData(this.props.data); // Adjusted to call with correct data structure
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.processData(this.props.data); // Adjusted to call with correct data structure
    }
  }

  processData(data) {
    if (!data || !data.Data || data.Data.length === 0) {
      return;
    }

    const hours = [...new Set(data.Data.map((item) => item.hour))];
    const nationalities = [
      ...new Set(
        data.Data.flatMap((item) =>
          item.nationality.map((nat) => nat.nationalityName)
        )
      ),
    ];

    const series = nationalities.map((nationality) => ({
      name: nationality,
      data: hours.map((hour) => {
        const item = data.Data.find((dataItem) => dataItem.hour === hour);
        const nationalityData = item
          ? item.nationality.find((nat) => nat.nationalityName === nationality)
          : null;
        return {
          x: hour,
          y: nationalityData ? nationalityData.Rank : null, // Use rank for y-axis
          totalAmount: nationalityData ? nationalityData.TotalAmount : null, // Add totalAmount for tooltip
        };
      }),
    }));

    // Update colorScale based on ranks
    const colorRanges = [
      { from: 1, to: 1, name: "First", color: this.getColorForRank(1) },
      { from: 2, to: 2, name: "Second", color: this.getColorForRank(2) },
      { from: 3, to: 3, name: "Third", color: this.getColorForRank(3) },
      { from: 4, to: 4, name: "Fourth", color: this.getColorForRank(4) },
      { from: 5, to: 5, name: "Fifth", color: this.getColorForRank(5) },
    ];

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          categories: this.generateXAxisLabels(),
          title: {
            text: "Hour",
          },
        },
        yaxis: {
          categories: nationalities.slice(0, 5), // Show top 5 nationalities
          title: {
            text: "Nationality",
          },
        },
        plotOptions: {
          heatmap: {
            ...this.state.options.plotOptions.heatmap,
            colorScale: {
              ranges: colorRanges,
            },
          },
        },
      },
      series: series,
    });
  }

  // Get color based on rank
  getColorForRank(rank) {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}

export class TopSellingNationalityWeek extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Spending Nationalities(Last 7 Days)",
          align: "left",
        },
        xaxis: {
          categories: [],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          categories: [],
          title: {
            text: "Nationality",
          },
        },
        legend: {
          position: "bottom",
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              const dataPoint =
                w.config.series[seriesIndex].data[dataPointIndex];
              const date = w.config.xaxis.categories[dataPointIndex];
              const nationality = w.config.series[seriesIndex].name;
              const totalAmount = dataPoint.totalAmount;
              return `Date: ${date}<br>Nationality: ${nationality}<br>Total Amount: $${totalAmount.toFixed(
                2
              )}`;
            },
          },
        },
      },
    };

    this.processData = this.processData.bind(this);
    this.getColorForRank = this.getColorForRank.bind(this);
  }

  componentDidMount() {
    this.processData(this.props.data); // Process data initially
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.processData(this.props.data); // Process data when props change
    }
  }

  processData(data) {
    if (!data || !data.Data) return;

    const dates = data.dateRange;
    const nationalitiesSet = new Set();

    // Process nationalities
    data.Data.forEach((item) => {
      item.nationality.forEach((n) => {
        if (n.nationalityName) {
          nationalitiesSet.add(n.nationalityName);
        }
      });
    });

    const nationalities = Array.from(nationalitiesSet);

    // Custom format function for date
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    };

    const xaxisCategories = dates.map((date) => formatDate(date));

    const series = nationalities.map((nationality) => ({
      name: nationality,
      data: dates.map((date) => ({
        x: formatDate(date),
        y: 0,
        totalAmount: 0,
      })),
    }));

    data.Data.forEach((item) => {
      // Check if datemark is defined before splitting
      const dateStr = item.datemark ? item.datemark.split("T")[0] : null;
      if (!dateStr) return;

      item.nationality.forEach((nation) => {
        if (nation.nationalityName) {
          const nationalityIndex = nationalities.indexOf(
            nation.nationalityName
          );
          if (nationalityIndex !== -1) {
            const dateIndex = dates.indexOf(dateStr);
            if (dateIndex !== -1) {
              series[nationalityIndex].data[dateIndex].y = parseFloat(
                nation.Rank
              );
              series[nationalityIndex].data[dateIndex].totalAmount = parseFloat(
                nation.TotalAmount
              );
            }
          }
        }
      });
    });

    // Define color ranges based on rank
    const colorRanges = [
      { from: 1, to: 1, name: "First", color: this.getColorForRank(1) },
      { from: 2, to: 2, name: "Second", color: this.getColorForRank(2) },
      { from: 3, to: 3, name: "Third", color: this.getColorForRank(3) },
      { from: 4, to: 4, name: "Fourth", color: this.getColorForRank(4) },
      { from: 5, to: 5, name: "Fifth", color: this.getColorForRank(5) },
    ];

    this.setState({
      options: {
        ...this.state.options,
        xaxis: {
          categories: xaxisCategories,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          categories: nationalities,
          title: {
            text: "Nationality",
          },
        },
        plotOptions: {
          heatmap: {
            ...this.state.options.plotOptions.heatmap,
            colorScale: {
              ranges: colorRanges,
            },
          },
        },
      },
      series: series,
    });
  }

  getColorForRank(rank) {
    switch (rank) {
      case 1:
        return "#003077"; // Color for First
      case 2:
        return "#2a4b7e"; // Color for Second
      case 3:
        return "#7497cc"; // Color for Third
      case 4:
        return "#aec2e0"; // Color for Fourth
      case 5:
        return "#a8b7c2"; // Color for Fifth
      default:
        return "#ffffff"; // Default color
    }
  }

  render() {
    return (
      <div className="heatmap-chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="heatmap"
          height={350}
        />
      </div>
    );
  }
}
export class UnredeemedYear extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;
    const labels = data.map((item) => {
      const [year, month] = item.month.split("-");
      const monthName = new Date(`${year}-${month}-01`).toLocaleString(
        "en-us",
        { month: "long" }
      );
      return `${monthName} ${year}`;
    });

    const series = data.map((item) => item.count);

    this.state = {
      series: [
        {
          name: "Unredeemed Points",
          data: series,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
            isFunnel: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, { dataPointIndex }) {
            const monthYear = labels[dataPointIndex];
            const month = monthYear.split(" ")[0];
            const year = monthYear.split(" ")[1].slice(-2);
            return `${month} '${year}: ${val.toFixed(0)}`;
          },
          dropShadow: {
            enabled: true,
          },
        },
        title: {
          text: "",
          align: "center",
        },
        xaxis: {
          categories: labels,
        },
        yaxis: {
          title: {
            text: "Month",
          },
        },
        legend: {
          show: true,
        },
        colors: ["#004169"],
      },
    };
  }

  render() {
    return (
      <div className="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={500}
        />
      </div>
    );
  }
}

export class RedeemedPointChart extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;
    const labels = ["Current Month", "Last 7 Days", "Daily"];
    const series = [0, 0, 0];

    if (data) {
      series[0] = data.monthlyAmount || 0;
      series[1] = data.weeklyAmount || 0;
      series[2] = data.dailyAmount || 0;
    }

    this.state = {
      series: [
        {
          name: "Redeemed Points",
          data: series,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            barHeight: "80%",
            isFunnel: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ": " + val;
          },
          dropShadow: {
            enabled: true,
          },
        },
        title: {
          text: "Redeemed Points Performance",
          align: "center",
        },
        xaxis: {
          categories: labels,
          title: {
            text: "Month", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
        },
        legend: {
          show: true,
        },
        colors: ["#004169"],
      },
    };
  }
  render() {
    // console.log("Chart data:", this.state.series);

    return (
      <div className="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={300}
        />
      </div>
    );
  }
}
export class ExpiredYesterday extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "To be expire", data: [] },
        { name: "Redeemed", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setState(
        { options: this.getChartOptions(this.props.chartType) },
        this.updateChartData
      );
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "realtime",
        stacked: true,
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#FF0000", "#00FF00"],
      dataLabels: { enabled: false },
      legend: {
        show: true,
        customLegendItems: ["To be expire", "Redeemed"],
        markers: {
          fillColors: ["#FF0000", "#00FF00"],
        },
      },
      xaxis: {
        type: "category",
        categories: [],
        labels: { formatter: (x) => x || "" },
        title: {
          text: "Months", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
          offsetX: -10,
        },
      },
      yaxis: [
        {
          labels: {
            style: { colors: ["#000000"], fontSize: "12px" },
            formatter: (value) => (value !== undefined ? value.toFixed(0) : 0),
          },
          title: {
            text: "Expire & Redeemed Points", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
        },
        
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          endingShape: "rounded",
          distributed: false,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val.toLocaleString()} Points`,
        },
      },
    };

    return {
      ...commonOptions,
      chart: { ...commonOptions.chart, type: chartType },
      stroke: { curve: "straight", width: [2, 0, 0] },
    };
  };

  updateChartData = () => {
    const { data } = this.props;

    if (Array.isArray(data)) {
      const chartOptions = this.getChartOptions(this.props.chartType);
      const categories = [];
      const expiryEarnedPointsData = [];
      const userEarnedData = [];

      data.forEach((item) => {
        const label = `${item.expiry_Month} ${item.expiry_year}`;
        if (!categories.includes(label)) {
          categories.push(label);
        }

        expiryEarnedPointsData.push(item.expiry_earned_points);
        userEarnedData.push(item.userEarned);
      });

      this.setState({
        series: [
          { name: "To be expire", data: expiryEarnedPointsData },
          { name: "Redeemed", data: userEarnedData },
        ],
        options: {
          ...chartOptions,
          xaxis: { ...chartOptions.xaxis, categories },
        },
      });
    } else {
      this.setState({
        series: [
          { name: "To be expire", data: [] },
          { name: "Redeemed", data: [] },
        ],
        options: {
          ...this.getChartOptions(this.props.chartType),
          xaxis: {
            ...this.getChartOptions(this.props.chartType).xaxis,
            categories: [],
          },
        },
      });
    }
  };

  render() {
    const { options, series } = this.state;
    return (
      <Chart
        options={options}
        series={series}
        type={this.props.chartType || "bar"}
        width="100%"
        height={380}
      />
    );
  }
}
export class ExpiredMonth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "Expired Points", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.setupChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setupChartData();
      this.setState({ options: this.getChartOptions(this.props.chartType) });
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "expiring-points-line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#004169", "#00FF00", "#FF0000"], // Colors for Expired Points, Positive Change, and Negative Change
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      markers: {
        size: 0,
        customHTML: [
          () => "<span>Count</span>",
          () =>
            '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          () =>
            '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      xaxis: {
        categories: [],
        title: { text: "Date", offsetY: 70 },
        labels: {
          formatter: (value) => {
            // Convert ISO date string to "Month Day" format
            const date = new Date(value);
            const options = { month: "short", day: "2-digit" };
            return date.toLocaleDateString(undefined, options);
          },
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(0) : "0",
          },
          tickAmount: 3,
          min: 0,
        },
        {
          opposite: true,
          labels: {
            style: {
              colors: ["#FF6347"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(2) + "%" : "0%",
          },
          tickAmount: 3,
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter: (val) => {
            // Format tooltip date
            const date = new Date(val);
            const options = { month: "short", day: "2-digit" };
            return date.toLocaleDateString(undefined, options);
          },
        },
        y: [
          {
            formatter: (val) =>
              val !== undefined ? val + " points" : "0 points",
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          width: [2, 0, 0],
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
      };
    } else {
      return commonOptions;
    }
  };

  setupChartData() {
    const { data } = this.props;

    if (!data || data.length === 0) return;

    // Transform date strings to ISO date format
    const categories = data.map((item) =>
      new Date(item.expiry_Date).toISOString()
    );
    const dataPoints = data.map((item) => item.total_expiring_amount || 0);
    const positivePercentageChangeData = new Array(data.length).fill(0);
    const negativePercentageChangeData = new Array(data.length).fill(0);

    let previousCount = null;

    data.forEach((item, index) => {
      const count = item.total_expiring_amount || 0;

      if (index > 0 && previousCount !== null) {
        const percentageChange =
          previousCount !== 0
            ? ((count - previousCount) / previousCount) * 100
            : 0;

        if (percentageChange >= 0) {
          positivePercentageChangeData[index] = percentageChange;
        } else {
          negativePercentageChangeData[index] = percentageChange;
        }
      }

      previousCount = count;
    });

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
      series: [
        { name: "Expired Points", data: dataPoints },
        { name: "Increased", data: positivePercentageChangeData },
        { name: "Decreased", data: negativePercentageChangeData },
      ],
    }));
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        width="100%"
        height={380}
      />
    );
  }
}

export class Expiring3Month extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "Expired Points", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.setupChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setupChartData();
      this.setState({ options: this.getChartOptions(this.props.chartType) });
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "expiring-points-line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#004169", "#00FF00", "#FF0000"], // Colors for Expired Points, Positive Change, and Negative Change
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      markers: {
        size: 0,
        customHTML: [
          () => "<span>Count</span>",
          () =>
            '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          () =>
            '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      xaxis: {
        categories: [],
        title: { text: "Month", offsetY: 70 },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(0) : "0",
          },
          tickAmount: 3,
          min: 0,
        },
        {
          opposite: true,
          labels: {
            style: {
              colors: ["#FF6347"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(2) + "%" : "0%",
          },
          tickAmount: 3,
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: [
          {
            formatter: (val) =>
              val !== undefined ? val + " points" : "0 points",
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          width: [2, 0, 0],
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
      };
    } else {
      return commonOptions;
    }
  };

  setupChartData() {
    const { data } = this.props;

    if (!data || data.length === 0) return;

    const categories = data.map(
      (item) => `${item.expiry_Month} ${item.expiry_year}`
    );
    const dataPoints = data.map((item) => item.total_expiring_amount);
    const positivePercentageChangeData = new Array(data.length).fill(0);
    const negativePercentageChangeData = new Array(data.length).fill(0);

    let previousCount = null;

    data.forEach((item, index) => {
      const count = item.total_expiring_amount;

      if (index > 0 && previousCount !== null) {
        const percentageChange =
          previousCount !== 0
            ? ((count - previousCount) / previousCount) * 100
            : 0;

        if (percentageChange >= 0) {
          positivePercentageChangeData[index] = percentageChange;
        } else {
          negativePercentageChangeData[index] = percentageChange;
        }
      }

      previousCount = count;
    });

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
      series: [
        { name: "Expired Points", data: dataPoints },
        { name: "Increased", data: positivePercentageChangeData },
        { name: "Decreased", data: negativePercentageChangeData },
      ],
    }));
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        width="100%"
        height={380}
      />
    );
  }
}
export class UnredeemedYearBar extends React.Component {
  constructor(props) {
    super(props);

    const data = this.props.data;
    const labels = data.map((item) => {
      const [year, month] = item.month.split("-");
      const monthName = new Date(`${year}-${month}-01`).toLocaleString(
        "en-us",
        { month: "long" }
      );
      return `${monthName} ${year}`;
    });

    const series = data.map((item) => item.count);

    this.state = {
      series: [
        {
          name: "Unredeemed Points",
          data: series,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val.toLocaleString(); // Format value with comma separation
          },
          dropShadow: {
            enabled: false,
          },
        },
        title: {
          text: "",
          align: "center",
        },
        xaxis: {
          categories: labels,
          title: {
            text: "Months",
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetY: -50
          },
        },
        yaxis: {
          title: {
            text: " Unredeemed Points",
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10
          },
        },
        legend: {
          show: true,
        },
        colors: ["#004169"],
      },
    };
  }

  render() {
    return (
      <div className="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={500}
        />
      </div>
    );
  }
}
export class AmountComparison extends React.Component {
  constructor(props) {
    super(props);

    // Provided data
    const data = this.props.data;

    this.state = {
      series: [
        {
          name: "Amount",
          data: [data.monthlyAmount, data.weeklyAmount, data.dailyAmount],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "60%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "",
          align: "center",
        },
        xaxis: {
          categories: ["Monthly Amount", "Weekly Amount", "Daily Amount"],
          title: {
            text: "Daily&Week&Month", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
          
          },
        },
        yaxis: {
          title: {
            text: "Redeemed Points Amount", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
        },
        colors: ["#004169"],
        legend: {
          show: false,
        },
      },
    };
  }

  render() {
    return (
      <div className="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}
export class NoOfTranscation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "Transaction Amount", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.setupChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setupChartData();
      this.setState({ options: this.getChartOptions(this.props.chartType) });
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "expiring-points-line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#004169", "#00FF00", "#FF0000"], // Colors for Expired Points, Positive Change, and Negative Change
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      markers: {
        size: 0,
        customHTML: [
          () => "<span>Count</span>",
          () =>
            '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          () =>
            '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      xaxis: {
        categories: [],
        title: {
          text: "Hours", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
          offsetX: -10,
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(0) : "0",
          },
          title: {
            text: "Transactions Amount", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
        },
        // {
        //   opposite: true,
        //   labels: {
        //     style: {
        //       colors: ["#FF6347"],
        //       fontSize: "12px",
        //     },
        //     formatter: (value) =>
        //       value !== undefined ? value.toFixed(2) + "%" : "0%",
        //   },
        //   tickAmount: 3,
        // },
      ],
      tooltip: {
        // shared: true,
        // intersect: false,
        y: [
          {
            formatter: (val) =>
              val !== undefined ? val + " points" : "0 points",
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          width: [2, 0, 0],
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
      };
    } else {
      return commonOptions;
    }
  };

  setupChartData() {
    const { data } = this.props;

    if (!data || data.length === 0) return;

    const categories = data.map((item) =>
      item.hour !== null ? item.hour.toString() : 0
    );
    const dataPoints = data.map((item) => item.amount);
    const positivePercentageChangeData = new Array(data.length).fill(0);
    const negativePercentageChangeData = new Array(data.length).fill(0);

    let previousCount = null;

    data.forEach((item, index) => {
      const count = item.amount;

      if (index > 0 && previousCount !== null) {
        const percentageChange =
          previousCount !== 0
            ? ((count - previousCount) / previousCount) * 100
            : 0;

        if (percentageChange >= 0) {
          positivePercentageChangeData[index] = percentageChange;
        } else {
          negativePercentageChangeData[index] = percentageChange;
        }
      }

      previousCount = count;
    });

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
      series: [
        { name: "Transaction Amount", data: dataPoints },

        // {
        //   name: "Increased",
        //   data: positivePercentageChangeData,
        //   type: 'line',
        // },
        // {
        //   name: "Decreased",
        //   data: negativePercentageChangeData,
        //   type: 'line',
        // },
      ],
    }));
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        width="100%"
        height={400}
      />
    );
  }
}
export class NoOfTranscationWeekly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "Transaction Amount", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.setupChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setupChartData();
      this.setState({ options: this.getChartOptions(this.props.chartType) });
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "expiring-points-line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#004169", "#00FF00", "#FF0000"], // Colors for Expired Points, Positive Change, and Negative Change
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      markers: {
        size: 0,
        customHTML: [
          () => "<span>Count</span>",
          () =>
            '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          () =>
            '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      xaxis: {
        categories: [],
        title: {
          text: "Last 7 Days", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
          offsetX: -10,
        },
        labels: {
          formatter: (value) => {
            // Display full day name
            const date = new Date(value);
            const days = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
            return days[date.getDay()];
          },
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(0) : "0",
          },
          title: {
            text: "Transactions Amount", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
        },
        // {
        //   opposite: true,
        //   labels: {
        //     style: {
        //       colors: ["#FF6347"],
        //       fontSize: "12px",
        //     },
        //     formatter: (value) =>
        //       value !== undefined ? value.toFixed(2) + "%" : "0%",
        //   },
        //   tickAmount: 3,
        // },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter: (val) => {
            // Tooltip date format
            const date = new Date(val);
            const days = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
            return days[date.getDay()];
          },
        },
        y: [
          {
            formatter: (val) =>
              val !== undefined ? val + " points" : "0 points",
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          width: [2, 0, 0],
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
      };
    } else {
      return commonOptions;
    }
  };

  setupChartData() {
    const { data } = this.props;

    if (!data || data.length === 0) return;

    // Convert dates to a format ApexCharts can understand
    const categories = data.map((item) => new Date(item.day).toISOString());
    const dataPoints = data.map((item) => item.amount || 0);
    const positivePercentageChangeData = new Array(data.length).fill(0);
    const negativePercentageChangeData = new Array(data.length).fill(0);

    let previousCount = null;

    data.forEach((item, index) => {
      const count = item.amount || 0;

      if (index > 0 && previousCount !== null) {
        const percentageChange =
          previousCount !== 0
            ? ((count - previousCount) / previousCount) * 100
            : 0;

        if (percentageChange >= 0) {
          positivePercentageChangeData[index] = percentageChange;
        } else {
          negativePercentageChangeData[index] = percentageChange;
        }
      }

      previousCount = count;
    });

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
      series: [
        { name: "Transaction Amount", data: dataPoints },

        // {
        //   name: "Increased",
        //   data: positivePercentageChangeData,
        //   type: 'line',
        // },
        // {
        //   name: "Decreased",
        //   data: negativePercentageChangeData,
        //   type: 'line',
        // },
      ],
    }));
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        width="100%"
        height={380}
      />
    );
  }
}

export class NoOfTranscationMonthly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getChartOptions(props.chartType),
      series: [
        { name: "Transaction Amount", data: [] },
        { name: "Increased", data: [] },
        { name: "Decreased", data: [] },
      ],
    };
  }

  componentDidMount() {
    this.setupChartData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.setupChartData();
      this.setState({ options: this.getChartOptions(this.props.chartType) });
    }
  }

  getChartOptions = (chartType) => {
    const commonOptions = {
      chart: {
        id: "expiring-points-line-chart",
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
      },
      colors: ["#004169", "#00FF00", "#FF0000"], // Colors for Expired Points, Positive Change, and Negative Change
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        customLegendItems: [
          "<span>Count</span>",
          '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      markers: {
        size: 0,
        customHTML: [
          () => "<span>Count</span>",
          () =>
            '<span><img src="images/up.png" alt="Increased" style="width:16px; height:16px;" /> Increased</span>',
          () =>
            '<span><img src="images/decrease.png" alt="Decreased" style="width:16px; height:16px;" /> Decreased</span>',
        ],
      },
      xaxis: {
        categories: [],
        title: {
          text: "Month", // Label for y-axis
          style: {
            colors: ["#ba54f5"],
            fontWeight:"600",
            fontSize: "15px",
            fontFamily:"outfit,sans-serif" // Customize the font size if needed
          },
          offsetX: -10,
        },
        labels: {
          formatter: (value) => {
            // Display date in the format "Month Day" (e.g., "Jul 05")
            const date = new Date(value);
            const options = { month: "short", day: "2-digit" };
            return date.toLocaleDateString(undefined, options);
          },
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: ["#000000"],
              fontSize: "12px",
            },
            formatter: (value) =>
              value !== undefined ? value.toFixed(0) : "0",
          },
          title: {
            text: "Transactions Amount", // Label for y-axis
            style: {
              colors: ["#ba54f5"],
              fontWeight:"600",
              fontSize: "15px",
              fontFamily:"outfit,sans-serif" // Customize the font size if needed
            },
            offsetX: -10,
          },
          tickAmount: 3,
          min: 0,
        },
        // {
        //   opposite: true,
        //   labels: {
        //     style: {
        //       colors: ["#FF6347"],
        //       fontSize: "12px",
        //     },
        //     formatter: (value) =>
        //       value !== undefined ? value.toFixed(2) + "%" : "0%",
        //   },
        //   tickAmount: 3,
        // },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter: (val) => {
            // Tooltip date format
            const date = new Date(val);
            const options = { month: "short", day: "2-digit" };
            return date.toLocaleDateString(undefined, options);
          },
        },
        y: [
          {
            formatter: (val) =>
              val !== undefined ? val + " points" : "0 points",
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/arrow.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
          {
            formatter: (val) => {
              if (val && val !== 0) {
                const icon =
                  '<img src="images/down.png" alt="Decrease Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                return `${val.toFixed(2)}% ${icon}`;
              }
              return null;
            },
          },
        ],
      },
    };

    if (chartType === "line") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "line",
        },
        stroke: {
          width: [2, 0, 0],
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
      };
    } else if (chartType === "bar") {
      return {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
      };
    } else {
      return commonOptions;
    }
  };

  setupChartData() {
    const { data } = this.props;

    if (!data || data.length === 0) return;

    // Convert dates to the desired format for ApexCharts
    const categories = data.map((item) => new Date(item.day).toISOString());
    const dataPoints = data.map((item) => item.amount || 0);
    const positivePercentageChangeData = new Array(data.length).fill(0);
    const negativePercentageChangeData = new Array(data.length).fill(0);

    let previousCount = null;

    data.forEach((item, index) => {
      const count = item.amount || 0;

      if (index > 0 && previousCount !== null) {
        const percentageChange =
          previousCount !== 0
            ? ((count - previousCount) / previousCount) * 100
            : 0;

        if (percentageChange >= 0) {
          positivePercentageChangeData[index] = percentageChange;
        } else {
          negativePercentageChangeData[index] = percentageChange;
        }
      }

      previousCount = count;
    });

    this.setState((prevState) => ({
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
      series: [
        { name: "Transaction Amount", data: dataPoints },
        // {
        //   name: "Increased",
        //   data: positivePercentageChangeData,
        //   type: 'line',
        // },
        // {
        //   name: "Decreased",
        //   data: negativePercentageChangeData,
        //   type: 'line',
        // },
      ],
    }));
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        width="100%"
        height={400}
      />
    );
  }
}

export class ValueSalesDaily extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: this.getChartOptions(props.chartType),
    };
  }

  componentDidMount() {
    this.updateSeries(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.updateSeries(this.props.data);
    }
  }

  updateSeries(data) {
    const { series, colors } = this.processData(data);
    this.setState({
      series,
      options: {
        ...this.state.options,
        colors: colors,
        chart: {
          ...this.state.options.chart,
          type: this.props.chartType || "bar",
        },
      },
    });
  }

  processData(data) {
    const categoryMap = new Map();
    const categoryColors = [];

    data.forEach((item) => {
      item.data.forEach((entry) => {
        if (!categoryMap.has(entry.categoryName)) {
          categoryMap.set(entry.categoryName, new Array(24).fill(0));
          categoryColors.push(this.getRandomColor()); // Assign random color
        }
      });
    });

    data.forEach((item) => {
      item.data.forEach((entry) => {
        if (categoryMap.has(entry.categoryName)) {
          const categoryData = categoryMap.get(entry.categoryName);
          categoryData[item.hour] = entry.amount;
          categoryMap.set(entry.categoryName, categoryData);
        }
      });
    });

    // Convert map to series format
    const series = Array.from(categoryMap, ([name, data]) => ({
      name,
      data,
    }));

    return { series, colors: categoryColors };
  }

  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getChartOptions(chartType) {
    const commonOptions = {
      chart: {
        height: 450,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        type: chartType || "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
          endingShape: "rounded",
          borderRadius: 10,
        },
        line: {
          curve: "smooth",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: this.generateXAxisLabels(),
      },
      legend: {
        position: "bottom",
      },
      fill: {
        opacity: 1,
      },
    };

    return commonOptions;
  }

  generateXAxisLabels() {
    const labels = [];
    const startHour = 0;
    const endHour = 23;

    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 24 === 0 ? 12 : i % 12;
      const period = i < 12 || i === 24 ? "AM" : "PM";
      const label = `${hour === 0 ? 12 : hour} ${period}`;
      labels.push(label);
    }

    return labels;
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type={this.props.chartType || "bar"}
          height={450}
        />
      </div>
    );
  }
}
export class ValueSalesWeekly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: this.getChartOptions(props.chartType),
    };
  }

  componentDidMount() {
    this.updateSeries(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.updateSeries(this.props.data);
    }
  }

  updateSeries(data) {
    const categoryMap = new Map();
    const labels = this.generateXAxisLabels("lastYear");

    data.forEach((item) => {
      item.data.forEach((entry) => {
        if (!categoryMap.has(entry.categoryName)) {
          categoryMap.set(entry.categoryName, new Array(labels.length).fill(0));
        }
      });
    });

    data.forEach((item) => {
      const monthLabel = new Date(item.month).toLocaleDateString("default", {
        year: "numeric",
        month: "short",
      });
      const index = labels.indexOf(monthLabel);

      item.data.forEach((entry) => {
        if (categoryMap.has(entry.categoryName)) {
          const categoryData = categoryMap.get(entry.categoryName);
          if (index >= 0 && index < categoryData.length) {
            categoryData[index] = entry.amount;
          }
          categoryMap.set(entry.categoryName, categoryData);
        }
      });
    });

    const series = Array.from(categoryMap, ([name, data]) => ({
      name,
      data,
    }));

    // Calculate percentage changes
    const seriesWithChanges = series.map(({ name, data }) => {
      const percentageChanges = data.map((value, index) => {
        if (index === 0) return 0;
        const previousValue = data[index - 1];
        if (previousValue === 0) return 0;
        return ((value - previousValue) / previousValue) * 100;
      });

      return {
        name,
        data,
        percentageChanges,
      };
    });

    this.setState({
      series: seriesWithChanges,
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: labels,
        },
        chart: {
          ...this.state.options.chart,
          type: this.props.chartType || "bar",
        },
        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (value, { seriesIndex, dataPointIndex, w }) => {
              const seriesData = seriesWithChanges[seriesIndex];
              if (!seriesData || !seriesData.percentageChanges) return value;

              const percentageChange =
                seriesData.percentageChanges[dataPointIndex];
              const icon =
                percentageChange > 0
                  ? '<img src="images/up.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>'
                  :  '<img src="images/down.png" alt="Decreased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
              return `${value} (${percentageChange.toFixed(2)}% ${icon})`;
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            const seriesData = seriesWithChanges[seriesIndex];
            const percentageChange =
              seriesData.percentageChanges[dataPointIndex];
            const icon = percentageChange > 0 ? "▲" : "▼";
            return `${value} (${icon} ${percentageChange.toFixed(2)}%)`;
          },
          style: {
            colors: ["#000"],
            fontSize: "12px",
          },
          offsetY: -10,
        },
      },
    });
  }

  generateXAxisLabels(type) {
    const labels = [];
    const today = new Date();

    if (type === "lastYear") {
      const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1);
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const month = currentDate.toLocaleString("default", { month: "short" });
        const year = currentDate.getFullYear();
        labels.push(`${month} ${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }

    return labels;
  }

  getChartOptions(chartType) {
    return {
      chart: {
        height: 450,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        type: chartType || "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "10%",
          distributed: false,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: this.generateXAxisLabels("lastYear"),
        tickPlacement: "on",
      },
      legend: {
        show: true,
        position: "bottom",
      },
      fill: {
        opacity: 1,
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type={this.props.chartType || "bar"}
          height={450}
        />
      </div>
    );
  }
}
export class ValueSalesMonthly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: this.getChartOptions(props.chartType),
    };
  }

  componentDidMount() {
    this.updateSeries(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.chartType !== this.props.chartType
    ) {
      this.updateSeries(this.props.data);
    }
  }

  getCurrentAndPreviousPeriod() {
    const now = new Date();
    const currentMonthYear =
      now.toLocaleString("default", { month: "long" }) +
      " " +
      now.getFullYear();
    const previousYear = now.getFullYear() - 1;
    const previousMonthYear =
      now.toLocaleString("default", { month: "long" }) + " " + previousYear;
    return [currentMonthYear, previousMonthYear];
  }

  updateSeries(data) {
    if (!Array.isArray(data) || data.length === 0) return;

    const categories = [];
    const periodMap = new Map();

    data.forEach((item) => {
      if (item.data && Array.isArray(item.data)) {
        item.data.forEach((entry) => {
          if (entry && entry.x && !categories.includes(entry.x)) {
            categories.push(entry.x);
          }
        });
      }
    });

    data.forEach((item) => {
      const periodName = item.period;
      if (item.data && Array.isArray(item.data)) {
        periodMap.set(
          periodName,
          item.data.reduce((acc, entry) => {
            if (entry && entry.x) {
              acc[entry.x] = entry.y || 0;
            }
            return acc;
          }, {})
        );
      }
    });

    const [currentPeriod, previousPeriod] = this.getCurrentAndPreviousPeriod();

    const series = categories.map((category) => {
      const currentValue = periodMap.get(currentPeriod)?.[category] || 0;
      const previousValue = periodMap.get(previousPeriod)?.[category] || 0;

      // Calculate percentage change
      const percentageChange =
        previousValue !== 0
          ? ((currentValue - previousValue) / previousValue) * 100
          : 0;

      return {
        name: category,
        data: [currentValue, previousValue],
        percentageChange,
      };
    });

    this.setState({
      series,
      options: {
        ...this.state.options,
        chart: {
          ...this.state.options.chart,
          type: this.props.chartType || "line",
        },
        xaxis: {
          categories: ["Current Month", "Previous Year"],
          labels: {
            style: {
              fontSize: "14px",
              fontWeight: 500,
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value, { seriesIndex, dataPointIndex, w }) => {
              const seriesData = this.state.series[seriesIndex];
              const percentageChange = seriesData.percentageChange || 0;
              const icon =
                percentageChange > 0
                  ? '<img src="images/up.png" alt="Increased Icon" style="width:16px;height:16px;margin-right:4px;"/>'
                  : '<img src="images/down.png" alt="Decreased Icon" style="width:16px;height:16px;margin-right:4px;"/>';
                 
              return  percentageChange > 0?`${value} (${percentageChange.toFixed(2)}% ${icon})`:value;
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            const seriesData = this.state.series[seriesIndex];
            const percentageChange = seriesData.percentageChange || 0;
            const icon = percentageChange > 0 ? "▲" : "▼";
            return `${value} (${icon} ${percentageChange.toFixed(2)}%)`;
          },
          style: {
            colors: ["#000"],
            fontSize: "12px",
          },
        },
      },
    });
  }

  getChartOptions(chartType) {
    return {
      chart: {
        type: chartType || "line",
        height: 450,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
       
        bar: {
          columnWidth: "10%",
          distributed: false,
          endingShape: "rounded",
        },
      },
      tooltip: {
        followCursor: true,
        intersect: false,
        shared: true,
      },
      dataLabels: {
        background: {
          enabled: true,
        },
        formatter(val, opts) {
          const seriesName = opts.w.config.series[opts.seriesIndex].name;
          return val !== null ? `${seriesName}: ${val}` : "";
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
        },
        title: {
          text: "Amount",
        },
      },
      xaxis: {
        categories: [], // Will be dynamically set
        title: {
          text: "Period",
        },
        position: "bottom",
        labels: {
          style: {
            fontSize: "14px", // Adjust the font size here
            fontFamily: "Arial, sans-serif",
            fontWeight: 400,
          },
        },
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      stroke: {
        width: [2, 2],
        dashArray: [0, 5],
        curve: "smooth",
      },
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={this.props.chartType || "line"}
        height={380}
      />
    );
  }
}

export class TopSubCategoriesMonthly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "line",
          height: 450,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          line: {
            isSlopeChart: true,
          },
        },
        tooltip: {
          followCursor: true,
          intersect: false,
          shared: true,
        },
        dataLabels: {
          background: {
            enabled: true,
          },
          formatter(val, opts) {
            const seriesName = opts.w.config.series[opts.seriesIndex].name;
            return val !== null ? `${seriesName}: ${val}` : "";
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
          },
          title: {
            text: "Amount",
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: "Period",
          },
          position: "bottom",
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        stroke: {
          width: [2, 2],
          dashArray: [0, 5],
          curve: "smooth",
        },
      },
    };
  }

  componentDidMount() {
    this.updateSeries(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateSeries(this.props.data);
    }
  }

  getCurrentAndPreviousPeriod() {
    const now = new Date();
    const currentMonthYear =
      now.toLocaleString("default", { month: "long" }) +
      " " +
      now.getFullYear();
    const previousYear = now.getFullYear() - 1;
    const previousMonthYear =
      now.toLocaleString("default", { month: "long" }) + " " + previousYear;
    return [currentMonthYear, previousMonthYear];
  }

  updateSeries(data) {
    if (!Array.isArray(data) || data.length === 0) return;

    const categories = [];
    const periodMap = new Map();

    data.forEach((item) => {
      if (item.data && Array.isArray(item.data)) {
        item.data.forEach((entry) => {
          if (entry && entry.x && !categories.includes(entry.x)) {
            categories.push(entry.x);
          }
        });
      }
    });

    data.forEach((item) => {
      const periodName = item.period;
      if (item.data && Array.isArray(item.data)) {
        periodMap.set(
          periodName,
          item.data.reduce((acc, entry) => {
            if (entry && entry.x) {
              acc[entry.x] = entry.y || 0;
            }
            return acc;
          }, {})
        );
      }
    });

    const [currentPeriod, previousPeriod] = this.getCurrentAndPreviousPeriod();

    const series = categories.map((category) => ({
      name: category,
      data: [currentPeriod, previousPeriod].map(
        (period) => periodMap.get(period)[category] || 0
      ),
    }));

    this.setState({
      series,
      options: {
        ...this.state.options,
        xaxis: {
          categories: ["Current Month", "Previous Year"],
        },
      },
    });
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="line"
        height={380}
      />
    );
  }
}

function generateData(start, end, yrange) {
  let baseval = start;
  const series = [];

  while (baseval <= end) {
    const x = baseval;
    const y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    const z =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y, z]);
    baseval += 86400000; // increment by 1 day
  }

  return series;
}
export class Bubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Product1",
          data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
            min: 10,
            max: 60,
          }),
        },
        {
          name: "Product2",
          data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
            min: 10,
            max: 60,
          }),
        },
        {
          name: "Product3",
          data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
            min: 10,
            max: 60,
          }),
        },
        {
          name: "Product4",
          data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
            min: 10,
            max: 60,
          }),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bubble",
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        title: {
          text: " Chart",
        },
        xaxis: {
          tickAmount: 12,
          type: "datetime",
          labels: {
            rotate: 0,
          },
        },
        yaxis: {
          max: 70,
        },
        theme: {
          palette: "palette2",
        },
      },
    };
  }

  render() {
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bubble"
          height={350}
        />
      </>
    );
  }
}
export class DummbleDaily extends React.Component {
  constructor(props) {
    super(props);

    const generateTimeLabels = () => {
      const labels = [];
      let currentTime = new Date();
      currentTime.setHours(12, 0, 0, 0);
      for (let i = 0; i < 13; i++) {
        labels.push(currentTime.toTimeString().slice(0, 5));
        currentTime.setHours(currentTime.getHours() + 1);
      }
      return labels;
    };

    const timeLabels = generateTimeLabels();

    this.state = {
      series: [
        {
          name: "Brand A",
          data: [
            { x: timeLabels[0], y: [2800, 4500] },
            { x: timeLabels[1], y: [3200, 4100] },
            { x: timeLabels[2], y: [2950, 7800] },
            { x: timeLabels[3], y: [3000, 4600] },
            { x: timeLabels[4], y: [3500, 4100] },
            { x: timeLabels[5], y: [4500, 6500] },
            { x: timeLabels[6], y: [4100, 5600] },
          ],
        },
        {
          name: "Brand B",
          data: [],
        },
        {
          name: "Brand C",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "5%",
            dumbbellColors: [["#008FFB", "#00E396"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands",
          align: "left",
        },
        xaxis: {
          categories: timeLabels,
          title: {
            text: "Hour",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value;
            },
          },
        },
        legend: {
          position: "right",
        },
      },
    };
  }

  render() {
    return (
      <Chart
        series={this.state.series}
        options={this.state.options}
        height={350}
        type="rangeBar"
      />
    );
  }
}

export class DummbleWeekly extends React.Component {
  constructor(props) {
    super(props);

    const generateDayLabels = () => {
      const labels = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = subDays(today, i);
        labels.push(format(date, "EEE"));
      }
      return labels;
    };

    const dayLabels = generateDayLabels();

    this.state = {
      series: [
        {
          name: "Brand A",
          data: [
            { x: dayLabels[0], y: [2800, 4500] },
            { x: dayLabels[1], y: [3200, 4100] },
            { x: dayLabels[2], y: [2950, 7800] },
            { x: dayLabels[3], y: [3000, 4600] },
            { x: dayLabels[4], y: [3500, 4100] },
            { x: dayLabels[5], y: [4500, 6500] },
            { x: dayLabels[6], y: [4100, 5600] },
          ],
        },
        {
          name: "Brand B",
          data: [
            { x: dayLabels[0], y: [2800, 4500] },
            { x: dayLabels[1], y: [3200, 4100] },
            { x: dayLabels[2], y: [2950, 7800] },
            { x: dayLabels[3], y: [3000, 4600] },
            { x: dayLabels[4], y: [3500, 4100] },
          ],
        },
        {
          name: "Brand C",
          data: [
            { x: dayLabels[0], y: [28, 450] },
            { x: dayLabels[1], y: [320, 410] },
            { x: dayLabels[2], y: [250, 780] },
            { x: dayLabels[3], y: [300, 460] },
            { x: dayLabels[4], y: [350, 4100] },
          ],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "5%",
            dumbbellColors: [["#008FFB", "#00E396"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands",
          align: "left",
        },
        xaxis: {
          categories: dayLabels,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value;
            },
          },
        },
        legend: {
          position: "right",
          labels: {
            colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
          },
          itemMargin: {
            horizontal: 20,
            vertical: 8,
          },
        },
      },
    };
  }

  render() {
    return (
      <>
        <Chart
          series={this.state.series}
          options={this.state.options}
          height={350}
          type="rangeBar"
        />
      </>
    );
  }
}

export class DummbleMonth extends React.Component {
  constructor(props) {
    super(props);

    const generateDayLabels = () => {
      const labels = [];
      const today = new Date();
      const start = startOfMonth(today);
      const end = endOfMonth(today);
      const days = eachDayOfInterval({ start, end });

      days.forEach((day) => {
        labels.push(format(day, "MMM dd"));
      });

      return labels;
    };

    const dayLabels = generateDayLabels();

    const generateRandomValues = () => {
      return {
        productA: Math.random() * 1000,
        productB: Math.random() * 1000,
        productC: Math.random() * 1000,
        productD: Math.random() * 1000,
      };
    };

    const seriesData = dayLabels.map((label) => {
      const { productA, productB, productC, productD } = generateRandomValues();

      const max = Math.max(productA, productB, productC, productD);
      let topProduct;
      if (max === productA) {
        topProduct = "Product A";
      } else if (max === productB) {
        topProduct = "Product B";
      } else if (max === productC) {
        topProduct = "Product C";
      } else {
        topProduct = "Product D";
      }

      return {
        x: label,
        y: [
          topProduct === "Product A" ? productA : 0,
          topProduct === "Product B" ? productB : 0,
          topProduct === "Product C" ? productC : 0,
          topProduct === "Product D" ? productD : 0,
        ],
      };
    });

    this.state = {
      series: [
        {
          data: seriesData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "10%",
            dumbbellColors: [["#008FFB", "#00E396", "#FEB019", "#FF4560"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Brands",
          align: "left",
        },
        xaxis: {
          categories: dayLabels,
          title: {
            text: "Date",
          },
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
          labels: {
            formatter: function (value) {
              return Math.floor(value);
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return Math.floor(value);
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "right",
          horizontalAlign: "left",
          onItemClick: {
            toggleDataSeries: true,
          },
          customLegendItems: [
            "Product A",
            "Product B",
            "Product C",
            "Product D",
          ],
        },
      },
    };
  }

  render() {
    return (
      <Chart
        series={this.state.series}
        options={this.state.options}
        height={350}
        type="rangeBar"
      />
    );
  }
}

export class Top5ByNationalitiesDaily extends React.Component {
  constructor(props) {
    super(props);

    const generateTimeLabels = () => {
      const labels = [];
      let currentTime = new Date();
      currentTime.setHours(12, 0, 0, 0);
      for (let i = 0; i < 13; i++) {
        labels.push(currentTime.toTimeString().slice(0, 5));
        currentTime.setHours(currentTime.getHours() + 1);
      }
      return labels;
    };

    const timeLabels = generateTimeLabels();

    this.state = {
      series: [
        {
          name: "India",
          data: [
            { x: timeLabels[0], y: [2800, 4500] },
            { x: timeLabels[1], y: [3200, 4100] },
            { x: timeLabels[2], y: [2950, 7800] },
            { x: timeLabels[3], y: [3000, 4600] },
            { x: timeLabels[4], y: [3500, 4100] },
            { x: timeLabels[5], y: [4500, 6500] },
            { x: timeLabels[6], y: [4100, 5600] },
          ],
        },
        {
          name: "Zimbabwe",
          data: [
            // Add data for Zimbabwe if available
          ],
        },
        {
          name: "Finland C",
          data: [
            // Add data for Finland C if available
          ],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "5%",
            dumbbellColors: [["#008FFB", "#00E396"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Nationalities",
          align: "left",
        },
        xaxis: {
          categories: timeLabels,
          title: {
            text: "Hour",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const nationalityName = w.config.series[seriesIndex].name;
            const yValue = w.globals.series[seriesIndex][dataPointIndex];
            return (
              '<div class="tooltip-custom">' +
              '<span class="tooltip-header">' +
              nationalityName +
              "</span>" +
              '<span class="tooltip-value">Amount: ' +
              yValue +
              "</span>" +
              "</div>"
            );
          },
        },
        legend: {
          position: "right",
        },
      },
    };
  }

  render() {
    return (
      <Chart
        series={this.state.series}
        options={this.state.options}
        height={350}
        type="rangeBar"
      />
    );
  }
}

export class Top5ByNationalitiesWeekly extends React.Component {
  constructor(props) {
    super(props);

    const generateDayLabels = () => {
      const labels = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = subDays(today, i);
        labels.push(format(date, "EEE"));
      }
      return labels;
    };

    const dayLabels = generateDayLabels();

    this.state = {
      series: [
        {
          name: "India",
          data: [
            { x: dayLabels[0], y: [0, 4500] },
            { x: dayLabels[1], y: [0, 4100] },
            { x: dayLabels[2], y: [0, 7800] },
            { x: dayLabels[3], y: [0, 4600] },
            { x: dayLabels[4], y: [0, 4100] },
            { x: dayLabels[5], y: [0, 6500] },
            { x: dayLabels[6], y: [0, 5600] },
          ],
        },
        {
          name: "Zimbabwe",
          data: [
            { x: dayLabels[0], y: [0, 4500] },
            { x: dayLabels[1], y: [0, 410] },
            { x: dayLabels[2], y: [0, 7800] },
            { x: dayLabels[3], y: [0, 400] },
            { x: dayLabels[4], y: [0, 4100] },
          ],
        },
        {
          name: "Malesiya",
          data: [
            { x: dayLabels[0], y: [0, 4500] },
            { x: dayLabels[1], y: [0, 4100] },
            { x: dayLabels[2], y: [0, 7800] },
            { x: dayLabels[3], y: [0, 4600] },
            { x: dayLabels[4], y: [0, 4100] },
          ],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "50%",
            dumbbellColors: [["#008FFB", "#00E396"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Nationalities",
          align: "left",
        },
        xaxis: {
          categories: dayLabels,
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value;
            },
          },
        },
        legend: {
          position: "right",
          labels: {
            colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
          },
          itemMargin: {
            horizontal: 20,
            vertical: 8,
          },
        },
      },
    };
  }

  render() {
    return (
      <>
        <Chart
          series={this.state.series}
          options={this.state.options}
          height={350}
          type="rangeBar"
        />
      </>
    );
  }
}

export class Top5ByNationalitiesMonthly extends React.Component {
  constructor(props) {
    super(props);

    const generateDayLabels = () => {
      const labels = [];
      const today = new Date();
      const start = startOfMonth(today);
      const end = endOfMonth(today);
      const days = eachDayOfInterval({ start, end });

      days.forEach((day) => {
        labels.push(format(day, "MMM dd"));
      });

      return labels;
    };

    const dayLabels = generateDayLabels();

    const generateRandomValues = () => {
      return {
        productA: Math.random() * 1000,
        productB: Math.random() * 1000,
        productC: Math.random() * 1000,
        productD: Math.random() * 1000,
      };
    };

    const seriesData = dayLabels.map((label) => {
      const { productA, productB, productC, productD } = generateRandomValues();

      const max = Math.max(productA, productB, productC, productD);
      let topProduct;
      if (max === productA) {
        topProduct = "Product A";
      } else if (max === productB) {
        topProduct = "Product B";
      } else if (max === productC) {
        topProduct = "Product C";
      } else {
        topProduct = "Product D";
      }

      return {
        x: label,
        y: [
          topProduct === "Product A" ? productA : 0,
          topProduct === "Product B" ? productB : 0,
          topProduct === "Product C" ? productC : 0,
          topProduct === "Product D" ? productD : 0,
        ],
      };
    });

    this.state = {
      series: [
        {
          data: seriesData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "rangeBar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: "10%",
            dumbbellColors: [["#008FFB", "#00E396", "#FEB019", "#FF4560"]],
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Top Selling Nationalities",
          align: "left",
        },
        xaxis: {
          categories: dayLabels,
          title: {
            text: "Date",
          },
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
          labels: {
            formatter: function (value) {
              return Math.floor(value);
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return Math.floor(value);
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "right",
          horizontalAlign: "left",
          onItemClick: {
            toggleDataSeries: true,
          },
          customLegendItems: ["Zimbabwe ", "United Kingdom", "Japan", "India "],
        },
      },
    };
  }

  render() {
    return (
      <Chart
        series={this.state.series}
        options={this.state.options}
        height={350}
        type="rangeBar"
      />
    );
  }
}
