import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import LoaderOwn from "../common/loaderOwn";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import {
  competitionEdit,
  subCategoryList,
  categoryList,
  productDropDownList,
} from "../api/apiIntegration";
import { getUser } from "../auth/authService";
import Select from "react-select";
import ImageModal from "./components/image_model";

const Editcompetition = ({
  load,
  show,
  onClose,
  competitionData,
  onUpdate,
  onAdd,
}) => {
  const [competition, setCompetition] = useState(competitionData);
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const [title, setTitle] = useState(competition.title);
  const [subTitle, setSubTitle] = useState(competition.subTitle);
  const [startDate, setStartDate] = useState(competition.startDate);
  const [endDate, setEndDate] = useState(competition.endDate);
  const [rules, setRules] = useState(competition.rules);
  const [prize, setPrize] = useState(competition.prize);
  const [isFeatured, setIsFeatured] = useState(competition.isFeatured);
  const [imagePreview, setImagePreview] = useState(
    competition.competitionImageUrl
  );
  const [maxWinners, setMaxWinners] = useState(competition.maxWinners);
  const [modalShow, setModalShow] = useState(false);
  const [fileImage, setFileImage] = useState(null);
  const [remainingChars, setRemainingChars] = useState(190 - competition.title.length);
  const [ruleItems, setRuleItems] = useState(
    competition.competitionRules.map((rule) => ({
      categoryId: rule.categoryId,
      subCategoryId: rule.subCategoryId,
      productId: rule.productId,
      quantity: rule.quantity,
      groupId: rule.groupId,
      posItemUnit: rule.posItemUnit,
    }))
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [ruleItemOptions, setRuleItemOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [IsEnabled, setIsEnabled] = useState(competition.isEnabled);

  useEffect(() => {
    ruleItems.forEach(async (ruleItem, index) => {
      if (ruleItem.categoryId) {
        const subCategories = await fetchSubCategoryList(ruleItem.categoryId);
        const products = await fetchProductList(
          ruleItem.categoryId,
          ruleItem.subCategoryId
        );
        setRuleItemOptions((prev) => ({
          ...prev,
          [index]: {
            subCategories: subCategories.data,
            products: products.data,
          },
        }));
      }
    });
  }, [ruleItems]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [categories, subCategories, products] = await Promise.all([
          categoryList(),
          fetchSubCategoryList(competition.competitionRules.categoryId),
          fetchProductList(
            competition.competitionRules.categoryId,
            competition.competitionRules.subCategoryId
          ),
        ]);
        if (categories.success) setCategoryOptions(categories.data);
        if (subCategories.success)
          setRuleItemOptions((prev) => ({
            ...prev,
            [0]: { ...prev[0], subCategories: subCategories.data },
          }));
        if (products.success)
          setRuleItemOptions((prev) => ({
            ...prev,
            [0]: { ...prev[0], products: products.data },
          }));
      } catch (error) {
        toast.error("An error occurred while fetching initial data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [competitionData]);

  useEffect(() => {
    if (competition && competition.competitionRules) {
      const mappedRuleItems = competition.competitionRules.map((rule) => ({
        categoryId: rule.categoryId,
        subCategoryId: rule.subCategoryId,
        productId: rule.productId,
        quantity: rule.quantity,
        groupId: rule.groupId,
        posItemUnit: rule.posItemUnit,
      }));
      setRuleItems(mappedRuleItems);
    }
  }, [competition]);

  const fetchSubCategoryList = async (categoryId) => {
    try {
      setIsLoading(true);
      const response = await subCategoryList(categoryId);
      return response;
    } catch (error) {
      toast.error("An error occurred while fetching subcategories.");
      return { success: false, data: [] };
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductList = async (categoryId, subCategoryId) => {
    try {
      const response = await productDropDownList(categoryId, subCategoryId);
      return response;
    } catch (error) {
      toast.error("An error occurred while fetching products.");
      return { success: false, data: [] };
    }
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };
  const handleTitleChange = (e) => {
    const countTitle = e.target.value;
    setTitle(countTitle);
    setRemainingChars(190 - countTitle.length);
  };
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only JPEG, PNG, JPG are allowed.");
      return;
    }

    const maxSize = 150 * 1024; // 150KB
    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 150KB.");
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    image.onload = () => {
      if (image.width === 1200 && image.height === 800) {
        toast.error("Invalid image dimensions. Required: 1200px x 800px.");
      } else {
        setFileImage(selectedFile);
        setImagePreview(URL.createObjectURL(selectedFile));
      }
    };
  };
  const handleSelectChange = async (index, type, value) => {
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems[index][type] = value;

    if (type === "categoryId") {
      const subCategories = await fetchSubCategoryList(value);
      setRuleItemOptions((prev) => ({
        ...prev,
        [index]: { ...prev[index], subCategories: subCategories.data },
      }));
    } else if (type === "subCategoryId") {
      const products = await fetchProductList(
        updatedRuleItems[index].categoryId,
        value
      );
      setRuleItemOptions((prev) => ({
        ...prev,
        [index]: { ...prev[index], products: products.data },
      }));
    }

    setRuleItems(updatedRuleItems);
  };

  const handleProductSelectChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    const updatedRuleItems = [...ruleItems];
    updatedRuleItems[index] = {
      ...updatedRuleItems[index],
      productId: selectedValues,
    };
    setRuleItems(updatedRuleItems);
  };

  const handleAddRuleItem = () => {
    setRuleItems((prevRuleItems) => {
      const lastRuleItem = prevRuleItems[prevRuleItems.length - 1];
      return [
        ...prevRuleItems,
        {
          categoryId: "",
          subCategoryId: "",
          productId: [],
          quantity: "",
          groupId: "",
          posItemUnit: "",
        },
      ];
    });
  };

  const handleDeleteRuleItem = (index) => {
    const updatedRuleItems = ruleItems.filter((_, i) => i !== index);
    setRuleItems(updatedRuleItems);
  };
  const validateRuleItems = () => {
    return ruleItems.some(
      (rule) =>
        rule.categoryId &&
        rule.subCategoryId &&
        rule.productId.length > 0 &&
        rule.quantity &&
        rule.groupId &&
        rule.posItemUnit
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateRuleItems()) {
      toast.error("At least one rule item must have all fields filled.");
      return;
    }

    const userGet = await getUser();
    const formattedRuleItems = ruleItems.map((rule) => ({
      categoryId: rule.categoryId,
      subCategoryId: rule.subCategoryId,
      productId: rule.productId,
      quantity: rule.quantity,
      groupId: rule.groupId,
      posItemUnit: rule.posItemUnit,
    }));
    const formData = new FormData();
    formData.append("competitionId", competition.competitionId);
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("startDate", startDate);
    formData.append("prize", prize);
    formData.append("isFeatured", isFeatured);
    formData.append("competitionImageUrl", fileImage);
    formData.append("createdBy", userGet.AdminId);
    formData.append("ruleItem", JSON.stringify(formattedRuleItems));
    formData.append("IsEnabled", IsEnabled);
    formData.append("maxWinners", maxWinners);
    try {
      setLoading(true);
      const response = await competitionEdit(formData);
      if (response.success) {
        toast.success(response.message, {
          autoClose: 1500,
          onClose: () => {
            onClose();
            load();
            setLoading(false);
          },
        });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const handleImageClick = () => {
    setModalShow(true);
  };
  const handleSwitchChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  return (
    <Offcanvas id="offcanva" show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit Competition</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit}>
          <div className="col-lg-11 d-flex justify-content-end">
            <label className="switch">
              <input
                type="checkbox"
                checked={IsEnabled}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`ms-3 fw-bold ${
                IsEnabled ? "text-dark" : "text-danger"
              }`}
            >
              {IsEnabled ? "Active" : "Inactive"}{" "}
            </span>
          </div>
          <Form.Group className="mb-2" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={handleTitleChange}
              maxLength="190"
            />
            <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
              (Maximum characters: 190)
            </Form.Text>
            <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
              You Have{" "}
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "5px",
                  padding: "2px 5px",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  fontWeight: "bold",
                }}
              >
                {remainingChars}
              </span>{" "}
              Characters Left
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-2" controlId="subTitle">
            <Form.Label>SubTitle</Form.Label>
            <Form.Control
              value={subTitle}
              onChange={handleChange(setSubTitle)}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="imageUrl">
            <Form.Label>Image URL</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />

            <Form.Text className="text-muted" style={{ fontSize: "10px" }}>
              (Requirements: Type JPEG/,PNG / Max. Size 150KB, Dimension 1200 x
              800 )
            </Form.Text>
          </Form.Group>
          {imagePreview && (
            <img
              src={imagePreview}
              style={{ width: "100px", height: "100px" }}
              onClick={() => handleImageClick()}
            />
          )}
          <div className="row">
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group className="mb-2" controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mb-2" controlId="rules">
            <Form.Label>Rules</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={rules}
              onChange={handleChange(setRules)}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="prize">
            <Form.Label>Prize</Form.Label>
            <Form.Control
              type="text"
              value={prize}
              onChange={handleChange(setPrize)}
            />
             <Form.Group className="mb-3">
              <Form.Label>Maximum Winners</Form.Label>
              <Form.Control
                type="number"
                value={maxWinners}
                onChange={handleChange(setMaxWinners)}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group className="mb-2" controlId="isFeatured">
            <Form.Check
              type="checkbox"
              label="Is Featured?"
              checked={isFeatured}
              onChange={() => setIsFeatured(!isFeatured)}
            />
          </Form.Group>
          <div key={0} className="rule-item mb-3">
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-2">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={ruleItems[0].categoryId}
                    onChange={(e) =>
                      handleSelectChange(0, "categoryId", e.target.value)
                    }
                  >
                    <option value="">Select Category</option>
                    {categoryOptions.map((option) => (
                      <option
                        key={option.ProductCategoryId}
                        value={option.ProductCategoryId}
                      >
                        {option.ProductCategoryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-2">
                  <Form.Label>SubCategory</Form.Label>
                  <Form.Control
                    as="select"
                    value={ruleItems[0].subCategoryId}
                    onChange={(e) =>
                      handleSelectChange(0, "subCategoryId", e.target.value)
                    }
                  >
                    <option value="">Select SubCategory</option>
                    {(ruleItemOptions[0]?.subCategories || []).map((option) => (
                      <option
                        key={option.ProductSubCategoryId}
                        value={option.ProductSubCategoryId}
                      >
                        {option.ProductSubCategoryName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-2">
              <Form.Label>Products</Form.Label>
              <Select
                id="select"
                isMulti
                options={(ruleItemOptions[0]?.products || []).map(
                  (product) => ({
                    value: product.ProductRecordId,
                    label: product.ProductName,
                  })
                )}
                value={(ruleItems[0].productId || []).map((productId) => {
                  const product = ruleItemOptions[0]?.products?.find(
                    (p) => p.ProductRecordId === productId
                  );
                  return {
                    value: productId,
                    label: product ? product.ProductName : "loading....",
                  };
                })}
                onChange={(selectedOptions) =>
                  handleProductSelectChange(0, selectedOptions)
                }
              />
            </Form.Group>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="mb-2">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    value={ruleItems[0].quantity}
                    onChange={(e) => {
                      const newQuantity = e.target.value;
                      const updatedRuleItems = [...ruleItems];
                      updatedRuleItems[0].quantity = newQuantity;
                      setRuleItems(updatedRuleItems);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-2">
                  <Form.Label>Group ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={ruleItems[0].groupId}
                    onChange={(e) => {
                      const newGroupId = e.target.value;
                      const updatedRuleItems = [...ruleItems];
                      updatedRuleItems[0].groupId = newGroupId;
                      setRuleItems(updatedRuleItems);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-2">
              <Form.Label>POS Item Unit</Form.Label>
              <Form.Control
                type="text"
                value={ruleItems[0].posItemUnit}
                onChange={(e) => {
                  const newPosItemUnit = e.target.value;
                  const updatedRuleItems = [...ruleItems];
                  updatedRuleItems[0].posItemUnit = newPosItemUnit;
                  setRuleItems(updatedRuleItems);
                }}
              />
            </Form.Group>
          </div>
          {/* Handle Rule Items  */}
          {ruleItems.map((ruleItem, index) =>
            index !== 0 ? (
              <div key={index}>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group className="mb-2">
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        value={ruleItem.categoryId}
                        onChange={(e) =>
                          handleSelectChange(
                            index,
                            "categoryId",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Category</option>
                        {categoryOptions.map((option) => (
                          <option
                            key={option.ProductCategoryId}
                            value={option.ProductCategoryId}
                          >
                            {option.ProductCategoryName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-2">
                      <Form.Label>SubCategory</Form.Label>
                      <Form.Control
                        as="select"
                        value={ruleItem.subCategoryId}
                        onChange={(e) =>
                          handleSelectChange(
                            index,
                            "subCategoryId",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select SubCategory</option>
                        {(ruleItemOptions[index]?.subCategories || []).map(
                          (option) => (
                            <option
                              key={option.ProductSubCategoryId}
                              value={option.ProductSubCategoryId}
                            >
                              {option.ProductSubCategoryName}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group className="mb-2">
                  <Form.Label>Products</Form.Label>
                  <Select
                    id="select"
                    isMulti
                    options={(ruleItemOptions[index]?.products || []).map(
                      (product) => ({
                        value: product.ProductRecordId,
                        label: product.ProductName,
                      })
                    )}
                    value={(ruleItem.productId || []).map((productId) => {
                      const product = ruleItemOptions[index]?.products?.find(
                        (p) => p.ProductRecordId === productId
                      );
                      return {
                        value: productId,
                        label: product ? product.ProductName : "loading....",
                      };
                    })}
                    onChange={(selectedOptions) =>
                      handleProductSelectChange(index, selectedOptions)
                    }
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Group className="mb-2">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        value={ruleItem.quantity}
                        onChange={(e) => {
                          const newQuantity = e.target.value;
                          const updatedRuleItems = [...ruleItems];
                          updatedRuleItems[index].quantity = newQuantity;
                          setRuleItems(updatedRuleItems);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group className="mb-2">
                      <Form.Label>Group ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={ruleItem.groupId}
                        onChange={(e) => {
                          const newGroupId = e.target.value;
                          const updatedRuleItems = [...ruleItems];
                          updatedRuleItems[index].groupId = newGroupId;
                          setRuleItems(updatedRuleItems);
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group className="mb-2">
                  <Form.Label>POS Item Unit</Form.Label>
                  <Form.Control
                    type="text"
                    value={ruleItem.posItemUnit}
                    onChange={(e) => {
                      const newPosItemUnit = e.target.value;
                      const updatedRuleItems = [...ruleItems];
                      updatedRuleItems[index].posItemUnit = newPosItemUnit;
                      setRuleItems(updatedRuleItems);
                    }}
                  />
                </Form.Group>
                <div
                  className="text-right"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteRuleItem(index)}
                    disabled={loading}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button id="btn1" onClick={handleAddRuleItem}>
              +Add Rule
            </Button>
          </div>

          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span>Update </span>}
              </div>
            </Button>
          </div>
        </Form>
        <ImageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          imageUrl={imagePreview}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Editcompetition;
