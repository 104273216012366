import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";

const getChartOptions = (chartType) => ({
  chart: {
    type: chartType || "bar",
    height: 450,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "10%",
      distributed: false,
      endingShape: "rounded",
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    shared: true,
  },
  dataLabels: {
   enabled: false,
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
    },
    title: {
      text: "Sales Amount", // Label for y-axis
      style: {
        colors: ["#ba54f5"],
        fontWeight:"600",
        fontSize: "15px",
        fontFamily:"outfit,sans-serif" // Customize the font size if needed
      },
      offsetX: -10,
    },
  },
  xaxis: {
    categories: ["Last Year", "Current Year"], 
    title: {
      text: "Year to date Last year", // Label for y-axis
      style: {
        colors: ["#ba54f5"],
        fontWeight:"600",
        fontSize: "15px",
        fontFamily:"outfit,sans-serif" // Customize the font size if needed
      },
      offsetX: -40,
      offsetY: -30,
    },
    position: "bottom",
    labels: {
      style: {
        fontSize: "14px",
        fontFamily: "Arial, sans-serif",
        fontWeight: 400,
      },
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
  },
  stroke: {
    width: [2, 2],
    dashArray: [0, 5],
    curve: "smooth",
  },
});

const ValueSalesYearVsLastYear = ({ data }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(getChartOptions("bar"));

  const getCurrentAndPreviousPeriod = useMemo(() => {
    return ["Year to Date (2024)", "2023"];
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      updateSeries(data);
    }
  }, [data]);

  const updateSeries = (data) => {
    const categories = [];
    const periodMap = new Map();

    data.forEach((item) => {
      if (item.data && Array.isArray(item.data)) {
        item.data.forEach((entry) => {
          if (entry && entry.x && !categories.includes(entry.x)) {
            categories.push(entry.x);
          }
        });
      }
    });

    data.forEach((item) => {
      const periodName = item.period;
      if (item.data && Array.isArray(item.data)) {
        periodMap.set(
          periodName,
          item.data.reduce((acc, entry) => {
            if (entry && entry.x) {
              acc[entry.x] = entry.y || 0;
            }
            return acc;
          }, {})
        );
      }
    });

    const [currentPeriod, previousPeriod] = getCurrentAndPreviousPeriod;

    const newSeries = categories.map((category) => {
      const currentValue = periodMap.get(currentPeriod)?.[category] || 0;
      const previousValue = periodMap.get(previousPeriod)?.[category] || 0;

      return {
        name: category,
        data: [previousValue, currentValue],
      };
    });

    setSeries(newSeries);

    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        ...prevOptions.chart,
        type: "bar",
      },
      xaxis: {
        categories: ["Last Year", "Current Year"],
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 500,
          },
        },
      },
    }));
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={380} />
    </div>
  );
};

export default ValueSalesYearVsLastYear;
