import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { useParams } from "react-router-dom";
import {
  userDetails,
  ExpiringPoints,
  TotalEarnedPoints,
} from "../api/apiIntegration";
import { format } from "date-fns";
import DonutChart from "react-donut-chart";
import LoaderOwn from "../common/loaderOwn.js";
import { useNavigate } from "react-router-dom";

export default function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [expiringPoints, setExpiringPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalEarnedPoints, setTotalEarnedPoints] = useState(null); 
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/user-transactions/${userId}`, { state: { user } });
  };
  const handleClick1 = () => {
    navigate(`/total-earnpoints-list/${userId}`);
  };
  useEffect(() => {
    list();
    ExpiringPointsList(selectedMonth, selectedYear);
    EarnedPoints();
  }, [userId, selectedMonth, selectedYear]);

  const list = async () => {
    try {
      const response = await userDetails({ id: userId });
      console.log(response);
      if (response.success) {
        setUser(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const ExpiringPointsList = async (month, year) => {
    try {
      const response = await ExpiringPoints(userId, month, year);
      if (response.success) {
        setExpiringPoints(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch expiring points:", error);
    }
  };
  const EarnedPoints = async () => {
    try {
      const response = await TotalEarnedPoints(userId);
      if (response.success) {
        setTotalEarnedPoints(response.data.totalEarnedPoints);
      }
    } catch (error) {
      console.error("Failed to fetch total earned points", error);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "dd-MMM-yyyy ");
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleSearch = () => {
    ExpiringPointsList(selectedMonth, selectedYear);
  };
  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthNumber - 1] || "";
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel ">
        <div className="content pt-5">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center justify-content-between ">
                    <div className="card-title">
                      <button
                        style={{ color: "#0A69D6" }}
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn mb-3 "
                      >
                        <i className="bi bi-arrow-left"></i> Back
                      </button>
                    </div>
                    <div className="card-title ms-auto">
                      <button
                        id="userbtn"
                        type="button"
                        className="btn  mt-5 "
                        onClick={handleClick}
                      >
                        VIEW TRANSACTION HISTORY
                      </button>
                    </div>
                  </div>
                  <div id="" className="card-body mt-5 pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="row-12 center-align">
                            <img
                              src="../images/user1.png"
                              alt=""
                              className="icon1"
                            />
                            <h4 style={{ fontSize: "30px", fontWeight: "700" }}>
                              {user.FirstName} {user.LastName}
                            </h4>
                          </div>
                          <div className="row pt-5">
                            <div className="row mt-5">
                              <h5
                                style={{ fontSize: "20px", fontWeight: "500" }}
                              >
                                DEMOGRAPHIC DATA
                              </h5>
                            </div>
                            <div className="row-12 center-align">
                              <img
                                src="../images/email.png"
                                alt=""
                                className="icon1"
                              />
                              <p>{user.Email} </p>
                            </div>

                            <div className="row-12 center-align">
                              <img
                                src="../images/location.png"
                                alt=""
                                className="icon1"
                              />
                              <p>{user.Location} </p>
                            </div>
                            <div className="row-12 center-align">
                              <img
                                src="../images/countries.png"
                                alt=""
                                className="icon1"
                              />
                              <p>{user.Nationality} </p>
                            </div>

                            <div className="row-12 center-align">
                              <img
                                src="../images/phone-call.png"
                                alt=""
                                className="icon1"
                              />
                              <p>{user.PhoneNumber} </p>
                            </div>

                            <div
                              className="row-12 center-align"
                              style={{ borderBottom: "2px dashed #0dcaf0" }}
                            >
                              <img
                                src="../images/birthday.png"
                                alt=""
                                className="icon1"
                              />
                              <p>{formatDate(user.DateOfBirth)}</p>
                            </div>

                            <div className="row mt-3">
                              <p>Date Verified</p>

                              <p>{formatDate(user.LastEditedDate)}</p>
                            </div>

                            <div className="row mt-3">
                              <p>Last Login</p>

                              <p>{formatDate(user.LastLogin)}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="mb-1">
                            <h2 style={{ fontSize: "20px", fontWeight: "500" }}>
                           Total Earn points
                            </h2>
                          </div>
                          <div
                            style={{ borderBottom: "2px dashed #0dcaf0" }}
                            className="row mt-4 "
                          >
                            <div className="col-3 pt-1">
                              <h5> { totalEarnedPoints}</h5>
                            </div>
                            <div className="col-sm-3 mb-3">
                              <button
                                type="button"
                                className="btn  btn-sm "
                                id="userbtn"
                                onClick={handleClick1}
                              >
                                EARN/REDEEM
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <h5
                              className="mt-4"
                              style={{ fontSize: "20px", fontWeight: "500" }}
                            >
                              Expiring Points
                            </h5>
                          </div>
                          <div className="row-lg-12  d-flex align-items-center ">
                            <div className="col-lg-4 ">
                              <select
                                className="browser-default custom-select"
                                id="filter-m"
                                onChange={handleMonthChange}
                                value={selectedMonth}
                              >
                                <option value="0" disabled="Select month">
                                  Select month
                                </option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>

                            <div className="col-lg-4 mx-5">
                              <select
                                className="browser-default custom-select"
                                id="filter-year"
                                onChange={handleYearChange}
                                value={selectedYear}
                              >
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                              </select>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn  btn-sm "
                                id="userbtn"
                                onClick={handleSearch}
                              >
                                SEARCH
                              </button>
                            </div>
                          </div>
                          <div className="row-12 mt-5 d-flex align-items-center">
                            {expiringPoints.map((point, index) => (
                              <div key={index} className="col-lg-4">
                                <h1 className="item me-4">
                                  {point.expiryAmount.toFixed(1)}
                                </h1>
                                <p className="text-success">
                                  {getMonthName(point.month)}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="col-sm-5 ">
                          <div className="mb-1">
                            <h2 style={{ fontSize: "20px", fontWeight: "500" }}>
                              {user.Purchases} Purchases
                            </h2>
                            <div className="row-8 mt-3 mx-2 d-flex align-items-center">
                              <DonutChart
                                data={[
                                  {
                                    label: "lemon",
                                    value: 25,
                                  },
                                  {
                                    label: "vodka",
                                    value: 75,
                                  },
                                  {
                                    label: "bear",
                                    value: 75,
                                  },
                                  {
                                    label: "wine",
                                    value: 75,
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
