import React, { useState } from "react";
import { login } from "../auth/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email == "") {
      toast.error("Email is requried");
    } else if (password == "") {
      toast.error("password is requried");
    } else {
      try {
        setLoading(true);
        const response = await login(email, password);
        if (response.success == true) {
          toast.success(response.message);
          navigate("/dashboard");
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error(
            "Login failed. Please check your credentials and try again."
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleLogin2Click = () => {
    navigate("/logindesign"); 
  };
  return (
    <div id="__layout">
      <div className="wrapper wrapper-full-page">
        <div className="full-page login-page">
          <div className="content">
            <div id="logincontainer " className="container login-page">
              <div className="row mx-auto ">
                <div className="card-header text-center">
                  <h1 className="card-title"></h1>
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                  <img
                    className="loginlogo"
                    src="../images/logo1.png"
                    height="170"
                    alt="Logo"
                  />
                </div>
                <div className="col-lg-6 mt-5">
                  <div className="card-login">
                    <form className="card-white" onSubmit={handleSubmit}>
                      <div className="card-body mb-5 custom-card-body">
                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              id="email"
                              placeholder="Email"
                              name="email"
                              className="form-control"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                id="password"
                                placeholder="Password"
                                name="password"
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <button
                                id="eye"
                                className="btn"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                  boxShadow: "none",
                                  cursor: "pointer",
                                }}
                              >
                                {showPassword ? (
                                  <i className="bi bi-eye-slash-fill"></i>
                                ) : (
                                  <i className="bi bi-eye-fill"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <div className="d-flex justify-content flex-wrap mb-3">
                              {/* <a
                                className="forgot-link"
                                href="/forgot-password"
                              >
                                Forgot Password?
                              </a> */}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-sm-6">
                            <div className="mb-2">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg "
                                disabled={loading}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {loading && (
                                    <ThreeDots
                                      color="#fff"
                                      height={20}
                                      width={30}
                                    />
                                  )}
                                  {!loading && <span>Login</span>}
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div className="row">
                    <div className="col-sm-6">
                      <div className="mb-2">
                      <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          onClick={handleLogin2Click}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span>Login2</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
